import { useQuery } from "@apollo/client";

import { GET_VENDOR_PROFILE_JOB_TYPES } from "@lango/common/queries";

const prepareServiceLines = (data) =>
  data?.findVendorProfileJobTypes?.map((vpJobTypes) => vpJobTypes?.jobType);

const useFetchVendorServiceLines = (vendorProfileID) => {
  const { data, loading, error } = useQuery(GET_VENDOR_PROFILE_JOB_TYPES, {
    variables: { vendorProfileID: vendorProfileID },
    fetchPolicy: "no-cache",
  });

  return { values: prepareServiceLines(data), loading, error };
};

export default useFetchVendorServiceLines;
