import { gql } from "@apollo/client";

import { USER_DETAILS } from "@lango/common/fragments";

const FIND_USER = gql`
  ${USER_DETAILS}
  query FindUser($userID: ID!) {
    findUser(userID: $userID) {
      ...userDetails
      notifications
      preferredTimezone
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $firstName: String!
    $lastName: String!
    $notifications: Boolean!
    $preferredTimezone: String!
    $genderID: Int
  ) {
    updateUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        notifications: $notifications
        preferredTimezone: $preferredTimezone
        genderID: $genderID
      }
    )
  }
`;

export { FIND_USER, UPDATE_USER };
