import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const getQueryParams = (key) => queryParams.get(key);

  return { getQueryParams };
};

export default useQueryParams;
