import { useModal } from "@lango/common/context";
import Flex from "../../tables/components/Flex";
import FormControl from "../components/FormControl";
import { Form, useFormikContext } from "formik";

const ClientVRISettingFields = () => {
  const { isSubmitting, dirty } = useFormikContext();
  const { hideModal } = useModal();
  return (
    <Form className="space-y-6">
      <FormControl
        control="toggle"
        name="enableScreenShare"
        label="Enable Screen Share"
      />
      <FormControl
        control="toggle"
        name="enableVirtualBackground"
        label="Enable Virtual Background"
      />

      <Flex justify="center" extraClasses="gap-4">
        <FormControl
          control="button"
          type="button"
          text="Cancel"
          importance="secondary"
          onClick={hideModal}
          disabled={isSubmitting}
        />
        <FormControl
          control="button"
          type="submit"
          importance="primary"
          text="Save"
          disabled={isSubmitting || !dirty}
          showLoader={isSubmitting}
        />
      </Flex>
    </Form>
  );
};

export default ClientVRISettingFields;
