/**
 * JobFilterModal is a React component that provides a modal for filtering jobs.
 *
 * It uses Formik for form handling, and it gets the setShowModal and setFilters functions as props.
 * These functions are used to control the visibility of the modal and to set the filters for the job list, respectively.
 *
 * When the form is submitted, it builds the filters request using the form values, sets the filters, and hides the modal.
 */

import SearchModalWrapper from "@lango/common/components/SearchModalWrapper";
import { filterKeys, filterType } from "@lango/common/request";
import { Formik } from "formik";
import JobFilterFields from "./JobFilterFields";
import { buildFiltersRequest } from "./utils";

export const JobFilterModal = (props) => {
  const { setShowModal, setFilters, currentFilters, hideFilters } = props;

  const handleSubmit = async (values) => {
    const filters = buildFiltersRequest(values);
    setFilters(filters);
    setShowModal(false);
  };

  const getCurrentFilter = (needle) => {
    const values =
      currentFilters &&
      currentFilters[filterType[needle]]?.find((f) => f?.key === needle)
        ?.values;
    return values || [];
  };

  const initialFormValues = {
    [filterKeys.status]: getCurrentFilter(filterKeys.status),
    [filterKeys.requestType]: getCurrentFilter(filterKeys.requestType),
    [filterKeys.languageTo]: getCurrentFilter(filterKeys.languageTo),
    [filterKeys.languageFrom]: getCurrentFilter(filterKeys.languageFrom),
    [filterKeys.billingReference]: getCurrentFilter(
      filterKeys.billingReference,
    ),
    [filterKeys.labels]: getCurrentFilter(filterKeys.labels),
    [filterKeys.dateRange]: getCurrentFilter(filterKeys.dateRange),
  };

  hideFilters.forEach((filter) => {
    delete initialFormValues[filter];
  });

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {props?.showSearchModal && (
        <SearchModalWrapper {...props}>
          <JobFilterFields {...props} />
        </SearchModalWrapper>
      )}
    </Formik>
  );
};

export default JobFilterModal;
