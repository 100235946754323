import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React from "react";

export const FieldIcon = ({ icon, meta = {} }) => {
  return (
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      {meta.touched && meta.error ? (
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      ) : (
        icon
      )}
    </div>
  );
};
