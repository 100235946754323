/**
 * VendorJobDetails is a React component that displays the details of a specific vendor job.
 *
 * It fetches the job details for a given job ID, which it gets from the route parameters.
 * While the data is loading, it displays a loading state. If an error occurs during fetching,
 * it displays an error message. Once the data has been successfully fetched, it displays the job details
 * in a table.
 */
import React from "react";

import { WithLoader } from "@lango/common/components";
import {
  HUMAN_TRANSLATION,
  ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY,
} from "@lango/common/constants";
import Empty from "@lango/common/features/tables/components/Empty";
import { useFetchJobType } from "@lango/common/hooks";

import VendorHTJobDetails from "./vendorHTJobDetails";
import VendorOnDemandJobDetails from "./vendorOnDemandJobDetails";

const JOB_DETAILS_LOOKUP = {
  [ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY]: <VendorOnDemandJobDetails />,
  [HUMAN_TRANSLATION]: <VendorHTJobDetails />,
};

// render job details page according to job type... HT / OPI/VRI
export const renderJobDetails = (jobType) => {
  const page = JOB_DETAILS_LOOKUP[jobType];
  if (!page) {
    return <Empty emptyMessage={`${jobType} details page does not exist`} />;
  }
  return page;
};

const VendorJobDetails = () => {
  const { jobType, loading, error } = useFetchJobType();
  return (
    <WithLoader loading={loading} error={error}>
      {jobType && renderJobDetails(jobType)}
    </WithLoader>
  );
};

export default VendorJobDetails;
