import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { clientOnboardingStep1Request } from "@lango/common/request";
import {
  CLIENT_ONBOARD,
  CLIENT_ONBOARDING_STATUS,
  UPDATE_CLIENT_ONBOARDING_STATUS,
} from "@lango/common/queries";
import { toastSuccess, toastWarning } from "@lango/common/features";
import { useAcceptClientInviteCtx } from "@lango/common/context";

import { AUTH_ROUTES } from "../routes";
import { clearOrgLogoInfo } from "../apollo";

const refetchQueries = {
  refetchQueries: [CLIENT_ONBOARDING_STATUS],
};

export const useClientOnboardSteps = () => {
  const navigate = useNavigate();
  const [step1Mutation] = useMutation(CLIENT_ONBOARD.STEP1, refetchQueries);
  const [step2Mutation] = useMutation(CLIENT_ONBOARD.STEP2, refetchQueries);
  const [step3Mutation] = useMutation(CLIENT_ONBOARD.STEP3, refetchQueries);
  const [step4Mutation] = useMutation(CLIENT_ONBOARD.STEP4, refetchQueries);
  const [updateClientOnboardingStatusMutation] = useMutation(
    UPDATE_CLIENT_ONBOARDING_STATUS,
    refetchQueries,
  );

  const { redirectMode, onboardingData, handleChangeInviteStep } =
    useAcceptClientInviteCtx();
  const clientProfileID = onboardingData?.clientProfileID;

  async function handleOnboardingStep1(/** @type {any} */ values) {
    try {
      await step1Mutation({
        variables: {
          input: clientOnboardingStep1Request({
            ...values,
            clientProfileID,
          }),
        },
      });
      handleChangeInviteStep("step2");
    } catch (error) {
      toastWarning("Failed to perform client onboarding step 1");
      console.error(error);
    }
  }

  async function handleOnboardingStep2(/** @type {any} */ values) {
    try {
      console.debug(values);
      await step2Mutation({
        variables: { input: { ...values, clientProfileID } },
      });
      handleChangeInviteStep("step3");
    } catch (error) {
      toastWarning("Failed to perform client onboarding step 2");
      console.error(error);
    }
  }

  async function handleOnboardingStep3(/** @type {any} */ values) {
    try {
      console.debug(values);
      await step3Mutation({
        variables: {
          input: {
            clientProfileID,
            serviceLocations: values?.serviceLocations,
          },
        },
      });
      handleChangeInviteStep("step4");
    } catch (error) {
      toastWarning("Failed to perform client onboarding step 3");
      console.error(error);
    }
  }

  async function handleOnboardingStep4() {
    try {
      await step4Mutation({
        variables: { input: { clientProfileID } },
      });
      toastSuccess("Client onboarding completed successfully");
      navigate("/");
    } catch (error) {
      toastWarning("Failed to perform client onboarding step 4");
      console.error(error);
      const to = redirectMode ? AUTH_ROUTES.LOGOUT : "/";
      redirectMode && clearOrgLogoInfo();
      navigate(to);
    }
  }

  async function handleUpdateClientOnboardingStatus(/** @type {any} */ values) {
    try {
      console.debug(values);
      await updateClientOnboardingStatusMutation({
        variables: {
          input: {
            clientProfileID,
            status: "step3",
          },
        },
      });
      handleChangeInviteStep("step4");
    } catch (error) {
      toastWarning("Failed to update client onboarding status");
      console.error(error);
    }
  }

  return {
    handleOnboardingStep1,
    handleOnboardingStep2,
    handleOnboardingStep3,
    handleOnboardingStep4,
    handleUpdateClientOnboardingStatus,
  };
};
