import React from "react";

const EndBar = ({ color, text, textColor, width = 421 }) => {
  return (
    <div>
      <svg
        width={width}
        height="48"
        viewBox={`0 0 ${width} 48`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`M0.5 0.5H${width - 20}C${width - 9.23} 0.5 ${width} 9.23045 ${width} 20V23V28C${width} 38.7696 ${width - 9.23} 47.5 ${width - 20} 47.5H0.5V0.5Z`}
          fill={color}
          stroke="#E9E9E9"
        />
        <text
          x={width / 1.5}
          y="50%"
          fill={textColor}
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="16"
        >
          {text}
        </text>
      </svg>
    </div>
  );
};

export default EndBar;
