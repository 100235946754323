import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { toastError } from "@lango/common/features";
import { INVITATION_MESSAGES } from "@lango/common/constants";
import { VERIFY_VENDOR_INVITATION } from "@lango/common/queries";
import environmentVars from "@lango/common/env";

import { useAcceptVendorInviteCtx } from "../context";
import { checkAuthenticationStatus } from "./utils";

export const useVerifyVendorInvitation = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { handleChangeVerifyResponse } = useAcceptVendorInviteCtx();
  useQuery(VERIFY_VENDOR_INVITATION, {
    variables: { code: token, sourceAppName: environmentVars.appName },
    context: { clientName: "public" },
    onCompleted: ({ verifyVendorInvitation }) => {
      const {
        isAuthenticatedWithEmailMatch,
        isAuthenticatedWithoutEmailMatch,
      } = checkAuthenticationStatus(verifyVendorInvitation?.email);

      if (isAuthenticatedWithEmailMatch) {
        handleChangeVerifyResponse(verifyVendorInvitation, "link");
        return;
      }

      if (isAuthenticatedWithoutEmailMatch) {
        toastError(INVITATION_MESSAGES.logout_to_accept);
        navigate("/");
        return;
      }

      const nextStep = verifyVendorInvitation?.nextStep;
      ["login", "register"].includes(nextStep) &&
        handleChangeVerifyResponse(verifyVendorInvitation, nextStep);
    },
    onError: (error) => {
      console.error(error);
      toastError(error?.message);
      navigate("/");
    },
  });

  return {};
};
