import { useField } from "formik";
import classNames from "@lango/common/classnames";

const Radio = ({ label, options, extraClasses, infoText, name }) => {
  const [field, meta] = useField(name);
  const className = classNames("py-5", extraClasses);
  return (
    <div className={className}>
      <label className="text-md mb-4 block font-medium">
        {label}
        {infoText && (
          <div className="mt-2 text-sm text-gray-500">{infoText}</div>
        )}
      </label>

      {options.map((option, index) => (
        <label
          key={index}
          className="inline-flex cursor-pointer items-center ml-5"
        >
          <input
            type="radio"
            className="form-radio h-4 w-4"
            name={name}
            value={field.value}
            onChange={() =>
              field.onChange({ target: { name, value: option.value } })
            }
            checked={option.value === field.value}
            style={{
              backgroundColor: option.value === field.value ? "black" : "white",
              borderColor: "black",
            }}
          />
          <span className="ml-2 text-gray-700">{option.label}</span>
        </label>
      ))}
      {meta.touched && meta.error && (
        <p className="ml-3 mt-2 h-1 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
};

export default Radio;
