import { Form, useFormikContext } from "formik";

import Button from "@lango/common/features/forms/components/Button";
import FormControl from "@lango/common/features/forms/components/FormControl";
import Flex from "@lango/common/features/tables/components/Flex";
import { useFetchAllRoles } from "@lango/common/hooks";

export const UpdateUserRolesForm = ({ initialValues }) => {
  const { values } = useFormikContext();
  const existingRoles = values?.userRoles.map((item) => item.value);
  const selectedRoles = initialValues?.userRoles.map((item) => item.value);

  const alteredRoles = existingRoles
    ?.filter((item) => !selectedRoles.includes(item))
    ?.concat(selectedRoles?.filter((item) => !existingRoles?.includes(item)));

  const areRolesAltered = alteredRoles?.length === 0;
  const { allRoles } = useFetchAllRoles();
  return (
    <Form className="space-y-24">
      <FormControl
        control="select"
        name="userRoles"
        label="User Roles"
        placeholder="User Roles"
        options={allRoles}
        initialValues={initialValues?.userRoles}
        isMulti
      />
      <Flex justify="center">
        <Button
          text="Update Roles"
          extraClasses="mt-2"
          disabled={areRolesAltered}
        />
      </Flex>
    </Form>
  );
};
