import WithLoader from "@lango/common/components/WithLoader";
import Table from "@lango/common/features/tables/components/Table";

import { useGetVendorProfileLanguage } from "@lango/common/hooks";
import { columns } from "./columns";

const VendorLanguagePairTable = ({ organizationID, lspID, canUpdateOrg }) => {
  const { loading, error, ...props } = useGetVendorProfileLanguage({
    organizationID,
    lspID,
  });

  return (
    <>
      <Table
        columns={columns({ canUpdateOrg, organizationID })}
        enablePagination
        {...props}
      />
      <WithLoader loading={loading} error={error} />
    </>
  );
};

export default VendorLanguagePairTable;
