import { WithLoader } from "@lango/common/components";
import { SettingsForm } from "./fields";
import { useFetchLinguistUser } from "@lango/common/hooks";
import { SettingsContextProvider } from "./provider";

const UpdateUserSettings = () => {
  const { currentUser, ...props } = useFetchLinguistUser();
  return (
    <WithLoader {...props}>
      <SettingsContextProvider user={currentUser}>
        <SettingsForm user={currentUser} />
      </SettingsContextProvider>
    </WithLoader>
  );
};

export default UpdateUserSettings;
