import { useQuery } from "@apollo/client";

import { FETCH_JOB_TYPES } from "@lango/common/queries";

/**
 * useFetchJobTypes Hook
 *
 * This hook fetches job types based on the provided job types
 * It utilizes useQuery hook from Apollo Client for data fetching.
 *
 * @hook useFetchJobTypes
 */
export const useFetchJobTypes = (types) => {
  const { loading, data, error } = useQuery(FETCH_JOB_TYPES, {
    variables: { types },
  });

  return {
    loading,
    error,
    allJobTypes: data?.allJobTypes,
  };
};
