import { DateTime } from "luxon";
import tzdata from "tzdata";

const NO_PREFERENCE = {
  label: "No Preference",
  value: "",
  gmt: "",
};

const localZone = DateTime.local().zoneName;
const suggestedTimezone = [
  {
    label: `${localZone}`,
    value: localZone,
    gmt: `(GMT ${DateTime.local().toFormat("Z")})`,
  },
];

export const getGroupedTimezones = (optional) => {
  const nullOption = optional ? [NO_PREFERENCE] : [];
  return [
    {
      label: "Suggested Timezones",
      options: suggestedTimezone,
    },
    {
      label: "All Timezones",
      options: [
        ...nullOption,
        ...Object.entries(tzdata.zones).map(([zoneName]) => {
          const offset = DateTime.local().setZone(zoneName).toFormat("Z");
          return {
            label: zoneName,
            value: zoneName,
            gmt: `(GMT ${offset})`,
          };
        }),
      ],
    },
  ];
};

export const prepareTimezoneOption = (option) => {
  return (
    <span>
      {option.label?.replace("_", " ")}
      <span className="ml-1 text-sm text-gray-500">{option.gmt}</span>
    </span>
  );
};

export const prepareUserTimezone = (data = {}) => {
  const user = data?.findCurrentUser;
  if (!user) return {};

  const preferredTimezone = user.preferredTimezone;
  return {
    ...user,
    preferredTimezone: preferredTimezone
      ? {
          label: preferredTimezone,
          value: preferredTimezone,
          gmt: `(GMT ${DateTime.local()
            .setZone(preferredTimezone)
            .toFormat("Z")})`,
        }
      : NO_PREFERENCE,
  };
};
