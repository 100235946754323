import useGetOrgLSPs from "@lango/buyer/src/features/user/containers/useGetOrgLSPs";

import { updateActiveLSP } from "@lango/common/apollo/localState";
import Dropdown from "@lango/common/components/dropdown";
import { useIsAuthenticated } from "@lango/common/features/auth/hooks/useIsAuthenticated";

import LSPsDropdownList from "./LSPsDropdownList";
import Logo from "./Logo";

const HeaderLogo = () => {
  const { orgLSPs } = useGetOrgLSPs();
  const { isAuthenticated } = useIsAuthenticated();

  const menuList = orgLSPs
    ?.map((lsp) => ({
      onClick: () => updateActiveLSP(lsp),
      ...lsp,
    }))
    .filter(Boolean);

  const isLSPsDropdownVisible = menuList?.length >= 1 && isAuthenticated;

  return (
    <Dropdown className="left-0 right-auto" showTriangleIcon={false}>
      <Dropdown.Button disabled={isLSPsDropdownVisible}>
        <Logo isLSPsDropdownVisible={isLSPsDropdownVisible} />
      </Dropdown.Button>
      <Dropdown.Menu className="w-60">
        <LSPsDropdownList menuItems={menuList} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HeaderLogo;
