import { Form, useFormikContext } from "formik";

import FormControl from "@lango/common/features/forms/components/FormControl";

const LinguistProfileFields = () => {
  const { isSubmitting, dirty } = useFormikContext();
  return (
    <Form className="space-y-8">
      <div className="grid gap-x-32 grid-cols-8">
        <FormControl
          control="input"
          name="name"
          label="Linguist Name"
          placeholder="Linguist Name"
          containerClasses="col-span-4"
          disabled={true}
        />
        <FormControl
          control="toggle"
          name="isActive"
          label="Status"
          description="Active/Inactive"
          disabled={isSubmitting}
        />
      </div>
      <FormControl
        control="button"
        type="submit"
        text="Save"
        disabled={isSubmitting || !dirty}
        showLoader={isSubmitting}
      />
    </Form>
  );
};

export default LinguistProfileFields;
