import { VerifyInvitation } from "../context";
import { Onboarding } from "../features/onboarding";
import { PendingInvitationsScreen } from "../features/pendingInvitations";
import { invitationPageLoader, protectedPageLoader } from "./authLoaders";
import { COMMON_ROUTES, VENDOR_ROUTES } from "./consts";

export const vendorInviteRoutes = [
  {
    path: VENDOR_ROUTES.VERIFY_INVITATION,
    element: <VerifyInvitation />,
  },
  {
    path: VENDOR_ROUTES.ONBOARDING,
    loader: protectedPageLoader,
    element: <Onboarding />,
  },
  {
    path: COMMON_ROUTES.INVITATIONS,
    loader: invitationPageLoader,
    element: <PendingInvitationsScreen />
  }
];
