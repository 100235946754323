import { useFormikContext } from "formik";
import { Link } from "react-router-dom";

import { Box } from "@lango/common/components/Box";
import FormControl from "@lango/common/features/forms/components/FormControl";
import Flex from "@lango/common/features/tables/components/Flex";
import { AUTH_ROUTES } from "@lango/common/routes";

const LoginFormFields = () => {
  const {
    isSubmitting,
    dirty,
    values,
    handleChange,
    setFieldValue,
    setFieldError,
    setStatus,
  } = useFormikContext();

  const localLogin = values?.localLogin;

  const handleChangeEmail = (e) => {
    if (e.target.value === values?.email) return;
    setFieldValue("localLogin", false);
    setFieldError("localLogin", null);
    handleChange(e);
    setStatus(null);
  };

  const handleChangePassword = (e) => {
    setFieldError("password", null);
    handleChange(e);
    setStatus(null);
  };
  const disableFields = isSubmitting || values?.disableFields;

  return (
    <Box spaceY={8}>
      <FormControl
        control="input"
        label="Email"
        type="email"
        placeholder="Enter email"
        name="username"
        onChange={handleChangeEmail}
        disabled={disableFields}
        whiteBackground
      />
      {localLogin && (
        <div className="transition duration-150 ease-in-out">
          <FormControl
            control="input"
            label="Password"
            type="password"
            placeholder="Enter password"
            name="password"
            autoComplete="current-password"
            onChange={handleChangePassword}
            disabled={disableFields}
            whiteBackground
          />
          <Flex justify="end">
            <Link
              to={!disableFields && AUTH_ROUTES.FORGOT_PASSWORD}
              className="font-medium text-sm text-gray-400 hover:text-gray-700 mt-6"
            >
              Forgot password?
            </Link>
          </Flex>
        </div>
      )}
      <Flex justify="center">
        <FormControl
          control="button"
          type="submit"
          disabled={disableFields || !dirty}
          text={localLogin ? "Sign in" : "Next"}
          showLoader={disableFields}
        />
      </Flex>
    </Box>
  );
};

export default LoginFormFields;
