import { createColumnHelper } from "@tanstack/react-table";
import { renderLabel } from "@lango/common/components/Labels";

const columnHelper = createColumnHelper();

export const columns = [
  columnHelper.accessor("firstName", {
    id: "firstName",
    header: "First Name",
  }),
  columnHelper.accessor("lastName", {
    id: "lastName",
    header: "Last Name",
  }),
  columnHelper.accessor("email", {
    id: "email",
    header: "Email",
  }),
  columnHelper.accessor("roles", {
    id: "roles",
    cell: (/** @type {any} */ info) => {
      const value = info.getValue();
      return value?.map((item) => renderLabel(item?.id, item?.name));
    },
    header: "Roles",
    enableSorting: false,
  }),
];
