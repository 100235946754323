import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

import classNames from "@lango/common/classnames";

// pagination controls
export const LEFT_PAGE = "LEFT";
export const RIGHT_PAGE = "RIGHT";

// pagination control class names
const activePageClasses =
  "text-white bg-black border border-r-0 font-bold pointer-events-none";
const inactivePageClasses =
  "bg-white transition-colors duration-150 focus:outline-none hover:bg-black hover:text-white font-bold";

const prepareControlIconClasses = (isActivePage) =>
  classNames(
    "h-8 w-8 min-w-fit rounded-full flex items-center justify-center",
    { [activePageClasses]: isActivePage },
    { [inactivePageClasses]: !isActivePage }
  );

// pagination control icons
const controlIcons = {
  LEFT: ChevronLeftIcon,
  RIGHT: ChevronRightIcon,
};

const renderControlIcon = (page) => {
  const Icon = controlIcons[page];
  return <Icon className="h-5 w-5" aria-hidden="true" />;
};

export const PaginationControl = ({ page, isActivePage, onClick }) => {
  const classes = prepareControlIconClasses(isActivePage);

  const renderItem = [LEFT_PAGE, RIGHT_PAGE].includes(page)
    ? renderControlIcon(page)
    : page;

  return (
    <li>
      <button className={classes} onClick={onClick}>
        {renderItem}
      </button>
    </li>
  );
};
