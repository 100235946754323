import PropTypes from "prop-types";
import React from "react";

import cx from "@lango/common/classnames";
import { colSpanClassesMap, gridColsClassesMap } from "@lango/common/css";

/**
 * Grid component
 * @param {object} props - The props object.
 * @param {string} [props.extraClasses] - Additional CSS classes for the grid container
 * @param {number|string} [props.cols] - Number of columns for the grid (1-12) or "none" for no grid columns
 * @param {React.ReactNode} [props.children] - Child components to be rendered inside the grid
 * @returns {JSX.Element} - Grid component
 */
export const Grid = ({ extraClasses, cols, children }) => {
  const className = cx(
    "grid",
    gridColsClassesMap[cols], // Add the grid-cols class if cols is provided
    extraClasses,
  );

  const renderChild = React.Children.map(children, (child, index) => {
    if (!child) return;
    return child;
  });
  return <div className={className}>{renderChild}</div>;
};

Grid.propTypes = {
  cols: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, "none"]),
  extraClasses: PropTypes.string,
  children: PropTypes.node,
};

/**
 * Grid.Col component
 * @param {object} props - The props object.
 * @param {number|string} [props.span] - Number of columns the child component should span (1-12) or auto/full"
 * @param {string} [props.extraClasses] - Additional CSS classes for the child component
 * @param {React.ReactNode} [props.children] - Child components to be rendered inside the Grid.Col component
 * @returns {JSX.Element} - Grid.Col component
 */
Grid.Col = ({ span, extraClasses, children }) => {
  return (
    <div className={cx(colSpanClassesMap[span], extraClasses)}>{children}</div>
  );
};

Grid.Col.propTypes = {
  span: PropTypes.oneOf([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    "auto",
    "full",
  ]),
  extraClasses: PropTypes.string,
  children: PropTypes.node,
};
