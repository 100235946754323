import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";

import { renderLabel } from "@lango/common/components/Labels";
import { renderDateAndTime } from "@lango/common/helpers";

import TimeAgo from "../TimeAgo";

export const UserListColumns = ({ canUpdateOrg, handleRemoveUser, activeUserID}) => {
  const columnHelper = createColumnHelper();

  // todo: add user status in db
  return useMemo(
    () => [
      columnHelper.accessor("firstName", {
        id: "firstName",
        cell: (info) => info.getValue(),
        header: "First Name",
      }),
      columnHelper.accessor("lastName", {
        id: "lastName",
        cell: (info) => info.getValue(),
        header: "Last Name",
      }),
      columnHelper.accessor("email", {
        id: "email",
        cell: (info) => info.getValue(),
        header: "Email",
      }),
      columnHelper.accessor("roles", {
        id: "roles",
        cell: (info) => {
          const value = info.getValue();
          return value?.map((item) => renderLabel(item?.id, item?.name));
        },
        header: "Roles",
        enableSorting: false,
      }),
      columnHelper.accessor("createdAt", {
        id: "createdAt",
        cell: (info) => info.getValue() && renderDateAndTime(info.getValue()),
        header: "Created On",
      }),
      columnHelper.accessor("lastLoginAt", {
        id: "lastLoginAt",
        cell: (info) => renderUserLastLogin(info),
        header: "Last Login",
      }),
      columnHelper.accessor((row) => ``, {
        id: "actions",
        header: "Actions",
        cell: (row) => (
          // check for update access and don't allowing removing active user
          // active user is zero during onboarding
          !canUpdateOrg || !activeUserID || row.row.original.id === activeUserID ? null : (
          <button className={"text-base underline text-lango-orange"}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveUser(row.row.original);
            }}
          >
            Remove
          </button>
        )),
      }),
    ],
    [columnHelper],
  );
};

const renderUserLastLogin = (props) => {
  const userOrgLastLogins = props?.row?.original?.userOrgLastLogins;
  return (
    userOrgLastLogins?.length > 0 && (
      <TimeAgo date={userOrgLastLogins[0].lastLoginAt} />
    )
  );
};
