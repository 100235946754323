import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { useModal } from "@lango/common/context";
import { toastSuccess, toastWarning } from "@lango/common/features";
import { INVITE_VENDOR_USER } from "@lango/common/queries";
import { inviteVendorUserRequest } from "@lango/common/request";

export const useInviteVendorUser = (vendorProfileID, onComplete = () => {}) => {
  const { hideModal } = useModal();
  const [inviteVendorUser] = useMutation(INVITE_VENDOR_USER);

  const handleInviteVendorUser = useCallback(
    async (/** @type {any} */ values) => {
      try {
        await inviteVendorUser(
          inviteVendorUserRequest({ ...values, vendorProfileID }),
        );
        hideModal();
        toastSuccess("Vendor user invited successfully");
        onComplete();
      } catch (error) {
        toastWarning(error?.message || "Error inviting vendor user");
        console.error(error);
      }
    },
    [inviteVendorUser, vendorProfileID, onComplete],
  );

  return { handleInviteVendorUser };
};
