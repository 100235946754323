import { useReactiveVar } from "@apollo/client";
import { Fragment } from "react";

import { activeOrgVar } from "@lango/common/apollo/localState";
import Dropdown from "@lango/common/components/dropdown";

import ActiveOrg from "./ActiveOrg";
import MenuItems from "./MenuItems";
import UserProfile from "./UserProfile";

const BaseUserMenu = ({
  userFirstName,
  userLastName,
  unreadNotificationsCount,
  menuItems,
}) => {
  const activeOrg = useReactiveVar(activeOrgVar);

  return (
    <Dropdown>
      <Dropdown.Button className="inline-flex items-center">
        <UserProfile
          userFirstName={userFirstName}
          userLastName={userLastName}
          activeOrgName={activeOrg && activeOrg?.name}
          unreadNotificationsCount={unreadNotificationsCount}
        />
      </Dropdown.Button>
      <Dropdown.Menu>
        <Fragment>
          <ActiveOrg activeOrgName={activeOrg && activeOrg?.name} />
          <MenuItems menuItems={menuItems} />
        </Fragment>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BaseUserMenu;
