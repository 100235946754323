/**
 *
 * This is a custom React Hook that fetches job types, statuses and all languages.
 *
 * Usage:
 * const { jobStatuses, jobTypes, allLanguages, loading, error } = useFetchJobFilters();
 *
 * @module useFetchJobFilters
 * @returns {Object} - An object containing jobStatuses, jobTypes, allLanguages, loading and error.
 */

import { useQuery } from "@apollo/client";

import { JOB_FILTER } from "@lango/common/queries";
import {
  ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY,
  HUMAN_TRANSLATION,
  INTENTO_PROVIDER,
  MACHINE_TRANSLATION_JOB_TYPE_CATEGORY,
} from "../constants";

export const useFetchJobFilters = () => {
  const { data, loading, error } = useQuery(JOB_FILTER, {
    variables: {
      types: [
        ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY,
        HUMAN_TRANSLATION,
        MACHINE_TRANSLATION_JOB_TYPE_CATEGORY,
      ],
      provider: INTENTO_PROVIDER,
    },
  });

  const jobTypes = data?.allJobTypes || [];
  const jobStatuses = data?.allJobStatuses || [];
  const allLanguages =
    data?.allProviderLanguages.map(({ label }) => ({
      label,
      // When passing filters to the backend we don't use the language ID, only its label in English.
      value: label,
    })) || [];

  return {
    loading,
    error,
    jobStatuses,
    jobTypes,
    allLanguages,
  };
};
