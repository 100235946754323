import { useQuery, useReactiveVar } from "@apollo/client";

import {
  activeLSPVar,
  activeOrgVar,
  invitedLSPVar,
} from "@lango/common/apollo/localState";
import envs from "@lango/common/env";
import { ORGANIZATION_LSPS } from "@lango/common/queries";

const useGetOrgLSPs = () => {
  const activeOrg = useReactiveVar(activeOrgVar);
  const { data, error, loading } = useQuery(ORGANIZATION_LSPS, {
    variables: {
      organizationID: activeOrg?.id,
      sourceAppName: envs.appName,
    },
    skip: !activeOrg?.id,
    onCompleted: (data) => {
      /**
       * Updates the active LSP based on the fetched organization LSPs.
       * If the previous active LSP is found in the fetched LSPs, it sets it as the active LSP.
       * Otherwise, it sets the first fetched LSP as the active LSP.
       */
      const prevLSP = activeLSPVar();
      const invitedLSPId = invitedLSPVar();
      const newLSPs = data?.findOrganizationLSPs || [];

      const findLSPById = (id) => newLSPs?.find((lsp) => lsp?.id === id);

      const updatedLSP = findLSPById(prevLSP?.id);
      const invitedLSP = findLSPById(invitedLSPId);

      const selectedLSP = invitedLSP || updatedLSP || newLSPs[0] || {};

      activeLSPVar(selectedLSP);
      selectedLSP?.id === invitedLSPId && invitedLSPVar(null);
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: "cache-and-network",
  });

  return { loading, error, orgLSPs: data?.findOrganizationLSPs || [] };
};

export default useGetOrgLSPs;
