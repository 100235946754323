import { toast } from "react-toastify";


const Msg = ({ textHeading, textBody }) => (
  <>
    <span className={"font-bold"}> {textHeading}</span> {textBody}
  </>
);

const errorLevel = "error";
const successLevel = "success";
const infoLevel = "info";
const warningLevel = "warning";

const triggerURLStorageKey = "triggerToastAtURL";

// for the following functions, options can be: triggerAtURL
// to delay the toast until the user is at a certain URL
// and any options supported by react-toastify

function toastSuccess(message, options) {
  toastWrapper(toast.success, "Success!", message, { level: successLevel, ...options});
}

function toastError(message, options) {
  toastWrapper(toast.error, "Uh oh!", message, { level: errorLevel, ...options});
}

function toastInfo(message, options) {
  toastWrapper(toast.info, "", message, {level: infoLevel, ...options});
}

function toastWarning(message, options) {
  toastWrapper(toast.warning, "Notice:", message, {level: warningLevel, ...options});
}

// internal helper

function toastWrapper(toastFunc, heading, message, options) {
  if (typeof message != "string") {
    console.error("Non string message sent to toast", message);
    return;
  }

  if (options.triggerAtURL) {
    setTriggerURL(message, options);
    return;
  }

  if (heading) toastFunc(<Msg textHeading={heading} textBody={message} />, options);
  else toastFunc(message, options);
} 

function toastLevel(message, options) {
  switch (options?.level) {
    case errorLevel:
      toastError(message, options);
      break;
    case successLevel:
      toastSuccess(message, options);
      break;
    case infoLevel:
      toastInfo(message, options);
      break;
    case warningLevel:
      toastWarning(message, options);
      break;
  }
}

function checkTriggerURL(location) {
  const triggerAtURL = getTriggerURL();
  if (triggerAtURL) {
    if (location.pathname.includes(triggerAtURL?.options?.triggerAtURL)) {
      triggerAtURL.options.triggerAtURL = null; // display immediately
      toastLevel(triggerAtURL.message, triggerAtURL.options);
      clearTriggerURL();
    }
  }
}

function setTriggerURL(message, options) {
  localStorage.setItem(triggerURLStorageKey, JSON.stringify({options: options, message: message}));
}

function getTriggerURL() {
  const stored = localStorage.getItem(triggerURLStorageKey);
  let obj = null;
  if (stored) {
    try {
      obj = JSON.parse(stored);
    } catch (e) {
      console.error(e);
    }
  }

  return obj;
}

function clearTriggerURL() {
  localStorage.removeItem(triggerURLStorageKey);
}

export function clearAllToasts() {
  toast.dismiss();
}



export { toastSuccess, toastError, toastInfo, toastWarning, toastLevel, setTriggerURL, checkTriggerURL };