// Contains type policies corresponding to the protected/billing.graphql schema
// Reference: https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields

export const billingPolicies = {
  Billable: {
    keyFields: false,
  },
  LineItem: {
    keyFields: false,
  },
  PaginatedOrgBillables: {
    keyFields: false,
  },
};
