import React from "react";
import { Droppable } from "react-beautiful-dnd";

import DraggableColumn from "./DraggableColumn";

const DraggableColumns = ({ columns }) => {
  return (
    <Droppable droppableId="columns">
      {(provided) => (
        <ul
          className="characters"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {columns.map((column, index) => (
            <DraggableColumn
              key={index}
              id={column.id}
              header={column.columnDef.header}
              index={index}
              getIsVisible={column.getIsVisible}
              getToggleVisibilityHandler={column.getToggleVisibilityHandler}
            />
          ))}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default DraggableColumns;
