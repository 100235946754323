import { createColumnHelper } from "@tanstack/react-table";

import {
  convertBytes,
  renderDate,
  renderFullName,
  renderJobLanguagePair,
  renderJobStatus,
} from "@lango/common/helpers";

const columnHelper = createColumnHelper();

// todo: de-duplicate from the buyer version
export const columns = [
  columnHelper.accessor("sourceFile.name", {
    id: "tasks.sourceFile.name",
    header: "File Name",
  }),
  columnHelper.accessor("sourceFile.size", {
    id: "tasks.sourceFile.size",
    header: "File Size",
    cell: (ctx) => convertBytes(ctx.getValue()),
  }),
  columnHelper.accessor("languagePair", {
    id: "tasks.language",
    cell: ({ row: { original } }) => {
      const languagePair = {
        languageFrom: original.languageFrom,
        languageTo: original.languageTo,
        sourceLanguages: [],
      };

      return renderJobLanguagePair(languagePair);
    },
    header: "Language Pair",
  }),
  columnHelper.accessor("assignedUser", {
    cell: (ctx) => {
      return renderFullName(ctx.getValue());
    },
    header: "Linguist",
  }),
  columnHelper.accessor("wordCount", {
    id: "tasks.wordCount",
    header: "Word Count",
  }),
  columnHelper.accessor("createdAt", {
    header: "Date",
    cell: (ctx) => renderDate(ctx.getValue()),
  }),
  columnHelper.accessor("taskStatus.name", {
    id: "tasks.taskStatus.name",
    header: "Status",
    cell: (ctx) => renderJobStatus(ctx.getValue()),
  }),
];
