import cx from "@lango/common/classnames";

export const UnreadIcon = ({ extraClasses = "", containerClasses = "" }) => {
  const classes = cx(
    "absolute top-0 left-6 block h-3 w-3 rounded-full bg-red-500 ring-2 ring-white",
    extraClasses,
  );
  return (
    <div className={containerClasses}>
      <span className={classes} />
    </div>
  );
};
