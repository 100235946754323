import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { useModal } from "@lango/common/context";
import { toastSuccess, toastWarning } from "@lango/common/features";
import { INVITE_CLIENT_USER } from "@lango/common/queries";
import { inviteClientUserRequest } from "@lango/common/request";

export const useInviteClientUser = (clientProfileID) => {
  const { hideModal } = useModal();
  const [inviteClientUser] = useMutation(INVITE_CLIENT_USER);

  const handleInviteClientUser = useCallback(
    async (/** @type {any} */ values) => {
      try {
        await inviteClientUser(
          inviteClientUserRequest({ ...values, clientProfileID }),
        );
        hideModal();
        toastSuccess("Client user invited successfully");
      } catch (error) {
        toastWarning(error?.message || "Error inviting client user");
        console.error(error);
      }
    },
    [inviteClientUser],
  );

  return { handleInviteClientUser };
};
