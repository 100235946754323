import Table from "@lango/common/features/tables/components/Table";

import { UserListColumns } from "./UserListColumns";

export const UserTable = ({
  showModal,
  showRemoveUserModal,
  setInitialValues,
  enableRowClick,
  canUpdateOrg,
  activeUserID,
  userTableProps,
}) => {

  const handleSelectUserToUpdateRole = (user) => {
    if (user?.id === activeUserID) {
      // return so that user row is not clickable
      return;
    }
    showModal();
    const userRoles = user?.roles.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setInitialValues({ userRoles, user });
  };

  const handleRemoveUser = (user) => {
    showRemoveUserModal();
    setInitialValues({ user });
  };

  const columns = UserListColumns({canUpdateOrg, handleRemoveUser, activeUserID});

  return (
    <Table
      columns={columns}
      defaultPageSize={10}
      onRowClick={enableRowClick ? handleSelectUserToUpdateRole : null}
      {...userTableProps}
      enablePagination
    />
  );
};
