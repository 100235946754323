import { useQuery } from "@apollo/client";

import { toastError } from "@lango/common/features/alerts/functions/toast";

import { CLIENT_PROFILE_VRI_SETTINGS } from "@lango/common/queries";
import { useParams } from "react-router-dom";

export const useFetchClientProfileVRISettings = () => {
  const { clientProfileID } = useParams();
  const { loading, error, data } = useQuery(CLIENT_PROFILE_VRI_SETTINGS, {
    variables: {
      clientProfileID
    },
    fetchPolicy: "no-cache",
    onError: (error) => toastError(error?.message),
  });
  return {
    settings: data?.clientProfileVRISettings,
    error,
    loading,
  };
};

