/**
 * This file contains the field configurations and components for the onboarding step 1.
 * It includes fields for agency and individual profiles, as well as common fields for company admin and address.
 * The file also includes a helper function to render field groups and a custom field component for selecting industries.
 */
import { Divider } from "@aws-amplify/ui-react";
import { Box } from "@lango/common/components";
import { FormControl } from "@lango/common/features";
import { useFetchAllIndustries } from "@lango/common/hooks";
import { useAllGenders } from "../../userSettings/userSettingForm/prepareGender";

// Field configurations for company admin
const company_admin_fields = [
  {
    control: "input",
    name: "firstName",
    label: "First Name",
    placeholder: "Enter first name",
  },
  {
    control: "input",
    name: "lastName",
    label: "Last Name",
    placeholder: "Enter last name",
  },
  {
    control: "input",
    name: "title",
    label: "Job Title",
    placeHolder: "Enter job title",
  },
  {
    control: "input",
    type: "email",
    name: "email",
    label: "Email",
    placeholder: "Enter email",
  },
  {
    control: "phone",
    name: "phoneNumber",
    label: "Phone Number",
    placeholder: "Enter phone number",
  },
];

// Field configurations for company address
const company_address_fields = [
  {
    control: "input",
    name: "country",
    label: "Country",
    placeholder: "Select country",
  },
  {
    control: "input",
    name: "address1",
    label: "Address",
    placeholder: "Enter address",
    containerClasses: "md:col-start-1",
  },
  {
    control: "input",
    name: "address2",
    label: "Apartment, suite, building, floor",
    placeholder: "Enter apartment, suite, building, floor, etc",
  },
  {
    control: "input",
    name: "city",
    label: "City",
    placeholder: "Enter city",
  },
  {
    control: "input",
    name: "state",
    label: "State",
    placeholder: "Enter state",
  },
  {
    control: "input",
    name: "postalCode",
    label: "Postal code",
    placeholder: "Enter postal code",
  },
];

/**
 * Custom field component for selecting industries.
 * Fetches all industries using the useFetchAllIndustries hook.
 * Renders a select control with options for industries.
 */
export function SelectIndustry() {
  const { allIndustries, loading } = useFetchAllIndustries();

  return (
    <FormControl
      control="select"
      name="industries"
      label="Industry (optional)"
      placeholder="Select Industry"
      isDisabled={loading}
      options={allIndustries}
      isLoading={loading}
      isMulti
    />
  );
}

/**
 * Component for rendering agency fields.
 * Includes basic info, company admin fields, and company address fields.
 */
export function AgencyFields() {
  return (
    <Box extraClasses="bg-white rounded-xl shadow-md my-8">
      <FieldContainer heading="Basic Info">
        <FormControl
          control="input"
          name="name"
          label="Vendor name"
          placeholder="Enter vendor name"
        />
        {/* todo: add user's logo component*/}
        <div></div>
        <SelectIndustry />
      </FieldContainer>
      <Divider />
      {renderFieldGroup("Company Admin", company_admin_fields)}
      <Divider />
      {renderFieldGroup("Company Address", company_address_fields)}
    </Box>
  );
}

// Field configurations for individual profile basic info
const individual_fields = [
  {
    control: "input",
    name: "firstName",
    label: "First name",
    placeholder: "Enter first name",
  },
  {
    control: "input",
    name: "lastName",
    label: "Last name",
    placeholder: "Enter last name",
  },
  {
    control: "input",
    name: "email",
    type: "email",
    label: "Email",
    readOnly: true,
    disabled: true,
  },
  {
    control: "phone",
    name: "phone",
    label: "Phone number",
    placeholder: "Enter phone number",
  },
];

/**
 * Component for rendering individual fields.
 * Includes basic info and company address fields.
 */
export function IndividualFields() {
  const { allGenders, loading } = useAllGenders();
  return (
    <Box extraClasses="bg-white rounded-xl shadow-md my-8">
      <FieldContainer heading="Basic Info">
        <div className="grid grid-cols-1 gap-y-4">
          {individual_fields.map((field) => (
            <FormControl {...field} />
          ))}
          <FormControl
            control="select"
            name="gender"
            label="Gender"
            placeholder="Gender"
            options={allGenders}
            isLoading={loading}
          />
        </div>
        {/* todo: add vendor company's logo component*/}
        <div></div>
      </FieldContainer>
      <Divider />
      {renderFieldGroup("Address", company_address_fields)}
    </Box>
  );
}

/**
 * Component for rendering a field container.
 * Includes a heading and children components.
 */
export function FieldContainer({ heading = "", children }) {
  return (
    <Box heading={heading} spaceY={6} extraClasses="p-4 sm:p-8">
      <Box extraClasses={"grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-32"}>
        {children}
      </Box>
    </Box>
  );
}

/**
 * Helper function to render a field group.
 * Includes a heading and an array of field configurations.
 */
export function renderFieldGroup(heading = "", fields = []) {
  return (
    <FieldContainer heading={heading}>
      {fields.map((field) => (
        <FormControl {...field} />
      ))}
    </FieldContainer>
  );
}
