export const AUTH_ROUTES = {
  LOGOUT: "/logout",
  LOGIN: "/login",
  ORG_LOGIN: "/login/:org",
  COGNITO_LOGIN: "/auth/sso",
  COGNITO_LOGOUT: "/auth/sso/logout",
  FORGOT_PASSWORD: "/forgot-password",
  CONFIRM_FORGOT_PASSWORD: "/confirm-forgot-password",
};

export const VENDOR_ROUTES = {
  TASKS: "/tasks",
  REQUESTS: "/requests",
  SETTINGS: "/settings",
  ONBOARDING: "/onboarding",
  VERIFY_INVITATION: "/verify_invitation/:token",
};

export const PM_ROUTES = {
  SETTINGS: "/settings/*",
  JOB_ROUTINGS: "/settings/job-routings",
  JOB_ROUTING: "/settings/job-routings/:jobRoutingID/*",
  REQUESTS: "/requests",
};

export const BUYER_ROUTES = {
  PROJECT_DETAILS: "/project/:projectID",
  REQUESTS_LIST: "/requests/*",
  PROJECTS_LIST: "/projects/*",
  JOB_DETAILS: "/request/job/:jobID",
  PRIVACY: "/privacy",
  SETTINGS: "/settings/*",
  BOX_LOGIN: "/box",
  ONEDRIVE_LOGIN: "/onedrive",
};

export const USER_ROUTES = {
  LINGUIST: (userId, organizationId) =>
    `/users/${userId}/organizations/${organizationId}`,
};

export const COMMON_ROUTES = {
  INVITATIONS: "/invitations",
  DOWNLOAD_JOB_FILES: "/download_job_files",
  DOWNLOAD_TASK_FILE: "/download_task_file",
  DOWNLOAD_TASK_FILES: "/download_task_files",
};
