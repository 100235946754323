import cx from "@lango/common/classnames";

const LangoLogo = ({ width = "w-24", inline = true }) => (
  <img
    className={cx(width, { inline: inline })}
    src={require("./logo.png")}
    alt="Logo"
  />
);

export default LangoLogo;
