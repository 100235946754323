import { Formik } from "formik";
import ClientVRISettingFields from "./clientVRISettingFields";
import {
  useFetchClientProfileVRISettings,
  useUpdateClientProfileVRISettings,
} from "@lango/common/hooks";
import { WithLoader } from "@lango/common/components";

const ClientVRISettingsForm = () => {
  const { handleUpdateClientVRISettings } = useUpdateClientProfileVRISettings();
  const { settings, ...props } = useFetchClientProfileVRISettings();
  return (
    <WithLoader {...props}>
      <Formik initialValues={settings} onSubmit={handleUpdateClientVRISettings}>
        <ClientVRISettingFields />
      </Formik>
    </WithLoader>
  );
};

export default ClientVRISettingsForm;
