import { useEffect } from "react";

import { usernameVar } from "@lango/common/apollo/localState";
import environmentVars from "@lango/common/env";

import { resetHubspotOnUnloadIfUnauthenticated } from "./utils";

/**
 * Hubspot Chat Widget
 * - Embeds a new hubspot script to mount the chat widget
 * - Creates an instance of hubspot chat widget
 * - Sets user identity on hubspot by email
 * - Removes the hubspot embed script on component unmount
 * @ReferenceLink https://community.hubspot.com/t5/CMS-Development/Embed-Chat-Into-A-ReactJS-SPA/m-p/354887
 */
export const HubspotChat = () => {
  useEffect(() => {
    // Create script component.
    if (environmentVars.hubspotPortalID) {
      let script = document.createElement("script");
      script.src = `//js.hs-scripts.com/${environmentVars.hubspotPortalID}.js`;
      script.async = true;
      document.body.appendChild(script);

      window.hsConversationsOnReady = [
        () => {
          var _hsq = (window._hsq = window._hsq || []);
          const email = usernameVar();
          _hsq.push([
            "identify",
            {
              email: email || "",
            },
          ]);
          setTimeout(() => {
            window.HubSpotConversations?.widget?.refresh?.();
          }, 1000);
        },
      ];
      resetHubspotOnUnloadIfUnauthenticated();

      return () => {
        document.body.removeChild(script);
        window.hsConversationsOnReady = [];
      };
    }
  }, []);
};
