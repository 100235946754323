import { useMutation } from "@apollo/client";

import {
  toastError,
  toastSuccess,
} from "@lango/common/features/alerts/functions/toast";
import { UPDATE_LINGUIST_PROFILE } from "@lango/common/queries";

import { updateLinguistProfileRequest } from "../../utils";

const useUpdateLinguistProfile = () => {
  const [updateLinguistProfile] = useMutation(UPDATE_LINGUIST_PROFILE);

  const handleUpdateLinguistProfile = async (values, { resetForm }) => {
    try {
      const request = updateLinguistProfileRequest(values);
      await updateLinguistProfile(request);
      toastSuccess("Linguist profile has been updated.");
      resetForm?.({ values });
    } catch (error) {
      toastError("There was an error updating the linguist profile");
    }
  };

  return { handleUpdateLinguistProfile };
};

export default useUpdateLinguistProfile;
