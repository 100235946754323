/**
 * This file contains the hook useFetchVendorHTJobTasks which is used to fetch tasks related to a specific job.
 * It uses a lazy query from Apollo Client to fetch the data when needed.
 *
 * @module useFetchVendorHTJobTasks
 */

import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

import { activeOrgVar } from "@lango/common/apollo/localState";
import { PAGINATED_HT_JOB_TASKS } from "@lango/common/queries";

import { preparePaginatedJobTaskRequest } from "./utils";

export const useFetchVendorHTJobTasks = (jobID) => {
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const [getJobTasks, { data }] = useLazyQuery(
    PAGINATED_HT_JOB_TASKS,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const fetchData = useCallback(
    (props) => {
      getJobTasks(
        preparePaginatedJobTaskRequest({ ...props, jobID, organizationID })
      );
    },
    [getJobTasks, jobID, organizationID]
  );

  return {
    fetchData,
    data: data?.paginatedTasksByJob?.tasks ?? [],
    totalRecords: data?.paginatedTasksByJob?.totalRecords,
  };
};
