import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import { ALL_VENDOR_PROFILE_LANGUAGE } from "@lango/common/queries";
import { prepareVendorProfileLanguageRequest } from "@lango/common/request";

export const useGetVendorProfileLanguage = ({ lspID, organizationID }) => {
  const [paginatedVendorProfileLanguage, { data, loading, error }] =
    useLazyQuery(ALL_VENDOR_PROFILE_LANGUAGE, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  const fetchVendorProfileLanguages = useCallback(
    (props) => {
      const request = prepareVendorProfileLanguageRequest({
        ...props,
        lspID,
        organizationID,
      });
      paginatedVendorProfileLanguage(request);
    },
    [paginatedVendorProfileLanguage, organizationID, lspID],
  );

  return {
    data: data?.paginatedVendorProfileLanguage?.vendorProfileLanguages || [],
    totalRecords: data?.paginatedVendorProfileLanguage?.totalRecords,
    fetchData: fetchVendorProfileLanguages,
    loading,
    error,
  };
};
