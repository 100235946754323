import { Modal as FlowbiteModal } from "flowbite-react";
import React from "react";

const Header = ({ children, ...props }) => (
  <FlowbiteModal.Header {...props} className={"border-b-0"}>{children}</FlowbiteModal.Header>
);

const Body = ({ children, ...props }) => (
  <FlowbiteModal.Body className={"pt-2"} {...props}>{children}</FlowbiteModal.Body>
);

const Footer = ({ children, ...props }) => (
  <FlowbiteModal.Footer {...props}>{children}</FlowbiteModal.Footer>
);

const Modal = ({ destroyOnClose, children, ...props }) => {
  const open = destroyOnClose ? props?.show : true;
  return (
    open && (
      <FlowbiteModal {...props}>
        {React.Children.map(children, (child) => {
          if (!child) return;
          return child;
        })}
      </FlowbiteModal>
    )
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
