import React from "react";

import classNames from "@lango/common/classnames";

import ButtonLoader from "./ButtonLoader";

/**
 * Renders AddButton Component
 *
 * @param {any} props - The props object.
 */
const Button = ({
  type,
  text,
  textButton = false,
  icon,
  children,
  importance = "primary",
  showLoader = false,
  loaderText,
  onClick,
  disabled = false,
  extraClasses = "",
  ...props
}) => {
  let btnClass = classNames(
    "inline-flex justify-center transition duration-300 ease-in-out",
    extraClasses,
    {
      "py-3 px-14 text-center shadow-sm rounded-full": !textButton,
      "bg-none text-black md:text-md underline font-medium hover:text-gray-700 hover:border-0":
        textButton,
      "bg-black text-white":
        importance !== "secondary" && !textButton && (!disabled || showLoader),
      "bg-white text-black hover:bg-gray-400 hover:text-black hover:border-2 hover:border-black":
        importance === "secondary" && !textButton && !disabled && !showLoader,
      "border-2 border-gray-400 bg-gray-400 text-white":
        disabled && !textButton && !showLoader,
      "border-2 border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black":
        !disabled && !textButton && !showLoader,
      "text-gray-500": disabled && textButton,
      "cursor-not-allowed": disabled,
      "items-center": icon,
      "hover:border-2 hover:border-black hover:bg-white hover:text-black":
        !showLoader && !textButton,
      "hover:bg-gray-400 hover:text-white": !showLoader && !disabled && importance === "secondary",
    },
  );

  return (
    <>
      <button
        type={type}
        className={btnClass}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {showLoader ? (
          <ButtonLoader message={loaderText} />
        ) : (
          <>
            {icon ? <span className={"-ml-1 mr-2"}>{icon}</span> : null} {text}
          </>
        )}
        {children}
      </button>
    </>
  );
};

export default Button;
