import { useQuery, useReactiveVar } from "@apollo/client";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { FIND_LINGUIST_PROFILE } from "@lango/common/queries";

import { prepareLinguistProfile } from "./utils";
import { activeOrgVar } from "../apollo";

export const useFetchLinguistProfile = (userID) => {
  const { id } = useReactiveVar(activeOrgVar);
  const { linguistID, organizationID } = useParams();
  const { loading, data, error } = useQuery(FIND_LINGUIST_PROFILE, {
    variables: {
      userID: linguistID || userID,
      vendorID: organizationID || id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const values = useMemo(() => prepareLinguistProfile(data), [data]);

  return { loading, error, values };
};

export default useFetchLinguistProfile;
