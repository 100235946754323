import { ApolloProvider } from "@apollo/client";
import { Authenticator } from "@aws-amplify/ui-react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import "react-dates/initialize";
import { HelmetProvider } from "react-helmet-async";

import { client } from "@lango/common/apollo/client";
import "@lango/common/css/index.css";
import { initializeFullStory } from "@lango/common/helpers";
import { ModalProvider } from "@lango/common/context";

import { combineComponents } from "../helpers";

initializeFullStory();
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);

const AP = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

const defaultProviders = [
  Authenticator.Provider,
  AP,
  HelmetProvider,
  ModalProvider,
];
export const AppContextProvider = ({ additionalProviders = [], children }) => {
  const Combined = combineComponents(
    ...defaultProviders,
    ...additionalProviders,
  );
  return <Combined>{children}</Combined>;
};
