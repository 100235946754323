export const iconButtonStyles =
  "group inline inline-flex items-center rounded-full bg-white p-2 text-base font-medium text-black hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 md:p-3 ml-5 cursor-pointer";
export const iconButtonStylesInner = "w-4 md:w-5";

export const IconButton = ({ onClick, Icon, ...props }) => {
  return (
    <button className={iconButtonStyles} onClick={onClick} {...props}>
      <Icon className={iconButtonStylesInner} />
    </button>
  );
};
