import ContentLoader from "react-content-loader";

const WithNotificationsLoader = ({ pageLimit, loading, error, children }) => {
  if (loading || error) {
    return Array.from({ length: pageLimit }).map((_, index) => (
      <div className="bg-white p-6 sm:rounded-tr-none" key={index}>
        <ContentLoader
          speed={2}
          width={1100}
          height={100}
          viewBox="0 0 1100 100"
          backgroundColor="#d9d4d4"
          foregroundColor="#ecebeb"
        >
          <rect x="5" y="15" rx="3" ry="3" width="100" height="10" />
          <rect x="5" y="35" rx="3" ry="3" width="52" height="7" />
          <rect x="5" y="80" rx="3" ry="3" width="800" height="9" />
          <rect x="1070" y="80" rx="3" ry="3" width="44" height="7" />
        </ContentLoader>
      </div>
    ));
  }
  return children;
};

export default WithNotificationsLoader;
