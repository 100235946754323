import { getFirstLastCharacters } from "@lango/common/helpers";
import { useReadOrgNotification } from "@lango/common/hooks";
import { Notification } from "./Notification";
/**
 * OrganizationNotificationsTab Component
 *
 * Displays the list of organization notifications in the notifications panel.
 *
 * @param {Object} props - The props for the OrganizationNotification component.
 * @param {Object} props.notifications - The array of organization notification objects to display
 * @param {function} props.closePanel - A function to close the panel
 * @returns {React.ReactNode} The rendered OrganizationNotification component.
 */
export const OrganizationNotificationsTab = ({ notifications, closePanel }) => {
  const { handleReadNotification } = useReadOrgNotification();
  return (
    <>
      {notifications.map((notification, key) => (
        <Notification
          key={key}
          title={notification.title}
          description={notification.description}
          onClick={() => {
            handleReadNotification(notification?.id, notification?.sentByID);
            closePanel();
          }}
          isRead={notification.isRead}
          createdAt={notification.createdAt}
          initials={getFirstLastCharacters(notification.sentBy?.name)}
        />
      ))}
    </>
  );
};
