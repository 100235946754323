import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useField } from "formik";
import { useState } from "react";

import classNames from "@lango/common/classnames";
import InputLabel from "@lango/common/features/forms/components/InputLabel";

const Textarea = ({
  label,
  showLabel,
  extraClasses,
  size,
  infoText = null,
  maxLength = 500,
  showCount = false,
  whiteBackground = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [count, setCount] = useState(field?.value?.length || 0);

  const handleInputChange = (e) => {
    setCount(e.target.value.length);
    field.onChange(e);
  };

  let btnClass = classNames(
    "block w-full border border-gray-200 text-black focus:ring-gray-700 focus:border-gray-700 placeholder-gray-400",
    extraClasses,
    {
      "py-2 px-2 rounded": size === "small",
      "!bg-white": whiteBackground,
      "py-3 px-5 bg-gray-100 rounded-md": size !== "small",
    },
    {
      "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
        meta.touched && meta.error,
    },
  );
  return (
    <div className={"text-left"}>
      {showLabel !== false ? (
        <InputLabel
          labelFor={props.id || props.name}
          text={label}
          infoText={infoText}
        />
      ) : null}
      <div className="relative">
        <textarea
          className={btnClass}
          aria-describedby={props.errorMsgID}
          rows={6}
          maxLength={maxLength}
          onInput={handleInputChange}
          {...field}
          {...props}
        ></textarea>
        {showCount && (
          <div className="float-right right-0 bottom-0 mr-1 mb-2 text-sm text-gray-500">
            {count}/{maxLength}
          </div>
        )}
        {meta.touched && meta.error ? (
          <div className="pointer-events-none absolute top-0 right-0 top-1.5 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      <p className="ml-3 mt-2 h-1 text-sm text-red-600" id={props.errorMsgID}>
        {meta.touched && meta.error ? meta.error : null}
      </p>
    </div>
  );
};

export default Textarea;
