// Contains type policies corresponding to the protected/organization.graphql schema
// Reference: https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields

import { idField } from "./consts";

export const organizationPolicies = {
  VendorProfile: {
    keyFields: idField,
  },
  VendorRate: {
    keyFields: false,
  },
  VendorContact: {
    keyFields: false,
  },
  VendorProfileData: {
    keyFields: false,
  },
  Organization: {
    keyFields: false,
  },
  OrganizationType: {
    keyFields: idField,
  },
  PaginatedVendorRates: {
    keyFields: false,
  },
  PaginatedClientRates: {
    keyFields: false,
  },
  ClientAndRate: {
    keyFields: false,
  },
  ClientProfile: {
    keyFields: false,
  },
  ClientContact: {
    keyFields: false,
  },
  ClientRate: {
    keyFields: false,
  },
  ClientProfileData: {
    keyFields: false,
  },
  PaginatedVendorContacts: {
    keyFields: false,
  },
  PaginatedOrgVendors: {
    keyFields: false,
  },
  PaginatedOrgClients: {
    keyFields: false,
  },
  PaginatedClientContacts: {
    keyFields: false,
  },
  PaginatedOrgUsers: {
    keyFields: false,
  },
  CloudStorageProvider: {
    keyFields: idField,
  },
  OrganizationCloudStorageProvider: {
    keyFields: false,
  },
  NoticeType: {
    keyFields: idField,
  },
  Notice: {
    keyFields: idField,
  },
  PaginatedOrgNotices: {
    keyFields: false,
  },
};
