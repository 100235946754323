/**
 * This file contains utility functions for the JobFilterModal component.
 *
 * The buildFiltersRequest function takes the form values as an argument and builds a filters request object from them.
 * It checks if the status, type, source language, and target language values are present, and if they are, it adds them to the commonFilters array in the filters request object.
 * The sourceLanguage and targetLanguage values are added with a like property set to true, indicating that the filter should match jobs where the language is similar to the specified value.
 * The function returns the filters request object, which also includes an empty customFilters array.
 */

import {
  exactMatchFilters,
  filterType,
  filterTypes,
  fuzzyMatchFilters,
} from "@lango/common/request";

// Support both string values and react-select object format.
const getValue = (value) => {
  if (Array.isArray(value)) {
    return value.map(getValue);
  }
  return typeof value === "string" ? value : value?.value.toString();
};

// Ensure array.
const toArray = (v) => (Array.isArray(v) ? v : [v]);

export const buildFiltersRequest = (formValues) => {
  const filters = { [filterTypes.common]: [], [filterTypes.custom]: [] };

  exactMatchFilters.forEach((key) => {
    const values = getValue(toArray(formValues[key]));
    if (values) {
      filters[filterType[key]].push({ key, values });
    }
  });

  fuzzyMatchFilters.forEach((key) => {
    const values = getValue(toArray(formValues[key]));
    if (values) {
      filters[filterType[key]].push({ key, values, like: true });
    }
  });

  return filters;
};
