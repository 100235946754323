import { CloudUploadIcon } from "@heroicons/react/solid";
import { useFormikContext } from "formik";
import { useDropzone } from "react-dropzone";

import classNames from "@lango/common/classnames";
import { Box, Grid, Loader } from "@lango/common/components";
import { allowedFileTypesMT } from "@lango/common/constants";
import { useUploadVendorFile } from "@lango/common/hooks";
import environmentVars from "@lango/common/env";

export default function TaxForm({ fileName }) {
  const { isSubmitting } = useFormikContext();
  const { handleUploadVendorFile, loading, error } = useUploadVendorFile();

  function onDrop(files) {
    handleUploadVendorFile(files);
  }

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      disabled: isSubmitting,
      maxFiles: 1,
      maxSize: Number(environmentVars.taxFileSizeLimit),
      multiple: false,
      accept: allowedFileTypesMT,
    });

  return (
    <Box
      heading="Tax forms"
      description="Upload W9 form (optional)"
      extraClasses="p-8"
    >
      {loading ? (
        <Loader message="Uploading files..." />
      ) : error ? (
        <p>Error uploading file</p>
      ) : fileName ? (
        <p>File already uploaded: {fileName}</p>
      ) : acceptedFiles.length > 0 ? (
        <p>{acceptedFiles[0].name}</p>
      ) : (
        <div
          {...getRootProps()}
          className={classNames("dropzone mt-1 grow sm:flex-1", {
            isActive: isDragActive,
          })}
        >
          <input {...getInputProps()} />
          <Grid extraClasses="cursor-pointer place-items-center rounded-lg bg-lango-gray p-12 mt-4">
            <CloudUploadIcon className="w-12 stroke-gray-500" />
            <h1 className="text-lg font-semibold text-gray-700">
              Browse file here
            </h1>
            <p className="text-sm text-gray-500">
              Supported file types: doc • pdf • txt
            </p>
          </Grid>
        </div>
      )}
    </Box>
  );
}
