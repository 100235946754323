// Contains type policies corresponding to the public/auth.graphql schema
// Reference: https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields

export const authPolicies = {
  SSOUserCheckResponse: {
    keyFields: false,
  },
  OrgCustomLoginInfo: {
    keyFields: false,
  },
};
