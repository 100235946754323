import { useReactiveVar } from "@apollo/client";
import { CogIcon, CollectionIcon, LogoutIcon } from "@heroicons/react/solid";
import { useState } from "react";

import {
  userFirstNameVar,
  userLastNameVar,
  userOrgsVar,
} from "@lango/common/apollo/localState";
import OrganizationSwitcherModal from "@lango/common/components/OrganizationSwitcherModal";
import BaseUserMenu from "@lango/common/features/header/components/BaseUserMenu";
import { NotificationPanel } from "@lango/common/features/notifications";
import { AUTH_ROUTES } from "@lango/common/routes";
import { useHasOrganizations } from "../../auth/hooks/useHasOrganizations";

const UserMenu = () => {
  const userFirstName = useReactiveVar(userFirstNameVar);
  const userLastName = useReactiveVar(userLastNameVar);
  const userOrgs = useReactiveVar(userOrgsVar);
  const [showOrgSwitcherModal, setOrgSwitcherShowModal] = useState(false);
  const unreadNotificationsCount = 0;
  const { hasOrganizations } = useHasOrganizations();

  function handleShowOrgSwitcherModal() {
    setOrgSwitcherShowModal(true);
  }

  const switchCompanyItem =
    userOrgs?.length > 1
      ? {
          name: "Switch Company",
          onClick: handleShowOrgSwitcherModal,
          icon: CollectionIcon,
        }
      : null;

  const menuItems = [
    {
      name: "Settings",
      href: "/settings",
      icon: CogIcon,
    },
    switchCompanyItem,
    {
      name: "Log out",
      href: AUTH_ROUTES.LOGOUT,
      icon: LogoutIcon,
    },
  ].filter(Boolean);

  const noOrgsItems = menuItems.filter((item) => item.name === "Log out");

  return (
    <>
      <NotificationPanel extraClasses="z-10" />
      <BaseUserMenu
        userFirstName={userFirstName}
        userLastName={userLastName}
        unreadNotificationsCount={unreadNotificationsCount}
        menuItems={hasOrganizations ? menuItems : noOrgsItems}
      />
      {showOrgSwitcherModal && (
        <OrganizationSwitcherModal
          isOpen={showOrgSwitcherModal}
          onRequestClose={() => {
            setOrgSwitcherShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default UserMenu;
