import React from "react";

const HeaderContainer = (props) => {
  return (
    <header className={"bg-white py-2 px-2 shadow-md lg:py-7 lg:px-10"}>
      <div className={"mx-auto grid max-w-screen-2xl grid-cols-1 gap-y-8"}>
        <div className={"relative flex items-center justify-between"}>
          {props.children}
        </div>
      </div>
    </header>
  );
};

export default HeaderContainer;
