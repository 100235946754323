import { ExclamationIcon } from "@heroicons/react/solid";

const ErrorBanner = ({ message }) => {
  return (
    <div className="bg-lango-error-red border-lango-error-red-dark mb-4 border-l-8 p-4 transition duration-300 ease-in-out">
      <div className="flex">
        <div className="shrink-0">
          <ExclamationIcon
            className="text-lango-error-red-dark h-5 w-5"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm text-black">
            <span className={"font-bold"}>Uh oh!</span> {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorBanner;
