import { useGetUserList } from "@lango/common/components/userList/useGetUserList";
import { useModal, useVendorProfile } from "@lango/common/context";
import {
  AddButton,
  Flex,
  InviteClientAndVendorAdmin,
  Table,
} from "@lango/common/features";
import SettingsTabContainer from "@lango/common/features/containers/SettingsTabContainer";
import { USER_ROUTES } from "@lango/common/routes";

import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import { useInviteClientUser, useInviteOrgUser, useInviteVendorUser } from "@lango/common/hooks";
import { INVITATION_TYPES } from "@lango/common/constants/invitationTypes";

const ClientAndVendorAdminList = (props) => {
  const { organizationID, inviteType, canUpdateOrg = false, profileID } = props;
  const navigate = useNavigate();
  const { vendorProfile } = useVendorProfile();
  const UserTableProps = useGetUserList(organizationID, inviteType);
  const numUsers = UserTableProps?.data.length;

  const handleRowClick = ({ id }) => {
    if (inviteType !== INVITATION_TYPES.VENDOR) return;
    navigate(USER_ROUTES.LINGUIST(id, vendorProfile?.organizationID));
  };

  return (
    <SettingsTabContainer>
      {canUpdateOrg && <AddUser inviteType={inviteType} numUsers={numUsers} profileID={profileID} />}
      <Table
        columns={columns}
        defaultPageSize={10}
        {...UserTableProps}
        enablePagination
        onRowClick={handleRowClick}
      />
    </SettingsTabContainer>
  );
};

export default ClientAndVendorAdminList;

function AddVendorUser({ users, vendorProfileID }) {
  const { handleInviteVendorUser } = useInviteVendorUser(vendorProfileID);
  return (
    <AddUserButton users={users} onSubmit={handleInviteVendorUser} />
  );
}


function AddClientUser({ users, clientProfileID }) {
  const { handleInviteClientUser } = useInviteClientUser(clientProfileID);
  return (
    <AddUserButton users={users} onSubmit={handleInviteClientUser} />
  );
}

function AddOrgUser({ users }) {
  const { handleInviteOrgUser } = useInviteOrgUser();
  return (
    <AddUserButton users={users} onSubmit={handleInviteOrgUser} />
  );
}

export function AddUser({ inviteType, numUsers, profileID = null }) {
  const inviteComponents = {
    [INVITATION_TYPES.VENDOR]:  <AddVendorUser users={numUsers} vendorProfileID={profileID} />,
    [INVITATION_TYPES.CLIENT]: <AddClientUser users={numUsers} clientProfileID={profileID} />,
    [INVITATION_TYPES.ORGANIZATION]: <AddOrgUser users={numUsers} />,
  }

  return inviteComponents[inviteType] || null;
}

// todo: remove this when add invite user feature in client portal settings tab
export function AddUserButton({ users, onSubmit }) {
  const { showModal } = useModal();

  const handleAddUser = () => {
    showModal(InviteClientAndVendorAdmin, {
      heading: "Add new user",
      users,
      onSubmit,
    });
  };

  return (
    <Flex justify="end">
      <AddButton
        text="Add new user"
        onClick={handleAddUser}
        extraClasses="mb-4"
        type="button"
      />
    </Flex>
  );
}


