import { useFormikContext } from "formik";

import { Box } from "@lango/common/components/Box";
import FormControl from "@lango/common/features/forms/components/FormControl";
import Flex from "@lango/common/features/tables/components/Flex";

import { renderPasswordRequirements } from "../authUtils";

const FormFields = ({ isValidUsername }) => {
  const { isSubmitting, dirty } = useFormikContext();

  return (
    <Box spaceY={8}>
      <FormControl
        label="Email"
        type="email"
        name="username"
        control="input"
        placeholder="Enter email"
        disabled={isValidUsername}
      />

      <FormControl
        label="Confirmation Code"
        type="text"
        name="code"
        placeholder="confirmation code"
        control="input"
        infoText="Please check your email for this code."
        disabled={isSubmitting}
        whiteBackground
      />

      {renderPasswordRequirements}

      <FormControl
        label="New Password"
        type="password"
        name="newPassword"
        control="input"
        autoComplete="new-password"
        disabled={isSubmitting}
        whiteBackground
      />

      <FormControl
        label="Repeat New Password"
        type="password"
        name="repeatNewPassword"
        control="input"
        autoComplete="new-password"
        disabled={isSubmitting}
        whiteBackground
      />

      <Flex justify="center">
        <FormControl
          control="button"
          type="submit"
          disabled={isSubmitting || !dirty}
          text="Send"
          showLoader={isSubmitting}
        />
      </Flex>
    </Box>
  );
};

export default FormFields;
