import { PopoverButton } from "@headlessui/react";
import { MdNotificationsNone } from "react-icons/md";

import { UnreadIcon } from "@lango/common/components/UnreadIcon";

/**
 * NotificationHeader Component
 *
 * Renders a notification bell icon that indicates the presence of unread notifications.
 *
 * @param {Object} props - The props for the NotificationHeader component.
 * @param {boolean} props.hasUnreadNotifications - A boolean indicating if there are any unread
 * @returns {React.ReactNode} The rendered NotificationHeader component.
 */
export const NotificationHeader = ({ hasUnreadNotifications }) => {
  return (
    <PopoverButton className="items-center rounded-full px-1 py-1 !border-gray-200 border bg-white outline-none">
      <MdNotificationsNone size="28px" />
      {hasUnreadNotifications && <UnreadIcon />}
    </PopoverButton>
  );
};
