import { Logout } from "@lango/common/features/auth/components/Logout";
import ConfirmForgotPasswordForm from "@lango/common/features/auth/containers/ConfirmForgotPasswordForm";
import ForgotPasswordForm from "@lango/common/features/auth/containers/ForgotPasswordForm";
import Login from "@lango/common/features/auth/containers/Login";
import RedirectOrgLogin from "@lango/common/features/auth/containers/redirectOrgLogin";

import {
  authPageLoader,
  logoutPageLoader,
  protectedPageLoader,
} from "./authLoaders";
import { AUTH_ROUTES } from "./consts";

export const authRoutes = [
  {
    path: AUTH_ROUTES.LOGOUT,
    element: <Logout />,
    loader: logoutPageLoader,
  },
  {
    path: AUTH_ROUTES.COGNITO_LOGOUT,
    element: <Logout />,
    loader: logoutPageLoader,
  },
  {
    path: AUTH_ROUTES.LOGIN,
    element: <Login />,
    loader: authPageLoader,
  },
  {
    path: AUTH_ROUTES.COGNITO_LOGIN,
    element: <Login />,
  },
  {
    path: AUTH_ROUTES.FORGOT_PASSWORD,
    element: <ForgotPasswordForm />,
    loader: authPageLoader,
  },
  {
    path: AUTH_ROUTES.CONFIRM_FORGOT_PASSWORD,
    element: <ConfirmForgotPasswordForm />,
    loader: authPageLoader,
  },
  {
    path: AUTH_ROUTES.ORG_LOGIN,
    element: <RedirectOrgLogin />,
  },
];
