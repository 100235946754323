import { useMutation, useReactiveVar } from "@apollo/client";

import { activeOrgVar } from "@lango/common/apollo/localState";
import { toastError } from "@lango/common/features";
import { UPDATE_CC_PERMISSIONS } from "@lango/common/queries";

const useUpdateCCPermissions = () => {
  const { id: vendorID } = useReactiveVar(activeOrgVar);
  const [updateCCPermissions, { loading }] = useMutation(UPDATE_CC_PERMISSIONS);

  const handleUpdateCCPermissions = async () => {
    try {
      await updateCCPermissions({ variables: { vendorID } });
    } catch (error) {
      toastError(error?.message || "Error checking permissions");
      console.error(error);
    }
  };

  return { handleUpdateCCPermissions, loading };
};

export default useUpdateCCPermissions;
