/**
 * Invitation types
 * Used to indicate the type of invitation a pending invitation is
 * Primarily used to indicate if a PM invitation is an organization invite or an agency invite
 */

export const INVITATION_TYPES = {
  CLIENT: "client",
  VENDOR: "vendor",
  AGENCY: "agency",
  ORGANIZATION: "organization",
};

export function isOrganizationInvitation(invitationType) {
  return invitationType === INVITATION_TYPES.ORGANIZATION;
}