import React from "react";

import Tab, { primaryTabStyles } from "@lango/common/features/tab";
import Table from "@lango/common/features/tables/components/Table";
import { useFetchVendorHTJobTasks } from "@lango/common/hooks";

import { columns } from "./columns";

// todo: de-duplicate from the buyer version
const TaskDetails = ({ jobID }) => {
  const htTasksData = useFetchVendorHTJobTasks(jobID);

  return (
    <Tab styles={primaryTabStyles}>
      <Tab.Panel name="Source Files">
        <Table columns={columns} {...htTasksData} enablePagination />
      </Tab.Panel>
    </Tab>
  );
};

export default TaskDetails;
