import { useQuery } from "@apollo/client";

import { ALL_ROLES } from "@lango/common/queries";

export const useFetchAllRoles = () => {
  const { loading, data, error } = useQuery(ALL_ROLES);

  return {
    loading,
    error,
    allRoles: data?.allRoles,
  };
};
