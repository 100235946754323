import { gql } from "@apollo/client";

export const SSO_USER_CHECK = gql`
  query SSOUserCheck(
    $emailDomain: String!
    $applicationName: String!
    $organizationID: ID
  ) {
    ssoCheck(
      emailDomain: $emailDomain
      applicationName: $applicationName
      organizationID: $organizationID
    ) {
      ssoUser
      ssoProviderName
      ssoProviderOrganizationID
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const CONFIRM_FORGOT_PASSWORD = gql`
  mutation confirmForgotPassword(
    $email: String!
    $code: String!
    $newPassword: String!
  ) {
    confirmForgotPassword(email: $email, code: $code, newPassword: $newPassword)
  }
`;

export const VERIFY_VENDOR_INVITATION = gql`
  query VerifyVendorInvitation($code: String!, $sourceAppName: String!) {
    verifyVendorInvitation(code: $code, sourceAppName: $sourceAppName) {
      firstName
      lastName
      email
      nextStep
      acceptInviteStep
      defaultOrganization
      code
      organizations {
        id
        name
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation createUser($input: NewUser!) {
    createUser(input: $input)
  }
`;

export const VERIFY_CLIENT_INVITATION = gql`
  query VerifyClientInvitation($code: String!) {
    verifyClientInvitation(code: $code) {
      firstName
      lastName
      email
      nextStep
      acceptInviteStep
      defaultOrganization
      ssoProvider
      code
      organizations {
        id
        name
      }
    }
  }
`;

export const VERIFY_ORG_INVITATION = gql`
  query VerifyOrgInvitation($code: String!) {
    verifyOrgInvitation(code: $code) {
      firstName
      lastName
      email
      nextStep
      code
    }
  }
`;
