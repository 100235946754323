import { filterTypes } from "@lango/common/request";
import { functionalUpdate } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * We want to store complex state in the url with as few characters as possible to avoid cluttering.
 */
const shortUrlIdentifiers = {
  seperator: "_",
  commonFilters: "f",
  customFilters: "cf",
  filterKey: "k",
  filterValue: "v",
  filterLike: "l",
  sorting: "s",
  sortingId: "id",
  sortingDescending: "desc",
  paginationIndex: "pi",
  paginationSize: "ps",
  pagination: "p",
  truthy: "1",
  falsey: "0",
};

const serializeFilter = (params, filterType, filterStateItem, index) => {
  params.append(
    filterType,
    [index, shortUrlIdentifiers.filterKey, filterStateItem.key].join(
      shortUrlIdentifiers.seperator,
    ),
  );
  filterStateItem?.values?.forEach((filterStateItemValue, valueIndex) => {
    params.append(
      filterType,
      [
        index,
        shortUrlIdentifiers.filterValue,
        valueIndex,
        filterStateItemValue,
      ].join(shortUrlIdentifiers.seperator),
    );
  });
  if (filterStateItem.like) {
    params.append(
      filterType,
      [index, shortUrlIdentifiers.filterLike, shortUrlIdentifiers.truthy].join(
        shortUrlIdentifiers.seperator,
      ),
    );
  }
};

/**
This function serializes tanstack's filter state into URL parameters.
For state shape, see: https://tanstack.com/table/v8/docs/api/features/filters#state
@param {URLSearchParams} params - The URL parameters to modify.
@param {Object} filterState - The state to serialize into URL.
@returns {URLSearchParams} The modified URL parameters.
*/
const serializeFilters = (params, filterState, distinguisher = "") => {
  const common = distinguisher + shortUrlIdentifiers.commonFilters;
  const custom = distinguisher + shortUrlIdentifiers.customFilters;

  // Clear any existing filtering before setting the new filtering.
  params.delete(common);
  params.delete(custom);

  if (
    !filterState ||
    (!filterState[filterTypes.common] && !filterState[filterTypes.custom])
  ) {
    return params;
  }

  // Don't serialize empty filters.
  const clean = (array) => {
    return array
      .map((f) => (!f.values.filter(Boolean).length ? undefined : f))
      .filter(Boolean);
  };

  clean(filterState[filterTypes.common]).forEach((filterStateItem, index) => {
    serializeFilter(params, common, filterStateItem, index);
  });
  clean(filterState[filterTypes.custom]).forEach((filterStateItem, index) => {
    serializeFilter(params, custom, filterStateItem, index);
  });

  return params;
};

/**
This function parses tanstack's filter state from URL parameters.
For state shape, see: https://tanstack.com/table/v8/docs/api/features/filters#state
@param {URLSearchParams} params - The URL parameters to read from.
@param {Object} old - The current state to modify
@returns {Object} The modified state.
*/
const parseFilters = (params, old, distinguisher = "") => {
  const newFilters = {
    [filterTypes.common]: [],
    [filterTypes.custom]: [],
  };

  const commonFilterParams = params.getAll(
    distinguisher + shortUrlIdentifiers.commonFilters,
  );
  const customFilterParams = params.getAll(
    distinguisher + shortUrlIdentifiers.customFilters,
  );

  if (!commonFilterParams && !customFilterParams) {
    return old;
  }

  const parseIndividualParam = (param, filterType) => {
    const [index, key, value, valueItem] = param.split(
      shortUrlIdentifiers.seperator,
    );
    if (!newFilters[filterType][index]) {
      newFilters[filterType][index] = {};
    }
    if (key === shortUrlIdentifiers.filterKey) {
      newFilters[filterType][index].key = value;
    }
    if (key === shortUrlIdentifiers.filterValue) {
      if (!newFilters[filterType][index].values) {
        newFilters[filterType][index].values = [];
      }
      newFilters[filterType][index].values[value] = valueItem;
    }
    if (key === shortUrlIdentifiers.filterLike) {
      newFilters[filterType][index].like = value === shortUrlIdentifiers.truthy;
    }
  };

  commonFilterParams.forEach((param) =>
    parseIndividualParam(param, filterTypes.common),
  );
  customFilterParams.forEach((param) =>
    parseIndividualParam(param, filterTypes.custom),
  );
  return newFilters;
};

/**
This function serializes tanstack's sorting state into URL parameters.
For state shape, see: https://tanstack.com/table/v8/docs/api/features/sorting#state
@param {URLSearchParams} params - The URL parameters to modify.
@param {Array} sortingState - The state to serialize into URL.
@returns {URLSearchParams} The modified URL parameters.
*/
const serializeSorting = (params, sortingState, distinguisher = "") => {
  if (!sortingState) {
    return params;
  }
  // Clear any existing sorting before setting the new sorting.
  params.delete(distinguisher + shortUrlIdentifiers.sorting);
  sortingState.forEach((sortingStateItem, index) => {
    params.append(
      distinguisher + shortUrlIdentifiers.sorting,
      [index, shortUrlIdentifiers.sortingId, sortingStateItem.id].join(
        shortUrlIdentifiers.seperator,
      ),
    );
    params.append(
      distinguisher + shortUrlIdentifiers.sorting,
      [
        index,
        shortUrlIdentifiers.sortingDescending,
        sortingStateItem.desc
          ? shortUrlIdentifiers.truthy
          : shortUrlIdentifiers.falsey,
      ].join(shortUrlIdentifiers.seperator),
    );
  });
  return params;
};

/**
This function parses tanstack's sorting state from URL parameters.
For state shape, see: https://tanstack.com/table/v8/docs/api/features/sorting#state
@param {URLSearchParams} params - The URL parameters to read from.
@param {Array} old - The current state to modify
@returns {Array} The modified state.
*/
const parseSorting = (params, old, distinguisher = "") => {
  const sortingParams = params.getAll(
    distinguisher + shortUrlIdentifiers.sorting,
  );
  if (!sortingParams) {
    return old;
  }
  const sorts = [];
  sortingParams.forEach((param) => {
    const [index, key, value] = param.split(shortUrlIdentifiers.seperator);
    if (!sorts[index]) {
      sorts[index] = {};
    }
    if (key === shortUrlIdentifiers.sortingDescending) {
      sorts[index][key] = value === shortUrlIdentifiers.truthy;
    } else {
      sorts[index][key] = value;
    }
  });
  return sorts;
};

/**
This function serializes tanstack's pagination state into URL parameters.
For state shape, see: https://tanstack.com/table/v8/docs/api/features/pagination#state
@param {URLSearchParams} params - The URL parameters to modify.
@param {Object} paginationState - The state to serialize into URL.
@returns {URLSearchParams} The modified URL parameters.
*/
const serializePagination = (params, paginationState, distinguisher = "") => {
  params.set(
    distinguisher + shortUrlIdentifiers.paginationIndex,
    paginationState.pageIndex,
  );
  params.set(
    distinguisher + shortUrlIdentifiers.paginationSize,
    paginationState.pageSize,
  );
  return params;
};

/**
This function parses tanstack's pagination state from URL parameters.
For state shape, see: https://tanstack.com/table/v8/docs/api/features/pagination#state
@param {URLSearchParams} params - The URL parameters to read from.
@param {Object} old - The current state to modify
@param {Number} defaultPageSize - The default page size as an int.
@returns {Object} The modified state.
*/
const parsePagination = (params, old, defaultPageSize, distinguisher = "") => {
  const pageIndex = Number(
    params.get(distinguisher + shortUrlIdentifiers.paginationIndex),
  );
  const isUpdatedPageIndex = pageIndex !== null && pageIndex !== old.pageIndex;

  const pageSize =
    Number(params.get(distinguisher + shortUrlIdentifiers.paginationSize)) ||
    defaultPageSize;
  const isUpdatedPageSize = pageSize !== null && pageSize !== old.pageSize;

  if (isUpdatedPageIndex || isUpdatedPageSize) {
    return {
      pageIndex: isUpdatedPageIndex ? pageIndex : old.pageIndex,
      pageSize: isUpdatedPageSize ? pageSize : old.pageSize,
    };
  }
};

/**
This hook acts like a middleware for Tanstack Table's state management.
When the state changes, we serialize the state and store it in the URL search params.
Then we react to the search params changing by parsing the data from params,
and pushing it back into React State, which can then be passed back to the table.
By adding this url step, we gain the benefit of having the table state stored in the route,
to ensure users can keep their place when navigating / refreshing, etc.
@param {Object} params - The initial state of the table.
@param {Object} params.initialFilters - The initial filter state of the table. For shape, see: https://tanstack.com/table/v8/docs/api/features/filters#state
@param {Object} params.initialSorts - The initial filter state of the table. For shape, see: https://tanstack.com/table/v8/docs/api/features/sorting#state
@param {Object} params.initialPagination - The initial filter state of the table. For shape, see: https://tanstack.com/table/v8/docs/api/features/pagination#state
@param {String} params.distinguisher - A string to distinguish between multiple tables on the same page.
@returns {Object} state objects and setters to pass to the form. These can be used to replace Tanstack's internal state management with our URL handling.
*/
export const useTableRouting = ({
  initialFilters,
  initialSorts,
  initialPagination,
  distinguisher = "",
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Create state stores. We won't be setting these directly. Only through the URL.
  const [filters, setFilters] = useState(initialFilters);
  const [sorting, setSorting] = useState(initialSorts);
  const [pagination, setPagination] = useState(initialPagination);
  const [loading, setLoading] = useState(true);

  // Set the states to match the initial values provided.
  useEffect(() => {
    setFilters(initialFilters);
  }, [JSON.stringify(initialFilters)]);

  useEffect(() => {
    setSorting(initialSorts);
  }, [JSON.stringify(initialSorts)]);

  useEffect(() => {
    setPagination(initialPagination);
  }, [JSON.stringify(initialPagination)]);

  // Step 1: When we pass these setters to the TanStack Table,
  // the new state will be applied to the search params instead of directly to React State Store.
  const setSortingByRoute = (updater) => {
    const newValue = functionalUpdate(updater, sorting);
    setSearchParams((cur) => serializeSorting(cur, newValue, distinguisher));
  };
  const setPaginationByRoute = (updater) => {
    const newValue = functionalUpdate(updater, pagination);
    setSearchParams((cur) => serializePagination(cur, newValue, distinguisher));
  };
  const setFiltersByRoute = (updater) => {
    const newValue = functionalUpdate(updater, filters);
    setSearchParams((cur) => serializeFilters(cur, newValue, distinguisher));
  };

  // Step 2: When we recognize new search params, then we can update the React State Store.
  useEffect(() => {
    const newSorting = parseSorting(searchParams, sorting, distinguisher);
    if (newSorting) {
      setSorting(newSorting);
    }
    const newPagination = parsePagination(
      searchParams,
      pagination,
      initialPagination.pageSize,
      distinguisher,
    );
    if (newPagination) {
      setPagination(newPagination);
    }
    const newFilters = parseFilters(searchParams, filters, distinguisher);
    if (newFilters) {
      setFilters(newFilters);
    }
    setLoading(false);
  }, [searchParams]);

  // Return state and new custom setters for pagination, sorting, and filtering.
  // Any of the three can be used without the others.
  return {
    sorting,
    setSorting: setSortingByRoute,
    pagination,
    setPagination: setPaginationByRoute,
    filters,
    setFilters: setFiltersByRoute,
    loading,
  };
};
