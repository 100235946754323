import { prepareCustomState } from "@lango/common/helpers";

import { FEDERATED_LOGIN, ORG_FEDERATED_LOGIN } from "../utils";

export const USER_SSO_ENABLED_ERROR =
  "User's email domain does not belong to an SSO-enabled organization";
export const ORG_LOGIN = "OrgLogin";
export const customState = (orgID) => {
  const custState = orgID
    ? prepareCustomState(ORG_FEDERATED_LOGIN, "orgID", orgID)
    : prepareCustomState(FEDERATED_LOGIN);
  return custState;
};
