import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

import classNames from "@lango/common/classnames";
import DropdownTriangle from "@lango/common/features/forms/components/DropdownTriangle";

import DropdownButton from "./Button";
import DropdownMenu from "./Menu";
import { dropdownButtonClasses, dropdownMenuClasses, styles } from "./styles";

const Dropdown = ({
  children,
  className = "",
  showTriangleIcon = true,
  position = "relative", // if dropdown is used inside table, then position will be static
  buttonClassName = "",
  title = "",
}) => {
  const dropdownButton = React.Children.map(
    children,
    (child) => child.type === Dropdown.Button && child.props.children,
  );

  const dropdownMenu = (close) =>
    React.Children.map(
      children,
      (child) =>
        child.type === Dropdown.Menu && (
          <div
            className={classNames(
              "overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 bg-white",
              child.props.className,
            )}
            onClick={close} // Attach the close function to the click event
          >
            {child.props.children}
          </div>
        ),
    );

  return (
    <Popover className={position}>
      <Popover.Button
        className={classNames(dropdownButtonClasses, buttonClassName)}
        title={title}
      >
        {dropdownButton}
      </Popover.Button>
      <Transition as={Fragment} {...styles}>
        <Popover.Panel className={classNames(dropdownMenuClasses, className)}>
          {({ close }) => (
            <>
              {showTriangleIcon && <DropdownTriangle position={"right"} />}
              {dropdownMenu(close)}
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

Dropdown.Button = (props) => <DropdownButton {...props} />;
Dropdown.Menu = (props) => <DropdownMenu {...props} />;

export default Dropdown;
