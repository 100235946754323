import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import environmentVars from "@lango/common/env";
import { toastError, toastSuccess, toastWarning } from "@lango/common/features";
import { convertBytes } from "@lango/common/helpers";
import { FIND_CURRENT_USER, USER_PROFILE_PICTURE } from "@lango/common/queries";

export const useUploadUserPicture = () => {
  const [uploadUserPicture, { loading }] = useMutation(
    USER_PROFILE_PICTURE.UPLOAD,
    {
      refetchQueries: [FIND_CURRENT_USER],
    },
  );

  const validateFile = (file) => {
    if (!file) {
      toastWarning("No file has been selected");
      return false;
    }

    switch (file.type) {
      case "image/jpeg":
      case "image/png":
        break;
      default:
        toastWarning("Only JPEG and PNG files are allowed");
        return false;
    }

    if (file.size > environmentVars.avatarFileSizeLimit) {
      toastWarning(
        "File size cannot be larger than " +
          convertBytes(environmentVars.avatarFileSizeLimit),
      );
      return false;
    }

    return true;
  };

  const handleUploadPicture = useCallback(
    async (event) => {
      event.preventDefault();
      const file = event?.target?.files[0];

      if (!validateFile(file)) return;

      try {
        await uploadUserPicture({ variables: { file: file } });
        toastSuccess("Picture uploaded successfully");
      } catch (error) {
        console.error(error);
        toastError("There was an error uploading the picture");
      }
    },
    [uploadUserPicture],
  );

  return { handleUploadPicture, loading };
};
