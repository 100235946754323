import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import {
  LINGUIST_USER,
  LINGUIST_USER_NOTIFICATION_SETTINGS,
} from "@lango/common/queries";

import { prepareUserTimezone } from "../features/userSettings/userSettingForm/timezones";

export const useFetchLinguistUser = () => {
  const { loading, data, error } = useQuery(LINGUIST_USER, {
    fetchPolicy: "cache-and-network",
  });

  const user = useMemo(() => prepareUserTimezone(data), [data]);

  return {
    loading,
    error,
    currentUser: user,
  };
};

export const useFetchLinguistUserNotificationSettings = () => {
  const { loading, data, error } = useQuery(
    LINGUIST_USER_NOTIFICATION_SETTINGS,
    {
      fetchPolicy: "cache-and-network",
    },
  );

  return {
    loading,
    error,
    data: data?.findCurrentUser,
  };
};
