import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { useModal } from "@lango/common/context";
import {
  toastError,
  toastSuccess,
} from "@lango/common/features/alerts/functions/toast";
import {
  ALL_VENDOR_PROFILE_LANGUAGE,
  CREATE_VENDOR_PROFILE_LANGUAGE,
} from "@lango/common/queries";

import { createVendorProfileLanguages } from "../request";
import environmentVars from "../env";

const appName = environmentVars.appName;


export const useCreateVendorProfileLanguage = ({ organizationID, lspID }) => {
  const { hideModal } = useModal();

  const [createVendorProfileLanguage] = useMutation(
    CREATE_VENDOR_PROFILE_LANGUAGE,
    {
      refetchQueries: [ALL_VENDOR_PROFILE_LANGUAGE],
    },
  );

  const handleCreateVendorLanguagePair = useCallback(
    async (props) => {
      try {
        const request = createVendorProfileLanguages({
          organizationID,
          lspID,
          appName,
          ...props,
        });
        await createVendorProfileLanguage(request);
        toastSuccess("Vendor language pair has been created");
        hideModal();
      } catch (error) {
        toastError(error?.message || "Error creating vendor language pair");
        console.error(error);
      }
    },
    [organizationID, lspID, hideModal, createVendorProfileLanguage],
  );

  return { handleCreateVendorLanguagePair };
};
