import PropTypes from "prop-types";
import React from "react";

import classNames from "@lango/common/classnames";
import { spaceXClassesMap, spaceYClassesMap, spacer } from "@lango/common/css";
import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";

/**
 * Box component that provides spacing and optional heading for its children.
 *
 * @param {Object} props - The props for the ModalProvider component.
 * @param {string} [props.heading] - The heading text for the box.
 * @param {string} [props.description] - The description text for the box.
 * @param {React.ReactNode} [props.children] - The child components.
 * @param {string} [props.extraClasses] - Additional CSS classes to apply to the box.
 * @param {number} [props.spaceX] - The horizontal spacing of the box. Can be one of the values from the spaces array.
 * @param {number} [props.spaceY] - The vertical spacing of the box. Can be one of the values from the spacer array.
 * @param {boolean} [props.required] - Whether to show the required label.
 * @returns {React.ReactNode} The rendered Box component.
 */
export const Box = ({
  heading,
  description,
  children,
  extraClasses,
  spaceX,
  spaceY,
  required = false,
}) => {
  const className = classNames(
    spaceXClassesMap[spaceX],
    spaceYClassesMap[spaceY],
    extraClasses,
  );
  return (
    <div className={className}>
      {heading && (
        <BasePageHeading
          heading={heading}
          description={description}
          required={required}
        />
      )}
      {children}
    </div>
  );
};

Box.propTypes = {
  spaceX: PropTypes.oneOf(spacer),
  spaceY: PropTypes.oneOf(spacer),
  extraClasses: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
};
