import { createHttpLink, from, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { fetchAuthSession } from "aws-amplify/auth";
import { HttpStatusCode } from "axios";

import { toastWarning } from "@lango/common/features/alerts/functions/toast";

import { AUTH_ROUTES } from "../routes";

// graphQL endpoints
const protectedLink = createUploadLink({
  uri: (operation) =>
    `${process.env.REACT_APP_GRAPHQL_URL}?${operation.operationName}`,
});
const publicLink = createHttpLink({
  uri: (operation) =>
    `${process.env.REACT_APP_GRAPHQL_PUBLIC_URL}?${operation.operationName}`,
});

const handleLogout = () => {
  toastWarning("You have been signed out", {
    toastId: "logout",
    triggerAtURL: AUTH_ROUTES.LOGIN,
    autoClose: false,
  });
  // We don't have router here, so doing a hard redirect.
  if (window.location.pathname !== "/logout") {
    window.location.replace("/logout");
  }
};

// error callbacks lookup
const errors = {
  // auth session expired
  SESSION_EXPIRED: () => {
    console.error("session expired");
    handleLogout();
  },

  // fallback
  DEFAULT: (operation, graphQLErrors, networkError) => {
    const errType = networkError ? "network" : "graphQL";
    const errTitle = errType + " error in operation " + operation.operationName;
    if (networkError) {
      console.error({ title: errTitle, error: networkError });
      if (networkError?.response?.status === HttpStatusCode.Unauthorized) {
        handleLogout();
      }
      return;
    }
    console.error({ title: errTitle, errors: graphQLErrors });
  },
};

// handle errors
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  try {
    errors[networkError]();
  } catch {
    errors.DEFAULT(operation, graphQLErrors, networkError);
  }
});

// check auth via amplify
const authLink = setContext(
  (_, { headers }) =>
    new Promise((resolve, reject) => {
      fetchAuthSession()
        .then(({ tokens }) => {
          const token = tokens?.idToken;
          token
            ? resolve({
                ...headers,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
            : reject("SESSION_EXPIRED");
        })
        .catch(() => reject("SESSION_EXPIRED"));
    }),
);

const protectedChain = from([errorLink, authLink, protectedLink]);

export const link = split(
  (operation) => operation.getContext().clientName === "public",
  publicLink,
  protectedChain,
);
