// Contains type policies corresponding to the protected/domains/project.graphql schema
// Reference: https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields

import { idField } from "./consts";

export const projectPolicies = {
  Project: {
    keyFields: false,
  },
  ProjectLabel: {
    keyFields: false,
  },
  Label: {
    keyFields: idField,
  },
  ProjectType: {
    keyFields: idField,
  },
  ProjectStatus: {
    keyFields: idField,
  },
  ProjectRole: {
    keyFields: idField,
  },
  UserAndProjectRole: {
    keyFields: false,
  },
  VendorAndRate: {
    keyFields: false,
  },
  ProjectContact: {
    keyFields: false,
  },
  // Job: {
  //   keyFields: false,
  // },
  FileStorageProvider: {
    keyFields: idField,
  },
  JobType: {
    keyFields: idField,
  },
  JobStatus: {
    keyFields: idField,
  },
  JobLabel: {
    keyFields: idField,
  },
  Task: {
    keyFields: false,
  },
  TaskType: {
    keyFields: idField,
  },
  TaskStatus: {
    keyFields: idField,
  },
  TaskState: {
    keyFields: false,
  },
  TaskFile: {
    keyFields: false,
  },
  PaginatedProjectJobs: {
    keyFields: false,
  },
  PaginatedJobTasks: {
    keyFields: false,
  },
  TranslatedFile: {
    keyFields: false,
  },
  OrgWordCountAndLimit: {
    keyFields: false,
  },
  Glossary: {
    keyFields: idField,
  },
  JobGlossary: {
    keyFields: idField,
  },
  OrganizationGlossary: {
    keyFields: false,
  },
  ExportProjectJobsResponse: {
    keyFields: false,
  },
  ExportJobTasksResponse: {
    keyFields: false,
  },
  File: {
    keyFields: false,
  },
  PaginatedFileResp: {
    keyFields: false,
  },
  FileType: {
    keyFields: idField,
  },
  RateUnit: {
    keyFields: idField,
  },
  RateType: {
    keyFields: idField,
  },
  PaginatedOrgProjects: {
    keyFields: false,
  },
  FailureReason: {
    keyFields: idField,
  },
};
