import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { PM_APP, VENDOR_APP } from "@lango/common/constants";
import environmentVars from "@lango/common/env";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import {
  ORGANIZATION_NOTIFICATION,
} from "@lango/common/queries";

const appName = environmentVars.appName;

export const useReadOrgNotification = () => {
  const navigate = useNavigate();
  const [readNotification] = useMutation(ORGANIZATION_NOTIFICATION.READ, {
    refetchQueries: [ORGANIZATION_NOTIFICATION.PAGINATED],
  });

  const handleReadNotification = useCallback(
    async (notificationID, orgID) => {
      try {
        await readNotification({ variables: { notificationID } });
        if (appName === PM_APP) {
          {orgID && navigate("/vendors/qualification-review/" + orgID);}
        } else if (appName === VENDOR_APP) {
          navigate("/settings/vendor-language-pairs");
        } else {
          navigate("/");
        }
      } catch (error) {
        toastError("There was an error reading notifications");
        console.error(error);
      }
    },
    [readNotification],
  );

  return { handleReadNotification };
};
