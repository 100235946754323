import { Divider } from "@aws-amplify/ui-react";

import { Box } from "@lango/common/components";

import TaxForm from "./taxForm";
import { renderFieldGroup } from "../../onboarding/step1/fields";
import { address_fields,  billing_contact_fields } from "../../onboarding/step2/fields";

export default function Fields() {
  return (
    <Box extraClasses="bg-white rounded-xl shadow-md my-8">
      {renderFieldGroup("Billing Contact", billing_contact_fields)}
      <Divider />
      {renderFieldGroup("Address", address_fields)}
      <Divider />
      <TaxForm />
    </Box>
  );
}
