import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

import { activeOrgVar } from "@lango/common/apollo/localState";
import { PAGINATED_VENDOR_JOBS } from "@lango/common/queries";
import environmentVars from '@lango/common/env';

export const useFetchPaginatedVendorJobs = () => {
  const activeOrg = useReactiveVar(activeOrgVar);

  const [paginatedVendorUserJobs, { data, loading, error }] = useLazyQuery(
    PAGINATED_VENDOR_JOBS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    },
  );

  const fetchPaginatedVendorJobs = useCallback(
    (props) => {
      const request = prepareRequest({ ...props, activeOrg });
      paginatedVendorUserJobs(request);
    },
    [paginatedVendorUserJobs, activeOrg],
  );

  const result = data?.paginatedJobs;

  return {
    fetchPaginatedVendorJobs,
    loading,
    error,
    jobs: result?.jobs ?? [],
    totalJobs: result?.totalRecords,
  };
};

const prepareSortBy = (sortBy) =>
  sortBy?.length > 0
    ? { sortBy: sortBy[0]?.id, sortDir: sortBy[0]?.desc ? "DESC" : "ASC" }
    : { sortBy: "jobs.id", sortDir: "DESC" };

export const prepareRequest = (props) => {
  const { pageIndex, pageSize, sortBy, filters, activeOrg } = props;
  return {
    variables: {
      input: {
        vendorID: activeOrg?.id,
        pagination: { offset: pageIndex * pageSize, pageLimit: pageSize },
        filtersReq: filters,
        projectIDs: [],
        sourceAppName: environmentVars.appName,
        ...prepareSortBy(sortBy),
      },
    },
  };
};
