import StartBar from "./startBar";
import EndBar from "./endBar";
import { useAcceptVendorInviteCtx } from "@lango/common/context";

const InfoBars = ({ status, step2, step3, colorOne, colorTwo }) => {
  const { handleChangeInviteStep } = useAcceptVendorInviteCtx();

  const handleClickStep1 = () => {
    handleChangeInviteStep("step1");
  };

  const handleClickStep2 = () => {
    handleChangeInviteStep("step2");
  };

  const handleClickStep3 = () => {
    handleChangeInviteStep("step3");
  };
  const renderStartBars = () => {
    switch (status) {
      case "started":
        return (
          <>
            <StartBar
              color="black"
              text="Basic Info"
              zIndex={1}
              textColor="white"
              width={500}
            />
            <StartBar
              color="white"
              text={step2}
              zIndex={0}
              textColor="black"
              width={650}
            />
          </>
        );
      case "step1":
        return (
          <>
            <button
              onClick={handleClickStep1}
              style={{ zIndex: "1" }}
              type="button"
            >
              <StartBar
                color={"black"}
                text="Basic Info"
                zIndex={1}
                textColor={"white"}
                width={500}
              />
            </button>
            <button
              onClick={handleClickStep2}
              style={{ zIndex: "0" }}
              type="button"
            >
              <StartBar
                color={colorOne}
                text={step2}
                zIndex={0}
                textColor={colorOne === "white" ? "black" : "white"}
                width={650}
              />
            </button>
          </>
        );
      case "step2":
        return (
          <>
            <button
              onClick={handleClickStep1}
              style={{ zIndex: "1" }}
              type="button"
            >
              <StartBar
                color="black"
                text="Basic Info"
                zIndex={1}
                textColor="white"
                width={500}
              />
            </button>
            <button
              onClick={handleClickStep2}
              style={{ zIndex: "0" }}
              type="button"
            >
              <StartBar
                color={colorOne}
                text={step2}
                zIndex={0}
                textColor={colorOne === "white" ? "black" : "white"}
                width={650}
              />
            </button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="grid grid-cols-3 w-4/6 gap-12 mt-5">
      {status === "step2" ? (
        <>
          {renderStartBars()}
          <button onClick={handleClickStep3} type="button">
            <EndBar
              color={colorTwo}
              text={step3}
              textColor={colorTwo === "white" ? "black" : "white"}
              width={825}
            />
          </button>
        </>
      ) : (
        <>
          {renderStartBars()}
          <EndBar color="white" text={step3} textColor="black" width={825} />
        </>
      )}
    </div>
  );
};

export default InfoBars;
