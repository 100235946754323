import React from "react";
import { Navigate } from "react-router-dom";

import { useIsAuthenticated } from "@lango/common/features/auth/hooks";

const withRedirectIfAuthenticated = (WrappedComponent) => {
  const RedirectIfAuthenticated = (props) => {
    const { isAuthenticated } = useIsAuthenticated();

    return isAuthenticated ? (
      <Navigate replace to="/" />
    ) : (
      <WrappedComponent {...props} />
    );
  };

  return RedirectIfAuthenticated;
};

export default withRedirectIfAuthenticated;
