/**
 * JobFilterFields is a React component that provides the form fields for the JobFilterModal.
 *
 * It uses Formik for form handling and gets the resetForm and dirty functions from the Formik context.
 * It also uses the useFetchTaskFilters custom hook to fetch the task statuses and types.
 *
 * The component renders a form with fields for the task type, status, source language, and target language.
 * It also provides a clear button that resets the form and hides the modal, and a search button that submits the form.
 *
 * The form fields are wrapped in a WithLoader component, which displays a loading state while the task statuses and types are being fetched.
 * If an error occurs during fetching, it displays an error message.
 *
 * The clear and search buttons are disabled while the task statuses and types are being fetched or if the form has not been modified.
 */

import FormControl from "@lango/common/features/forms/components/FormControl";
import { filterOptions } from "@lango/common/helpers/filter";
import { useFetchJobFilters } from "@lango/common/hooks/useFetchJobFilters";
import { filterKeys } from "@lango/common/request";
import { Form, useFormikContext } from "formik";

const JobFilterFields = ({ setShowModal, setFilters }) => {
  const { resetForm, dirty, initialValues } = useFormikContext();
  const { jobStatuses, jobTypes, allLanguages, loading, error } =
    useFetchJobFilters();
  const isDisable = loading || !dirty;

  const handleClear = () => {
    resetForm();
    setShowModal?.(false);
    setFilters?.(null);
  };

  if (!initialValues) {
    return null;
  }

  return (
    <Form className="grid grid-cols-2 gap-x-4 gap-y-6">
      {initialValues[filterKeys.requestType] !== undefined && (
        <FormControl
          name={filterKeys.requestType}
          control="select"
          label="Job Type"
          placeholder="Select job type"
          isMulti
          closeMenuOnSelect={false}
          options={jobTypes}
        />
      )}
      {initialValues[filterKeys.status] !== undefined && (
        <FormControl
          name={filterKeys.status}
          control="select"
          label="Job Status"
          placeholder="Select job status"
          isMulti
          closeMenuOnSelect={false}
          options={jobStatuses}
        />
      )}
      {initialValues[filterKeys.languageFrom] !== undefined && (
        <FormControl
          name={filterKeys.languageFrom}
          control="select"
          label="Source Language"
          placeholder="Source Language"
          options={allLanguages}
          isLoading={loading}
        />
      )}
      {initialValues[filterKeys.languageTo] !== undefined && (
        <FormControl
          name={filterKeys.languageTo}
          control="select"
          label="Target Language"
          placeholder="Target Language"
          options={allLanguages}
          isLoading={loading}
        />
      )}
      {initialValues[filterKeys.billingReference] !== undefined && (
        <FormControl
          name={filterKeys.billingReference}
          control="input"
          label="Billing Reference"
          placeholder="123"
        />
      )}
      {initialValues[filterKeys.labels] !== undefined && (
        <FormControl
          name={filterKeys.labels}
          control="input"
          label="Labels"
          placeholder="Labels"
        />
      )}
      {initialValues[filterKeys.dateRange] !== undefined && (
        <FormControl
          name={filterKeys.dateRange}
          control="select"
          label="Date Range"
          placeholder="Select date range"
          defaultValue={{ value: [], label: "All Time" }}
          options={[
            { value: [], label: "All Time" },
            { value: "Today", label: "Today" },
            ...filterOptions,
          ]}
        />
      )}
      <div className="flex justify-center gap-4 col-span-2">
        <FormControl
          control="button"
          type="button"
          text="Clear"
          importance="secondary"
          disabled={loading}
          onClick={handleClear}
        />
        <FormControl
          control="button"
          type="submit"
          importance="primary"
          text="Search"
          disabled={isDisable}
        />
      </div>
    </Form>
  );
};

export default JobFilterFields;
