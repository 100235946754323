import { useAuthenticator } from "@aws-amplify/ui-react";
import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useInitializeVendorUser } from "@lango/common/hooks";
import {
  AcceptVendorInviteProvider,
  useAcceptVendorInviteCtx,
} from "@lango/common/context";

export const LoginRedirect = () => {
  const location = useLocation();
  const { state } = queryString.parse(location.hash);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { handleInitializeVendorUser } = useInitializeVendorUser();
  const { handleChangeVerifyResponse } = useAcceptVendorInviteCtx();

  useEffect(() => {
    if (authStatus === "authenticated" && state) {
      const decodedState = JSON.parse(state);
      if (decodedState?.code) {
        handleChangeVerifyResponse(decodedState, "link");
      } else {
        handleInitializeVendorUser(decodedState?.orgID);
      }
    }
  }, [
    authStatus,
    state,
    handleInitializeVendorUser,
    handleChangeVerifyResponse,
  ]);

  return null;
};

export function VendorLoginRedirect() {
  return (
    <AcceptVendorInviteProvider callbackMode>
      <LoginRedirect />
    </AcceptVendorInviteProvider>
  );
}
