import {
  NOTIFICATION_ACTIONS,
  NOTIFICATION_TYPES,
} from "@lango/common/constants/notifications";
import Button from "../../forms/components/Button";

/**
 * ActionButton Component
 * The action button for a notification within the notification list.
 * This component renders a text button that uses the notification type to render the text.
 *
 * @param {Object} props - The props for the OrganizationNotification component.
 * @param {function} props.onClick - The notification object containing details to display.
 * @param {string} props.notificationType - The type of the notification The title of the notification.
 */
export const ActionButton = ({ onClick, notificationType }) => {
  const buttonText =
    notificationType === NOTIFICATION_TYPES.NEW_INVITE
      ? NOTIFICATION_ACTIONS.ACCEPT
      : NOTIFICATION_ACTIONS.MARK_AS_READ;
  return (
    <Button
      type="button"
      textButton
      onClick={onClick}
      extraClasses="p-2 text-sm"
    >
      {buttonText}
    </Button>
  );
};
