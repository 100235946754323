/**
 * Themes
 *
 *
 */

const themes =
  {
    lango: {
        bgHoverPrimary: "hover:bg-lango-primary",
        bgHoverPrimaryLight: "hover:bg-lango-primary-light",
        animation: "transition duration-300 ease-in-out"
    },
  };

export default themes;