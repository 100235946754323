import { useReactiveVar } from "@apollo/client";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment } from "react";

import { orgLogoInfoVar } from "@lango/common/apollo/localState";
import LangoLogo from "@lango/common/components/langoLogo";

import LoadHeaderCustomLogo from "./LoadHeaderCustomLogo";
import useHasCustomLogo from "./useHasCustomLogo";

const Logo = ({ isLSPsDropdownVisible }) => {
  const { hasCustomLogo, loading } = useHasCustomLogo();
  const orgLogoInfo = useReactiveVar(orgLogoInfoVar);

  return (
    <Fragment>
      {!loading && (
        <>
          {orgLogoInfo && hasCustomLogo ? (
            <LoadHeaderCustomLogo orgId={orgLogoInfo.id} />
          ) : (
            <LangoLogo />
          )}
        </>
      )}
      {isLSPsDropdownVisible && (
        <ChevronDownIcon
          className={"mr-2 h-5 w-5 group-hover:text-gray-700 sm:ml-2 sm:mr-5"}
        />
      )}
    </Fragment>
  );
};

export default Logo;
