import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { activeOrgVar } from "@lango/common/apollo";

import {
  toastError,
  toastSuccess,
} from "@lango/common/features/alerts/functions/toast";

import { prepareClientProfileVRISettingsRequest } from "@lango/common/request";
import { UPDATE_CLIENT_VRI_SETTINGS } from "@lango/common/mutations";
import { useParams } from "react-router-dom";
import { useModal } from "../context";

export const useUpdateClientProfileVRISettings = () => {
  const { clientProfileID } = useParams();
  const activeOrg = useReactiveVar(activeOrgVar);
  const{hideModal}= useModal()

  const [updateClientVRISettingsMutation] = useMutation(
    UPDATE_CLIENT_VRI_SETTINGS,
  );
  const handleUpdateClientVRISettings = useCallback(
    async (values, actions) => {
      try {
        const request = prepareClientProfileVRISettingsRequest(
          values,
          clientProfileID,
          activeOrg?.id,
        );
        await updateClientVRISettingsMutation(request);
        toastSuccess("Client VRI settings have been updated.");
        actions?.resetForm?.({ values });
        hideModal()
      } catch (error) {
        console.log(error);
        toastError("There was an error updating the client VRI settings.");
      }
    },
    [updateClientVRISettingsMutation, clientProfileID],
  );

  return { handleUpdateClientVRISettings };
};
