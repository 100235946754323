import { PlusCircleIcon } from "@heroicons/react/solid";
import React from "react";

import classNames from "@lango/common/classnames";

import Button from "./Button";

/**
 * Renders AddButton Component
 *
 * @param {object} props - The props object.
 * @param {string} [props.text] - The text to display on the button.
 * @param {boolean} [props.light] - Optional. Indicates whether the button should be light-themed.
 * @param {boolean} [props.small] - Optional. Indicates whether the button should be small-sized.
 * @param {string} [props.extraClasses] - Optional. Additional CSS classes for the button container.
 * @param {string} [props.textExtraClasses] - Optional. Additional CSS classes for the button text.
 * @param {(event: React.MouseEvent<HTMLButtonElement>) => void} props.onClick - The function to call when the button is clicked.
 * @param {string} [props.type] - Optional
 * @returns {React.ReactNode} The rendered AddButton component.
 */
const AddButton = ({
  text = "",
  light = false,
  small = false,
  extraClasses,
  textExtraClasses,
  ...props
}) => {
  let btnClass = classNames(
    "inline-flex items-center ml-4 text-center border border-transparent shadow-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-gray-600 transition duration-300 ease-in-out",
    extraClasses,
    {
      "bg-lango-gray hover:bg-lango-light-gray focus:bg-lango-light-gray active:bg-gray-300 text-black":
        light,
      "bg-black hover:bg-gray-800 focus:bg-gray-800 active:bg-gray-800 text-white":
        !light,
      "px-0.5 py-0.5": small,
      "px-1 py-1": !small,
    },
  );

  let textClass = classNames("pr-2.5 md:pr-3.5", textExtraClasses);

  let iconClass = classNames({
    "mr-2": true,
    "text-black": light,
    "text-white": !light,
    "w-5": small,
    "w-8": !small,
  });

  return (
    <Button className={btnClass} {...props}>
      <PlusCircleIcon className={iconClass} />
      <span className={textClass}>{text}</span>
    </Button>
  );
};

export default AddButton;
