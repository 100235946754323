import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { LEFT_PAGE, PaginationControl, RIGHT_PAGE } from "./tableUtils";

const Pagination = (props) => {
  let {
    totalRecords = 0,
    pageLimit = 10,
    pageNeighbors = 0,
    pageLimits = [10, 25, 50],
    currentPage = 1,
    onPageChanged,
    heading = "records",
  } = props;

  const [selectedPageLimit, setSelectedPageLimit] = useState(pageLimit);
  const totalPages = Math.ceil(totalRecords / pageLimit);

  useEffect(() => {
    setSelectedPageLimit(pageLimit);
  }, [pageLimit]);

  // pageNeighbors can be: 0, 1 or 2
  pageNeighbors =
    typeof pageNeighbors === "number"
      ? Math.max(0, Math.min(pageNeighbors, 2))
      : 0;

  /**
   * Helper method for creating a range of numbers
   * range(1, 5) => [1, 2, 3, 4, 5]
   */
  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbors * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbors);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);
      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const gotoPage = (page) => {
    const paginationData = {
      page,
      limit: pageLimit,
    };

    onPageChanged(paginationData);
  };

  const handleClick = (page) => (evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage + 1);
  };

  const handlePageLimit = (limit) => {
    if (pageLimit !== limit) {
      pageLimit = limit;
      gotoPage(1);
    }
    setSelectedPageLimit(limit);
  };

  const pages = fetchPageNumbers();

  let endIndex = currentPage * pageLimit;
  endIndex = endIndex < totalRecords ? endIndex : totalRecords;

  const startIndex =
    currentPage > 1
      ? (currentPage - 1) * pageLimit + 1
      : totalRecords === 0
      ? 0
      : 1;
  const recordsPerPage = startIndex + "-" + endIndex;

  return (
    <nav
      aria-label="Page navigation"
      className={"flex items-center justify-between py-8 text-sm md:text-base"}
    >
      <div className={"w-0 flex-1 sm:block"}>
        Showing <span className="font-bold">{recordsPerPage} </span> of{" "}
        {totalRecords} {heading}
      </div>
      <div className={"hidden md:-mt-px md:flex"}>
        <ul className="inline-flex space-x-2 self-center">
          {pages?.map((page, index) => {
            const handleMoveControl =
              page === LEFT_PAGE
                ? handleMoveLeft
                : page === RIGHT_PAGE
                ? handleMoveRight
                : handleClick(page);
            return (
              <PaginationControl
                key={index}
                page={page}
                isActivePage={page === currentPage}
                onClick={handleMoveControl}
              />
            );
          })}
        </ul>
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <div className="relative inline-block text-left text-sm md:text-base">
          <div className="flex items-center">
            <span className="mr-2 font-medium">View</span>
            <Listbox value={selectedPageLimit} onChange={handlePageLimit}>
              <div className="relative">
                <Listbox.Button className="focus:outline-none relative w-full cursor-pointer cursor-default rounded-full bg-white py-1 pl-3 pr-8 text-left text-sm font-bold text-black focus-visible:border-black focus-visible:ring-2 focus-visible:ring-black/75 focus-visible:ring-offset-2 focus-visible:ring-offset-black sm:text-sm md:text-lg">
                  <span className="block truncate text-sm md:text-base">
                    {selectedPageLimit}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-black"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Listbox.Options className="w-18 focus:outline-none absolute mt-1 max-h-60 overflow-auto rounded-lg bg-white py-1 text-sm font-bold text-black shadow-md ring-black">
                    {pageLimits.map((limit, index) => (
                      <Listbox.Option
                        key={index}
                        value={limit}
                        className={({ active }) =>
                          `${active ? "bg-black text-white" : "text-black"}
                                                relative cursor-pointer select-none py-2 pl-10 pr-4 text-sm font-bold md:text-base`
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={"block truncate font-bold"}>
                              {limit}
                            </span>
                            {selected ? (
                              <span
                                className={`${
                                  active ? "text-white" : "text-black"
                                }
                                                        absolute inset-y-0 left-0 flex items-center pl-3`}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbors: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChanged: PropTypes.func,
  heading: PropTypes.string,
};

export default Pagination;
