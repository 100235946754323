import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { clearOrgLogoInfo, initializeUserData } from "@lango/common/apollo";
import { toastError } from "@lango/common/features";
import { AUTH_ROUTES, VENDOR_ROUTES } from "@lango/common/routes";

import { useProvisionAndFetchUser } from "../features/auth/hooks";

export const useInitializeVendorUser = () => {
  const { findOrRegisterUser, loading } = useProvisionAndFetchUser();
  const navigate = useNavigate();

  const handleInitializeVendorUser = useCallback(
    async (organizationID) => {
      try {
        const user = await findOrRegisterUser({ organizationID });
        initializeUserData(user);
        navigate(VENDOR_ROUTES.REQUESTS);
      } catch (err) {
        toastError(err?.message || err);
        navigate(AUTH_ROUTES.LOGOUT);
        clearOrgLogoInfo();
      }
    },
    [findOrRegisterUser],
  );

  return { handleInitializeVendorUser, findOrRegisterUser, loading };
};
