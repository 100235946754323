import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const linguistUserColumns = [
  columnHelper.accessor("firstName", {
    id: "firstName",
    header: "First Name",
  }),
  columnHelper.accessor("lastName", {
    id: "lastName",
    header: "Last Name",
  }),
  columnHelper.accessor("email", {
    id: "email",
    header: "Email",
  }),
];
