import { Form, useField } from "formik";

import FormControl from "@lango/common/features/forms/components/FormControl";

import useUpdateLinguistJobTypes from "./updateLinguistServiceLines";

const ServiceLineFields = () => {
  const [{ value }] = useField("jobTypes");
  const { updateLinguistJobType, loading } = useUpdateLinguistJobTypes();

  return (
    <Form className="space-y-8">
      {value?.map((value, index) => (
        <FormControl
          key={index}
          control="toggle"
          name={`jobTypes.${index}.enabled`}
          label={value?.name}
          description="Inactive/Active"
          disabled={loading}
          onChange={(enabled) => updateLinguistJobType({ ...value, enabled })}
        />
      ))}
    </Form>
  );
};

export default ServiceLineFields;
