import { Tabs } from "flowbite-react";

import { UnreadIcon } from "@lango/common/components/UnreadIcon";
import { BUYER_APP } from "@lango/common/constants";
import env from "@lango/common/env";
import { OrganizationNotificationsTab } from "./OrganizationTab";
import { UserNotificationsTab } from "./UserTab";

const tabTheme = {
  tabpanel: "py-0",
  tabitemcontainer: {
    base: "grid",
  },
  tablist: {
    base: "grid",
    tabitem: {
      base: "py-0 flex text-black items-center justify-center p-4 font-lg first:ml-0 focus:ring-0",
      variant: {
        underline: {
          base: "border-b-4 font-bold",
          active: {
            on: "border-black",
            off: "border-gray-300",
          },
        },
      },
    },
    variant: {
      underline: "grid w-full grid-cols-2 bg-white",
    },
  },
};

const { appName } = env;

/**
 * NotificationTabs Component
 *
 * Renders user and organizations tabs for the notifications panel.
 * On the buyer app, only the user notifications tab is shown,
 * since there are no organization notifications for clients at the moment.
 * The tab headers also include the red dot icon to indicate if there are new
 * notifications for that tab.
 *
 * @param {Object} props - Props for the component.
 * @param {Array} props.organizationNotifications - An array of organization notifications.
 * @param {Array} props.userNotifications - An array of user notifications.
 * @param {function} props.closePanel - A function to close the panel.
 * @param {function} props.setActiveTab - A function to inform the parent component of the active tab.
 * @returns
 */

export const NotificationTabs = ({
  organizationNotifications,
  userNotifications,
  closePanel,
  setActiveTab,
}) => {
  const hasUnreadUserNotifications = userNotifications.some((n) => !n.isRead);
  const hasUnreadOrganizationNotifications = organizationNotifications.some(
    (n) => !n.isRead,
  );

  if (appName === BUYER_APP) {
    // don't show organization notifications on buyer app
    return (
      <UserNotificationsTab
        notifications={userNotifications}
        closePanel={closePanel}
      />
    );
  }
  return (
    <Tabs
      variant="underline"
      className="gap-0 m-0"
      theme={tabTheme}
      onActiveTabChange={(number) => setActiveTab(number)}
    >
      <Tabs.Item
        title="User"
        icon={() =>
          hasUnreadUserNotifications && (
            <UnreadIcon
              containerClasses="relative"
              extraClasses="transform translate-x-3 -translate-y-2"
            />
          )
        }
      >
        <UserNotificationsTab
          notifications={userNotifications}
          closePanel={closePanel}
        />
      </Tabs.Item>
      <Tabs.Item
        title="Organization"
        icon={() =>
          hasUnreadOrganizationNotifications && (
            <UnreadIcon
              containerClasses="relative"
              extraClasses="transform translate-x-[4.75rem] -translate-y-2"
            />
          )
        }
      >
        <OrganizationNotificationsTab
          notifications={organizationNotifications}
          closePanel={closePanel}
        />
      </Tabs.Item>
    </Tabs>
  );
};
