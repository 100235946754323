import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { updateOrgLogoInfo } from "@lango/common/apollo/localState";
import { ORG_ERROR, PM_APP } from "@lango/common/constants";
import env from "@lango/common/env";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import useQueryParams from "@lango/common/hooks/useQueryParams";
import { AUTH_ROUTES } from "@lango/common/routes";

import { CUSTOM_LOGIN_SUPPORT_DISABLED } from "../../containers/authUtils";
import { ORG_CUSTOM_LOGIN_INFO } from "./queries";

const { appName } = env;

export const useValidateOrgCustomLoginSupport = () => {
  const navigate = useNavigate();
  const { getQueryParams } = useQueryParams();
  const orgSlug = getQueryParams("org");
  const { data, error, loading } = useQuery(ORG_CUSTOM_LOGIN_INFO, {
    context: { clientName: "public" },
    variables: { orgSlugName: orgSlug, applicationName: appName },
    skip: orgSlug && appName !== PM_APP ? false : true,
    onError: (error) => {
      console.error(error);
      toastError(ORG_ERROR);
      navigate(AUTH_ROUTES.LOGIN);
    },
    onCompleted: ({ getOrgCustomLoginInfo }) => {
      const { hasCustomLoginPage, hasCustomLogo, id } = getOrgCustomLoginInfo;
      if (!hasCustomLoginPage) {
        toastError(CUSTOM_LOGIN_SUPPORT_DISABLED);
        navigate(AUTH_ROUTES.LOGIN);
      }
      hasCustomLogo && updateOrgLogoInfo(id);
    },
  });
  return { error, loading, orgInfo: data?.getOrgCustomLoginInfo };
};
