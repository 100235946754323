import {
  fetchUserAttributes,
  signIn,
  signInWithRedirect,
} from "aws-amplify/auth";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  federatedLoginVar,
  initializeUserData,
} from "@lango/common/apollo/localState";
import { useProvisionAndFetchUser } from "@lango/common/features/auth/hooks";
import { clearHubspotConversation } from "@lango/common/components/HubspotChatWidget/utils";

export const useLogin = () => {
  const { findOrRegisterUser } = useProvisionAndFetchUser();
  const navigate = useNavigate();

  const handleInitializeFederatedUser = useCallback(
    async (customState) => {
      const { given_name, family_name } = await fetchUserAttributes();
      const res = await findOrRegisterUser({
        firstName: given_name,
        lastName: family_name,
        organizationID: customState?.orgID,
        registerUser: true,
      });
      federatedLoginVar(true);
      initializeUserData(res);
      navigate("/");
    },
    [findOrRegisterUser],
  );

  const handleLoginWithCognito = async (username, password, organizationID) => {
    await signIn({ username, password });
    await handleFetchAndInitializeUser(organizationID);
  };

  const handleFetchAndInitializeUser = async (
    organizationID,
    clearHS = true,
  ) => {
    const res = await findOrRegisterUser({ organizationID });
    initializeUserData(res);
    clearHS && clearHubspotConversation();
  };

  const handleFederatedLogin = useCallback(
    async (customProvider, customState) => {
      await signInWithRedirect({
        customState,
        provider: { custom: customProvider },
      });
    },
    [],
  );

  return {
    handleInitializeFederatedUser,
    handleLoginWithCognito,
    handleFederatedLogin,
    handleFetchAndInitializeUser,
  };
};
