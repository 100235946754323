import React from "react";
import cx from "@lango/common/classnames";

export const Container = (props) => {
  return (
    <div className={cx("container mx-auto max-w-md", props.containerClasses)}>
      <div
        className={cx("rounded-xl bg-white p-8 shadow-md", props.extraClasses)}
      >
        {props.children}
      </div>
    </div>
  );
};
