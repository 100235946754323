import { useCallback } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";

import { toastSuccess, toastWarning } from "@lango/common/features";
import { INVITE_ORG_USER } from "@lango/common/queries";
import { inviteOrgUserRequest } from "@lango/common/request";
import { activeOrgVar } from "../apollo";
import { useModal } from "../context";

/**
 * useInviteOrgUser Hook
 *
 * This is a custom React Hook that invites a user to the current organization and closes the modal.
 *
 * @hook useInviteOrgUser
 */
export const useInviteOrgUser = () => {
  const { hideModal } = useModal();
  const [inviteOrgUser] = useMutation(INVITE_ORG_USER);
  const org = useReactiveVar(activeOrgVar);

  const handleInviteOrgUser = useCallback(
    async (/** @type {any} */ values) => {
      try {
        await inviteOrgUser(
          inviteOrgUserRequest({ ...values, orgID: org?.id }),
        );
        hideModal();
        toastSuccess("User invited successfully");
      } catch (error) {
        toastWarning(error?.message || "Error inviting lsp user");
        console.error(error);
      }
    },
    [inviteOrgUser],
  );

  return { handleInviteOrgUser };
};
