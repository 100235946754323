import { useQuery} from "@apollo/client";
import { ORGANIZATION_LOGO_URL } from "@lango/common/queries";

const useFetchOrgCustomLogoURL = (organizationID) => {
  const { loading, error, data } = useQuery(ORGANIZATION_LOGO_URL, {
    variables: { organizationID },
    fetchPolicy: "cache-and-network",
  });

  return { url: data?.getOrganizationLogoURL || "", loading, error };
};

export default useFetchOrgCustomLogoURL;
