import { useNavigate } from "react-router-dom";

import { activeOrgVar } from "@lango/common/apollo/localState";
import { ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY } from "@lango/common/constants/jobTypes";
import BackButton from "@lango/common/features/forms/components/BackButton";
import { primaryTabStyles } from "@lango/common/features/tab";
import Tab from "@lango/common/features/tab";
import { VENDOR_ROUTES } from "@lango/common/routes";

import LinguistProfile from "./linguistProfile";
import ServiceLines from "./linguistServiceLines";

export const categories = [ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY];

export const updateLinguistProfileRequest = ({
  linguistProfileID,
  isActive,
}) => {
  return {
    variables: {
      input: {
        id: linguistProfileID,
        vendorID: activeOrgVar()?.id,
        isActive,
      },
    },
  };
};

export const prepareServiceLines = (data) => {
  const jobTypes = data?.allJobTypes?.map((jobType) => {
    const enabled = data?.findLinguistJobTypes?.some(
      (linguistJobType) => linguistJobType?.jobType?.id === jobType.id,
    );
    return { ...jobType, enabled };
  });

  return { jobTypes };
};

export const updateServiceLineRequest = ({ linguistID, enabled, id }) => {
  return {
    variables: {
      input: {
        userID: linguistID,
        vendorID: activeOrgVar()?.id,
        jobTypeID: id,
        enabled,
      },
    },
  };
};

export const LinguistTabPanel = () => (
  <Tab styles={primaryTabStyles}>
    <Tab.Panel name="Linguist Profile">
      <LinguistProfile />
    </Tab.Panel>
    <Tab.Panel name="Supported Service Lines">
      <ServiceLines />
    </Tab.Panel>
  </Tab>
);

export const NavigateToLinguists = () => {
  const navigate = useNavigate();
  const handleBackToLinguists = () =>
    navigate(`${VENDOR_ROUTES.SETTINGS}/linguists`);
  return <BackButton className="mb-4" onClick={handleBackToLinguists} />;
};
