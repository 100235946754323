import Dropdown from "@lango/common/components/dropdown";
import { AdjustmentsIcon } from "@heroicons/react/solid";
import { iconButtonStyles, iconButtonStylesInner } from "./IconButton";
import { filterTypes } from "@lango/common/request";
import Flex from "./Flex";

const psuedoTabsStyles =
  "hidden md:flex items-center transition ease-in-out duration-150 w-full text-nowrap text-sm text-black py-4 px-6 font-bold border-b-4 hover:bg-white hover:border-black";

export const QuickFilters = ({
  quickFilters,
  setFilters,
  expandQuickFilters,
}) => {
  if (expandQuickFilters) {
    return (
      <>
        <Flex>
          {quickFilters.map(({ label, filters }) => (
            <button
              key={label}
              onClick={() =>
                setFilters({
                  [filterTypes.common]: filters,
                  [filterTypes.custom]: [],
                })
              }
              className={psuedoTabsStyles}
            >
              {label}
            </button>
          ))}
        </Flex>
        <div className="grow" />
      </>
    );
  }
  return (
    <Dropdown
      buttonClassName={iconButtonStyles}
      title="Quick Filters"
      className="w-60"
    >
      <Dropdown.Button>
        <AdjustmentsIcon className={iconButtonStylesInner} />
      </Dropdown.Button>
      <Dropdown.Menu>
        {quickFilters.map(({ label, filters }) => (
          <button
            key={label}
            role="menuitem"
            onClick={() =>
              setFilters({
                [filterTypes.common]: filters,
                [filterTypes.custom]: [],
              })
            }
            className={
              "px-4 py-3 flex items-center text-sm font-medium text-black hover:bg-gray-100 transition ease-in-out duration-150 w-full"
            }
          >
            <div className="ml-2">
              <p className="text-sm font-medium text-black">{label}</p>
            </div>
          </button>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
