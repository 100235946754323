import { Dropdown } from "flowbite-react";
import { HiDotsHorizontal as EllipsesHorizontal } from "react-icons/hi";

import { useModal } from "@lango/common/context";
import {
  useDeleteVendorProfileLanguage,
  useUpdateQualificationStatus,
  useUpdateVendorProfileLanguagePair,
} from "@lango/common/hooks";
import { vendorLanguageUpdateInitialValues } from "@lango/common/initialValues";

import LanguagePairForm from "../languagePairForm";
import environmentVars from "@lango/common/env";
import { VENDOR_APP } from "@lango/common/constants";

const appName = environmentVars.appName;

const VendorProfileLanguageRowActions = ({ row, organizationID }) => {
  const { showModal } = useModal();
  const { handleUpdateLanguagePair } = useUpdateVendorProfileLanguagePair();
  const { handleDeleteVendorProfileLanguage } = useDeleteVendorProfileLanguage(organizationID);
  const { handleUpdateQualification } = useUpdateQualificationStatus();

  const handleUpdateVendorProfileLanguage = () => {
    showModal(LanguagePairForm, {
      heading: "Update Language Pair",
      initialValues: vendorLanguageUpdateInitialValues(row),
      onSubmit: handleUpdateLanguagePair,
    });
  };

  return (
    <Dropdown label={<EllipsesHorizontal />} arrowIcon={false} inline placement="left-start">
      <Dropdown.Item onClick={handleUpdateVendorProfileLanguage}>
        Update
      </Dropdown.Item>
      <Dropdown.Item onClick={() => handleDeleteVendorProfileLanguage(row?.id)}>
        Delete
      </Dropdown.Item>
      {row?.qualificationStatus === "Declined" && appName == VENDOR_APP && (
        <Dropdown.Item onClick={() => handleUpdateQualification([row?.id], "Pending")} >
          Re-request Approval
        </Dropdown.Item>
      )}
    </Dropdown>
  );
};

export default VendorProfileLanguageRowActions;
