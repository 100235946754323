import { Form, useFormikContext } from "formik";

import { Box } from "@lango/common/components/Box";
import FormControl from "@lango/common/features/forms/components/FormControl";
import Flex from "@lango/common/features/tables/components/Flex";
import LoginCard from "../../auth/containers/LoginCard";

const LoginInvitationFormFields = () => {
  const { isSubmitting, dirty, values } = useFormikContext();
  const disableFields = isSubmitting || values?.disableFields;

  return (
    <Box spaceY={8}>
      <FormControl
        control="input"
        name="email"
        label="Email"
        type="email"
        placeholder="Enter email"
        disabled
      />
      <FormControl
        control="input"
        label="Password"
        type="password"
        placeholder="Enter password"
        name="password"
        autoComplete="current-password"
        disabled={disableFields}
      />
      <Flex justify="center">
        <FormControl
          control="button"
          type="submit"
          text="Sign in"
          disabled={disableFields || !dirty}
          showLoader={disableFields}
        />
      </Flex>
    </Box>
  );
};

export function InviteLoginForm() {
  return (
    <Form>
      <LoginCard>
        <h2 className="pb-5 text-center text-2xl font-bold text-black">
          Sign In
        </h2>
        <LoginInvitationFormFields />
      </LoginCard>
    </Form>
  );
}
