import { useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { activeLSPVar, activeOrgVar } from "@lango/common/apollo";
import {
  AcceptVendorInviteProvider,
  useAcceptVendorInviteCtx,
} from "@lango/common/context";
import {
  useCanUpdateOrg,
  useFetchVendorOnboardingStatus,
} from "@lango/common/hooks";
import NoticeBanner from "@lango/common/features/alerts/components/NoticeBanner";
import Button from "@lango/common/features/forms/components/Button";
import { VENDOR_ROUTES } from "@lango/common/routes";
import { toastSuccess } from "@lango/common/features";

/**
 * Renders the banner details for the onboarding page.
 * Displays the completion percentage of the user's profile setup with the LSP.
 * Provides a button to navigate to the onboarding page.
 */
function BannerDetails() {
  const navigate = useNavigate();
  const lsp = useReactiveVar(activeLSPVar);
  const { status } = useFetchVendorOnboardingStatus();

  return (
    status?.percentage < 100 && (
      <NoticeBanner
        message={
          <>
            Your profile with {lsp?.name} is
            <b> {status?.percentage}% </b>complete. Finish setting it up now to
            start getting more jobs.{" "}
            <Button
              textButton
              type="button"
              text="Resume"
              onClick={() => navigate(VENDOR_ROUTES.ONBOARDING)}
            />
          </>
        }
        dark={false}
      />
    )
  );
}

/**
 * Renders the onboarding steps for the user.
 * Handles the onboarding status and updates the onboarding data accordingly.
 */
function OnboardingSteps() {
  const navigate = useNavigate();
  const org = useReactiveVar(activeOrgVar);
  const lsp = useReactiveVar(activeLSPVar);
  const { handleChangOnboardingData } = useAcceptVendorInviteCtx();

  function handleAlreadyCompletedOnboarding() {
    toastSuccess("Onboarding is already completed");
    navigate(VENDOR_ROUTES.REQUESTS);
  }

  function handleOnboardingStatus(status) {
    const nextStep = status?.nextStep;
    ["step1", "step2", "step3"].includes(nextStep)
      ? handleChangOnboardingData(
          { ...status, organizationID: org?.id, lspID: lsp?.id },
          nextStep,
        )
      : handleAlreadyCompletedOnboarding();
  }

  useFetchVendorOnboardingStatus(handleOnboardingStatus);

  return null;
}

/**
 * Renders the wrapper component for the onboarding banner.
 * Checks if the organization and LSP IDs are available and if the user can update the organization.
 */
function Wrapper({ children }) {
  const { canUpdateOrg } = useCanUpdateOrg();
  const org = useReactiveVar(activeOrgVar);
  const lsp = useReactiveVar(activeLSPVar);

  return canUpdateOrg && org?.id && lsp?.id && children;
}

/**
 * Renders the onboarding banner component.
 * Wraps the banner details component with the wrapper component.
 */
export function OnboardingBanner() {
  return (
    <Wrapper>
      <BannerDetails />
    </Wrapper>
  );
}

/**
 * Renders the main onboarding component.
 * Wraps the onboarding steps component with the accept vendor invite provider component.
 */
export default function Onboarding() {
  return (
    <Wrapper>
      <AcceptVendorInviteProvider>
        <OnboardingSteps />
      </AcceptVendorInviteProvider>
    </Wrapper>
  );
}
