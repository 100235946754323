import { useQuery } from "@apollo/client";
import env from "@lango/common/env";
import { toastError } from "../features";
import { USER_NOTIFICATIONS } from "../queries";

const appName = env.appName;

export const useGetUserNotifications = (offset, pageLimit) => {
  const { loading, error, data, refetch } = useQuery(USER_NOTIFICATIONS.GET, {
    variables: { offset, pageLimit, appName },
    fetchPolicy: "cache-and-network",
    onError: (error) => toastError(error),
  });

  return {
    unreadCount: data?.paginatedNotifications?.notifications?.filter(
      (notification) => !notification.isRead,
    ).length,
    paginatedNotifications:
      data?.paginatedNotifications?.notifications?.map((notification) => ({
        ...notification,
        isNew: true,
        sentBy: {
          name: notification?.description.split(" ").slice(0, 2).join(" "),
        },
        isUserNotification: true,
      })) || [],
    totalRecords: data?.paginatedNotifications?.totalRecords || 0,
    refetch,
    error,
    loading,
  };
};
