import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import {
  toastError,
  toastSuccess,
} from "@lango/common/features/alerts/functions/toast";
import { UPDATE_LINGUIST_JOB_TYPE } from "@lango/common/queries";

import { updateServiceLineRequest } from "../../utils";

const useUpdateLinguistJobTypes = () => {
  const { linguistID } = useParams();
  const [updateOrgServiceLines, { loading }] = useMutation(UPDATE_LINGUIST_JOB_TYPE);

  const updateLinguistJobType = async (props) => {
    try {
      const request = updateServiceLineRequest({ linguistID, ...props });
      await updateOrgServiceLines(request);
      toastSuccess("Updated linguist service lines");
    } catch (error) {
      toastError("There was an error updating the linguist service lines");
    }
  };

  return { updateLinguistJobType, loading };
};

export default useUpdateLinguistJobTypes;
