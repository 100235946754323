import { authStateVar } from "@lango/common/apollo/localState";
import { isBuyerOrVendorApp } from "@lango/common/env/index";

/**
 * @revokeCookieConsent Remove the cookies created by the HubSpot tracking code that are included in the consent banner under GDPR, include the HubSpot cookies related to tracking the visitor. As a result of the cookies being removed, the visitor would see the cookie consent banner on their next page load, as they would appear as a new visitor.
 * @clear The clear method can be used to delete these cookies, returning the widget to its default state on subsequent loads.
 * @resetWidget You can pass {resetWidget:true} to the clear() function to clear all chat related cookies, remove the widget from the page, and create a new instance of the chat widget.
 */
export const resetHubspotWidget = () => {
  if (isBuyerOrVendorApp) {
    window?._hsp?.push?.(["revokeCookieConsent"]);
    window?.HubSpotConversations?.clear?.({ resetWidget: true });
  }
};

/**
 * @clear The clear method can be used to delete these cookies, returning the widget to its default state on subsequent loads.
 * @resetWidget You can pass {resetWidget:true} to the clear() function to clear all chat related cookies, remove the widget from the page, and create a new instance of the chat widget.
 */
export const clearHubspotConversation = () => {
  if (isBuyerOrVendorApp) {
    window?.HubSpotConversations?.clear?.({ resetWidget: true });
  }
};

/**
 * @beforeunload The beforeunload event is fired when the current window, contained document, and associated resources are about to be unloaded.
 * - This method listens for the beforeunload event, checks if the user is not signed in and then resets the hubspot widget
 */
export const resetHubspotOnUnloadIfUnauthenticated = () => {
  window.addEventListener("beforeunload", () => {
    const authState = authStateVar();
    authState === "signIn" && resetHubspotWidget();
  });
};
