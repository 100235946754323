import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import {
  toastError,
  toastSuccess,
} from "@lango/common/features/alerts/functions/toast";
import { PASSWORD_CHANGED } from "@lango/common/features/auth/containers/authUtils";
import { CONFIRM_FORGOT_PASSWORD } from "@lango/common/queries";
import { AUTH_ROUTES } from "@lango/common/routes";

export const useConfirmForgotPassword = () => {
  const navigate = useNavigate();
  const [confirmForgotPassword] = useMutation(CONFIRM_FORGOT_PASSWORD, {
    context: { clientName: "public" },
  });

  const handleConfirmForgotPassword = async ({
    username,
    code,
    newPassword,
  }) => {
    try {
      await confirmForgotPassword({
        variables: { email: username, code, newPassword },
      });
      toastSuccess(PASSWORD_CHANGED);
      navigate(AUTH_ROUTES.LOGIN);
    } catch (error) {
      toastError("There was a problem confirming your new password.");
      navigate(AUTH_ROUTES.FORGOT_PASSWORD);
      console.error(error);
    }
  };

  return { handleConfirmForgotPassword };
};
