import Button from "./Button";
import Checkbox from "./Checkbox";
import FileUploadInput from "./FileUploadInput";
import PhoneInput from "./PhoneInput";
import PhysicalAddressInput from "./PhysicalAddressInput";
import Radio from "./Radio";
import RateInput from "./RateInput";
import Select from "./Select";
import TextInput from "./TextInput";
import Textarea from "./Textarea";
import Toggle from "./Toggle";

// todo: Add more controls
const ControlsList = {
  address: PhysicalAddressInput,
  button: Button,
  checkbox: Checkbox,
  file: FileUploadInput,
  input: TextInput,
  radio: Radio,
  rate: RateInput,
  select: Select,
  textarea: Textarea,
  toggle: Toggle,
  phone: PhoneInput,
};

const FormControl = ({ control, ...rest }) => {
  const Control = ControlsList[control];
  return <Control {...rest} />;
};

export default FormControl;
