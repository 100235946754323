import { makeVar } from "@apollo/client";
import isString from "lodash.isstring";

import environmentVars from "../env";

const stringify = (value) => {
  return isString(value) ? value : JSON.stringify(value);
};

const parse = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export const makePersistedVar = (key, value = null) => {
  const lastValue = localStorage.getItem(key);
  if (lastValue) value = parse(lastValue);

  const rv = makeVar(value);

  const onNextChange = (value) => {
    try {
      if (environmentVars.debugLog) console.log("Persisting", key, value);
      if (value) localStorage.setItem(key, stringify(value));
      else localStorage.removeItem(key);
    } catch (err) {
      console.warn(err);
    }
    rv.onNextChange(onNextChange);
  };

  rv.onNextChange(onNextChange);
  return rv;
};
