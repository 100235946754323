import { useParams } from "react-router-dom";
import { useFetchVendorJobDetails } from "./fetchVendorJobDetails";
import JobDetailsHeader from "@lango/common/features/header/components/JobDetailsHeader";
import WithLoader from "@lango/common/components/WithLoader";
import PlainTable from "@lango/common/features/tables/components/PlainTable";
import { onDemandJobColumns } from "./columns";
import React from "react";

const VendorOnDemandJobDetails = () => {
  const { jobID } = useParams();

  // useFetchVendorJobDetails is a custom hook that fetches the job details for the given jobID
  // It returns an object containing the job details, the loading state, and any error that occurred during fetching
  const { job, loading, error } = useFetchVendorJobDetails(jobID);

  return (
    <>
      <JobDetailsHeader extraClasses="mb-6" heading="Request" jobID={jobID} />
      <WithLoader loading={loading} error={error}>
        <PlainTable columns={onDemandJobColumns} data={job} />
      </WithLoader>
    </>
  );
};

export default VendorOnDemandJobDetails;