import classNames from "@lango/common/classnames";
import { Grid } from "@lango/common/components/Grid";
import { useField, useFormikContext } from "formik";
import { FieldIcon } from "./FieldIcon";
import InputLabel from "./InputLabel";
import { usePlacesWidget } from "react-google-autocomplete";
import TextInput from "./TextInput";
import { useEffect } from "react";
import environmentVars from "@lango/common/env";

const PhysicalAddressInput = ({
  label = undefined,
  showLabel = undefined,
  extraClasses = undefined,
  size = undefined,
  infoText = undefined,
  readOnly = undefined,
  whiteBackground = undefined,
  containerClasses = undefined,
  icon = undefined,
  ...props
}) => {
  const [field, meta] = useField(props);
  const {
    setFieldValue,
    values: { addressName, addressStreet },
  } = useFormikContext();

  useEffect(() => {
    const components = [addressName, addressStreet].filter(Boolean);
    setFieldValue(props.name, components.join(", "));
  }, [addressStreet, addressName]);

  const { ref } = usePlacesWidget({
    apiKey: environmentVars.googleApiKey,
    onPlaceSelected: ({ formatted_address }) => {
      setFieldValue("addressStreet", formatted_address);
    },
    options: {
      types: ["address"],
    },
  });

  return (
    <div className={classNames("text-left", containerClasses)}>
      <div className="flex justify-between">
        {showLabel !== false && label && (
          <InputLabel
            labelFor={props.id || props.name}
            text={label}
            infoText={infoText}
          />
        )}
      </div>
      <div className="relative">
        <Grid cols={4} extraClasses="gap-4">
          <Grid.Col span={2}>
            <TextInput
              autoComplete="name"
              placeholder="Name"
              name="addressName"
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <TextInput
              placeholder="Street Address"
              name="addressStreet"
              innerRef={ref}
            />
          </Grid.Col>
        </Grid>
        {props.description && (
          <div className={"mt-2 text-sm text-gray-500"}>
            {props.description}
          </div>
        )}
        <FieldIcon icon={icon} meta={meta} />
      </div>
      {meta.touched && meta.error && (
        <p className="ml-3 mt-2 h-1 text-sm text-red-600" id={props.errorMsgID}>
          {meta.error}
        </p>
      )}
    </div>
  );
};

export default PhysicalAddressInput;
