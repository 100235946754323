export const legacyTabStyles = {
  header: "bg-lango-gray rounded-t-xl overflow-hidden",
  active: "bg-white py-6 px-8 font-bold",
  inactive: "py-6 px-8 font-bold",
};

export const primaryTabStyles = {
  active: "border-b-4 font-bold bg-white border-black py-4 px-6",
  inactive: "py-4 px-6 font-bold border-b-4",
  header: "mb-8",
  container: "",
};

export const bottomBorderTabStyles = {
  header: "overflow-hidden",
  active: "bg-white py-4 px-8 font-bold border-b-2 border-black",
  inactive: "py-4 px-8 font-bold border-b-2 border-gray-400",
};

export const basicTabStyles = {
  header: "bg-lango-gray rounded-t-xl overflow-hidden",
  active: "border-b-4 border-b-black bg-white py-6 px-8 font-bold",
  inactive: "border-b-gray-300 border-b-4 py-6 px-8 font-bold",
};
