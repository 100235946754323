import React from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { Tooltip } from "flowbite-react";

function renderLanguagePair(sourceLanguage, targetLanguage) {
  return (
    <div className={"flex items-center"} key={sourceLanguage}>
      {sourceLanguage ?? (
        <span className={"italic text-gray-500"}>Processing</span>
      )}
      <ArrowRightIcon className={"w-6 px-1"} />
      {targetLanguage}
    </div>
  );
}

function renderSourceLanguagesTooltip(sourceLanguages, targetLanguage) {
  const tooltipProps = {
    style: "light",
    content: sourceLanguages?.map((lang) =>
      renderLanguagePair(`${lang.name} (${lang.isoCodeLong})`, targetLanguage),
    ),
  };
  return (
    <Tooltip {...tooltipProps}>
      <span className="cursor-pointer">
        {renderLanguagePair("Multiple", targetLanguage)}
      </span>
    </Tooltip>
  );
}

const Languages = ({ languageFrom, languageTo, sourceLanguages }) => {
  const targetLanguage =
    languageTo && `${languageTo.name} (${languageTo.isoCodeLong})`;

  const totalSourceLanguages = sourceLanguages?.length;

  const sourceLanguage = languageFrom
    ? `${languageFrom.name} (${languageFrom.isoCodeLong})`
    : totalSourceLanguages === 1
      ? `${sourceLanguages[0].name} (${sourceLanguages[0].isoCodeLong})`
      : "N/A";

  return totalSourceLanguages > 1
    ? renderSourceLanguagesTooltip(sourceLanguages, targetLanguage)
    : renderLanguagePair(sourceLanguage, targetLanguage);
};

export default Languages;
