import { gql } from "@apollo/client";

import { LANGUAGE } from "../job";

export const TASK_TYPE = gql`
  fragment taskType on Task {
    taskType {
      id
      name
    }
  }
`;

export const TASK_FILES = gql`
  fragment taskFiles on File {
    id
    name
  }
`;

export const TASK_STATUS = gql`
  fragment taskStatus on TaskStatus {
    id
    slug
  }
`;

export const TASK_TYPE_AND_FILES = gql`
  ${TASK_TYPE}
  ${TASK_FILES}
  fragment taskTypeAndFiles on Task {
    ...taskType
    taskFiles {
      ...taskFiles
    }
  }
`;

export const TASK_LANGUAGES = gql`
  ${LANGUAGE}
  fragment taskLanguages on Task {
    languageFrom {
      ...BasicLanguageFields
    }
    languageTo {
      ...BasicLanguageFields
    }
  }
`;

export const JOB_LANGUAGES = gql`
  ${LANGUAGE}
  fragment jobLanguages on Job {
    languageFrom {
      ...BasicLanguageFields
    }
    languageTo {
      ...BasicLanguageFields
    }
  }`;

export const PAGINATED_TASK_JOB = gql`
  ${TASK_FILES}
  ${TASK_LANGUAGES}
  ${TASK_STATUS}
  fragment paginatedTaskJob on Task {
    ...taskLanguages
    taskFiles {
      ...taskFiles
      size
      automaticallyDeleted
    }
    taskStatus {
      ...taskStatus
      name
    }
  }
`;

export const CALL_LOG = gql`
  fragment callLog on CallLogTimelineEvent {
    id
    identity
    eventTime
    eventType
  }
`;
