import * as yup from "yup";

import { isAgencyType, isIndividualType } from "../constants";

const validateFirstName = yup
  .string()
  .nullable()
  .required("First name is required")
  .min(2, "First name must be at least 2 characters")
  .max(50, "First name can be a maximum of 50 characters");

const validateLastName = yup
  .string()
  .nullable()
  .required("Last name is required")
  .min(2, "Last name must be at least 2 characters")
  .max(50, "Last name can be a maximum of 50 characters");

const validateEmail = yup
  .string()
  .nullable()
  .email()
  .required("Email is required")
  .min(5, "Email should be at least 5 characters")
  .max(50, "Email should not exceed 50 characters");

export const createVendor = yup.object().shape({
  vendorType: yup.number().required("Vendor type is required").nullable(),
  name: yup.string().when("vendorType", {
    is: isAgencyType, // check if vendor type is agency
    then: yup
      .string()
      .required("Vendor name is required")
      .min(5, "Vendor name must be at least 5 characters")
      .max(50, "Vendor name can be a maximum of 50 characters"),
    otherwise: yup.string().notRequired(),
  }),
  firstName: yup.string().when("vendorType", {
    is: isIndividualType, // check if vendor type is individual
    then: validateFirstName,
    otherwise: yup.string().notRequired(),
  }),
  lastName: yup.string().when("vendorType", {
    is: isIndividualType, // check if vendor type is individual
    then: validateLastName,
    otherwise: yup.string().notRequired(),
  }),
});

export const inviteIndividualVendor = yup.object({
  email: validateEmail,
});

export const inviteIndividualFromProfile = yup.object({
  email: validateEmail,
  firstName: validateFirstName,
  lastName: validateLastName,
});
