import React from "react";
import { ApolloError } from "@apollo/client";

import Loader from "./Loader";

/**
 * Renders the WithLoader component, which conditionally renders a loader component based on loading and error states.
 *
 * @param {object} props - The props object.
 * @param {boolean} [props.loading] - Optional. Indicates whether loading is in progress.
 * @param {ApolloError} [props.error] - Optional. Error message to display.
 * @param {React.ReactNode} [props.children] - Optional. Child components to be rendered.
 * @returns {React.ReactNode} - The rendered loader component or children.
 */
const WithLoader = ({ loading, error, children }) => {
  return loading || error ? <Loader error={error} /> : children;
};

export default WithLoader;
