import { createColumnHelper } from "@tanstack/react-table";

import Languages from "@lango/common/components/Languages";
import Status from "@lango/common/components/Status";
import { renderDateAndTime } from "@lango/common/helpers";

const columnHelper = createColumnHelper();

const renderLanguagePair = (props) => {
  const value = props?.row?.original;
  return (
    value && (
      <Languages
        languageFrom={value.languageFrom}
        languageTo={value.languageTo}
      />
    )
  );
};

export const jobTableColumns = [
  columnHelper.accessor("id", {
    id: "jobs.id",
    header: "Request #",
  }),
  columnHelper.accessor(row => row.jobType ? row.jobType.name : null, {
    id: "jobs.jobType.name",
    header: "Type",
  }),
  columnHelper.accessor("languagePair", {
    id: "jobs.language",
    cell: (info) => renderLanguagePair(info),
    header: "Language Pair",
  }),
  columnHelper.accessor("createdAt", {
    id: "jobs.created_at",
    cell: (info) => renderDateAndTime(info.getValue()),
    header: "Created",
  }),
  columnHelper.accessor("jobStatus.name", {
    id: "jobs.jobStatus.name",
    cell: (info) => <Status type={info.getValue()} />,
    header: "Status",
  }),
];


export const taskTableColumns = [
  columnHelper.accessor("id", {
    id: "tasks.id",
    header: "Task #",
  }),
  columnHelper.accessor(row => row.taskType ? row.taskType.name : null, {
    id: "tasks.taskType.name",
    header: "Type",
  }),
  columnHelper.accessor("languagePair", {
    id: "tasks.language",
    cell: (info) => renderLanguagePair(info),
    header: "Language Pair",
  }),
  columnHelper.accessor("createdAt", {
    id: "tasks.created_at",
    cell: (info) => renderDateAndTime(info.getValue()),
    header: "Created",
  }),
  columnHelper.accessor("taskStatus.name", {
    id: "tasks.taskStatus.name",
    cell: (info) => <Status type={info.getValue()} />,
    header: "Status",
  }),
];
