import { useReactiveVar } from "@apollo/client";
import React from "react";

import { activeLSPVar } from "@lango/common/apollo/localState";
import { Grid } from "@lango/common/components/Grid";

import { getActiveLSPClasseName, renderLSPInitials } from "./utils";

const LSPsDropdownList = ({ menuItems }) => {
  const activeLSP = useReactiveVar(activeLSPVar);
  return (
    <Grid extraClasses="relative gap-1 bg-white sm:gap-2 sm:p-0">
      {menuItems.map((item) => (
        <button
          key={item.id}
          role="menuitem"
          onClick={item.onClick}
          className={getActiveLSPClasseName(activeLSP?.id === item.id)}
        >
          {renderLSPInitials(item.name)}
          <p className="ml-2 truncate max-w-[11.25rem]">{item.name}</p>
        </button>
      ))}
    </Grid>
  );
};

export default LSPsDropdownList;
