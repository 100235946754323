/**
 * BrowserAgentOptions component sets up and initializes the New Relic Browser Agent
 * with the specified configuration options.
 *
 * The options object contains the necessary configuration for the New Relic Browser Agent,
 * including application ID, license key, beacon URLs, and other settings.
 *
 * The agent loader code executes immediately upon instantiation.
 * 
 * todo: invesigate https://github.com/newrelic/newrelic-browser-agent/blob/main/docs/warning-codes.md#8 
 */

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { Logging } from '@newrelic/browser-agent/features/logging';

import environmentVars from '@lango/common/env';

const newRelicVars = environmentVars.newRelic;

const requiredVars = {
  LICENSE_KEY: newRelicVars.licenseKey,
  AGENT_ID: newRelicVars.agentID,
  ACCOUNT_ID: newRelicVars.accountID,
  APPLICATION_ID: newRelicVars.applicationID,
  TRUST_KEY: newRelicVars.trustKey,
  BEACON: newRelicVars.beacon,
};

const prefix = 'NEW_RELIC_';

const BrowserAgentOptions = () => {
  const missingVars = [];

  for (const [key, value] of Object.entries(requiredVars)) {
    if (!value) {
      missingVars.push(`${prefix}${key}`);
    }
  }

  if (missingVars.length > 0) {
    console.log(`New Relic agent is not configured. Missing variables: ${missingVars.join(', ')}`);
    return null;
  }

  const options = {
    info: {
      applicationID: requiredVars.APPLICATION_ID,
      beacon: requiredVars.BEACON,
      errorBeacon: requiredVars.BEACON,
      licenseKey: requiredVars.LICENSE_KEY,
      sa: 1,
    },
    init: {
      ajax: {
        deny_list: [requiredVars.BEACON],
      },
      distributed_tracing: {
        enabled: true,
      },
      privacy: {
        cookies_enabled: true,
      },
    },
    features: [Logging],
    loader_config: {
      accountID: requiredVars.ACCOUNT_ID,
      agentID: requiredVars.AGENT_ID,
      applicationID: requiredVars.APPLICATION_ID,
      licenseKey: requiredVars.LICENSE_KEY,
      trustKey: requiredVars.TRUST_KEY,
    }
  };

  // The agent loader code executes immediately on instantiation.
  const newRelic = new BrowserAgent(options);

  if (newRelicVars.enableLogs) {
    // wrap the log methods
    newRelic.wrapLogger(console, "log", { level: "INFO" });
    newRelic.wrapLogger(console, "info", { level: "INFO" });
    newRelic.wrapLogger(console, "warn", { level: "WARN" });
    newRelic.wrapLogger(console, "error", { level: "ERROR" });
  }
};

export default BrowserAgentOptions;
