import { useModal } from "@lango/common/context";
import { useBoolean } from "@lango/common/hooks";
import { resendSignUpCode } from "aws-amplify/auth";

import Button from "@lango/common/features/forms/components/Button";
import Flex from "@lango/common/features/tables/components/Flex";

import { toastError, toastSuccess } from "../../alerts/functions/toast";

export const resendCodeModalProps = {
  heading: "Your account is not confirmed",
  headerClasses: "border-b-0 pb-2",
  bodyClasses: "pt-0",
};

export const ResendCode = ({ username }) => {
  const [loading, setLoading, setUnloading] = useBoolean(false);
  const { hideModal } = useModal();

  const handleResendCode = async () => {
    setLoading();
    try {
      await resendSignUpCode({ username });
      toastSuccess("Verification code has been resent.");
      setUnloading();
      hideModal();
    } catch (error) {
      toastError("Error resending code. Please try again.");
      console.error(error);
    }
  };

  return (
    <>
      <p>Would you like to resend the verification link?</p>
      <Flex justify="center" extraClasses="mt-6">
        <Button
          type="button"
          text="Resend"
          disabled={loading}
          showLoader={loading}
          onClick={handleResendCode}
        />
      </Flex>
    </>
  );
};
