import { createContext, useState, useCallback, useContext } from "react";

import { LoginClientInvitation, RegisterClient } from "@lango/common/features";
import { Loader } from "@lango/common/components";
import { LinkClient } from "../features/AddClientAndVendorAdmin/linkOrganization";
import { Step1, Step2, Step3, Step4 } from "../features/clientOnboarding";

export const AcceptClientInviteContext = createContext({
  inviteStep: "",
  verifyResponse: null,
  onboardingData: null,
  redirectMode: false,
  handleChangeInviteStep: (_val) => {},
  handleChangeVerifyResponse: (_val, _step) => {},
  handleChangeOnboardingData: (_val, _step) => {},
});

/**
 * @typedef {Object} AcceptClientInviteProviderProps
 * @property {boolean} [callbackMode=false] - Indicates whether the component is in callback mode.
 * @property {React.ReactNode} children - The child components.
 */

/**
 * @param {AcceptClientInviteProviderProps} props - The component props.
 * @returns {React.ReactNode} The AcceptClientInviteProvider component.
 */
export function AcceptClientInviteProvider({ callbackMode = false, children }) {
  const [inviteStep, setInviteStep] = useState("");
  const [verifyResponse, setVerifyResponse] = useState(null);
  const [onboardingData, setOnboardingData] = useState(null);
  const [redirectMode] = useState(callbackMode);

  const handleChangeInviteStep = useCallback((val) => {
    setInviteStep(val);
  }, []);

  const handleChangeVerifyResponse = useCallback((val, step) => {
    setVerifyResponse(val);
    setInviteStep(step);
  }, []);

  const handleChangeOnboardingData = useCallback((val, step) => {
    setOnboardingData(val);
    setInviteStep(step);
  }, []);

  return (
    <AcceptClientInviteContext.Provider
      value={{
        inviteStep,
        verifyResponse,
        onboardingData,
        redirectMode,
        handleChangeInviteStep,
        handleChangeVerifyResponse,
        handleChangeOnboardingData,
      }}
    >
      <InviteSteps />
      <>{children}</>
    </AcceptClientInviteContext.Provider>
  );
}

/**
 * @type {Object.<string, React.ReactNode>}
 */
const stepComponents = {
  register: <RegisterClient />,
  login: <LoginClientInvitation />,
  link: <LinkClient />,
  step1: <Step1 />,
  step2: <Step2 />,
  step3: <Step3 />,
  step4: <Step4 />,
};

/**
 * @returns {React.ReactNode} The component corresponding to the current inviteStep value.
 */
function InviteSteps() {
  const { inviteStep } = useAcceptClientInviteCtx();

  return (
    <>{stepComponents[inviteStep] || <Loader message="Please wait..." />}</>
  );
}

export function useAcceptClientInviteCtx() {
  const context = useContext(AcceptClientInviteContext);
  if (!context) {
    throw new Error(
      "useAcceptClientInviteCtx must be used within a AcceptClientInviteContext",
    );
  }
  return context;
}
