import { Switch } from "@headlessui/react";
import { useField } from "formik";

import classNames from "@lango/common/classnames";

import Tooltip from "./Tooltip";

const Toggle = ({
  label,
  description,
  extraClasses,
  infotext = null,
  disabled = false,
  onChange,
  containerClasses,
  ...props
}) => {
  const def = {
    name: props.name,
    // If value is not undefined, ensure it is a string.
    value: props.value && `${props.value}`,
    type: "checkbox",
  };
  const [field, meta] = useField(def);

  const toggleClasses = classNames(
    "relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black",
    extraClasses,
    {
      "bg-black": !disabled && field.checked,
      "bg-gray-300": !field.checked,
      "bg-gray-700": disabled && field.checked,
      "cursor-not-allowed": disabled,
      "cursor-pointer": !disabled,
    },
  );

  const labelClasses = classNames("text-sm font-medium", extraClasses, {
    "text-black": !disabled,
    "text-gray-600": disabled,
  });

  const handleChangeToggle = (event) => {
    onChange && onChange(event);
    field.onChange({
      target: {
        checked: event,
        ...def,
      },
    });
  };

  return (
    <Switch.Group
      as="div"
      className={classNames(
        containerClasses,
        "flex items-center justify-between",
      )}
    >
      <Switch
        checked={field.checked}
        onChange={handleChangeToggle}
        className={toggleClasses}
        disabled={disabled}
      >
        <span
          aria-hidden="true"
          className={classNames(
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            {
              "translate-x-5": field.checked,
              "translate-x-0": !field.checked,
            },
          )}
        />
        <input
          type={"checkbox"}
          className={"sr-only absolute inset-0"}
          aria-describedby={props.errorMsgID}
          {...props}
          {...field}
          onChange={undefined}
          readOnly={true}
          disabled={disabled}
        />
      </Switch>

      <span className="ml-3 flex grow flex-col">
        <span className="flex shrink flex-row">
          <Switch.Label as="span" className={labelClasses} passive>
            {label}
          </Switch.Label>
          {infotext ? (
            <span className={"ml-1"}>
              <Tooltip text={infotext} />
            </span>
          ) : null}
        </span>

        {description ? (
          <Switch.Description as="span" className="text-sm text-gray-500">
            {description}
          </Switch.Description>
        ) : null}

        {meta.touched && meta.error ? (
          <span className="h-1 text-sm text-red-600">{meta.error}</span>
        ) : null}
      </span>
    </Switch.Group>
  );
};

export default Toggle;
