import { DotsVerticalIcon } from "@heroicons/react/solid";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

const DraggableColumn = ({
  id,
  index,
  header,
  getIsVisible,
  getToggleVisibilityHandler,
}) => {
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided) => (
        <li
          key={id}
          className="mb-3 flex justify-between overflow-hidden"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <label>
            <input
              className="mr-3 h-5 w-5 rounded text-black focus:ring-0"
              type="checkbox"
              checked={getIsVisible()}
              onChange={getToggleVisibilityHandler()}
            />
            {header}
          </label>
          <span className="cursor-pointer">
            <DotsVerticalIcon className="-mr-2 inline w-4" color="gray" />
            <DotsVerticalIcon className="inline w-4" color="gray" />
          </span>
        </li>
      )}
    </Draggable>
  );
};

export default DraggableColumn;
