import cn from "@lango/common/classnames";
import { selectClasses, selectStyles } from "@lango/common/components/styles";
import InputLabel from "@lango/common/features/forms/components/InputLabel";
import Tooltip from "@lango/common/features/forms/components/Tooltip";
import Flex from "@lango/common/features/tables/components/Flex";
import { useField } from "formik";
import ReactSelect from "react-select";
import CreatableReactSelect from "react-select/creatable";
import { FieldIcon } from "./FieldIcon";

const Select = (selectProps) => {
  const {
    label,
    showLabel,
    className,
    size,
    infoText,
    whiteBackground,
    value,
    options,
    maxDisplayedOptions,
    onChange,
    id,
    isDisabled,
    isLoading,
    useOptionsAsName,
    placeholder,
    rounded,
    border,
    useRing,
    useUnderline,
    isCreatable,
    groupedOptions,
    icon,
    title,
    addOnText,
    ...props
  } = selectProps;
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  // React select expects default value to be a whole object. That's a little brittle.
  // If we recieve a pure string value, find the matching record in the options.
  const extractValue = (v) => {
    if (Array.isArray(v)) {
      if (!v.length) {
        return options.find(({ value }) => !value);
      }
      return v.map(extractValue);
    }
    if (typeof v === "string") {
      return options.find(({ value }) => value == v);
    }
    return v;
  };
  let selectedValue = extractValue(value ?? field?.value);

  const handleChange = (event) => {
    setValue(event);
    onChange && onChange(event);
  };

  const SelectField = isCreatable ? CreatableReactSelect : ReactSelect;

  return (
    <div className={cn("text-left", className, {"cursor-not-allowed": isDisabled})}>
      {showLabel !== false && label ? (
        <InputLabel
          labelFor={props.id || props.name}
          text={label}
          infoText={infoText}
        />
      ) : null}

      <Flex align="center">
        <div className="flex-1" title={title}>
          <div className="relative">
            <SelectField
              id={id}
              options={options}
              isLoading={isLoading}
              styles={{
                ...selectStyles(selectProps),
                ...props.additionalStyles,
              }}
              placeholder={placeholder}
              defaultValue={selectedValue}
              onChange={handleChange}
              value={selectedValue}
              className={selectClasses({ ...selectProps, meta })}
              isDisabled={isDisabled}
              getOptionLabel={props.getOptionLabel}
              {...props}
            />
            <FieldIcon icon={icon} meta={meta} />
            {addOnText && whiteBackground && (
              <div className={`pointer-events-none absolute inset-y-0 flex items-center pr-3 ${icon ? 'right-8' : 'right-0'}`}>
                <span className="text-gray-500 sm:text-sm">{addOnText}</span>
              </div>
            )}
          </div>
        </div>
        {(showLabel === false || !label) && infoText && (
          <Tooltip text={infoText} iconClasses={"mt-1 ml-1"} />
        )}
      </Flex>
      {meta.touched && meta.error ? (
        <p className="ml-3 mt-2 h-1 text-sm text-red-600" id={props.errorMsgID}>
          {meta.error}
        </p>
      ) : null}
    </div>
  );
};

Select.defaultProps = {
  infoText: null,
  whiteBackground: false,
  maxDisplayedOptions: 500,
  options: [],
  value: null,
  id: null,
  isLoading: false,
  useOptionsAsName: false,
  placeholder: "Select...",
  rounded: "rounded-full",
  border: true,
  useRing: true,
  useUnderline: false,
  isCreatable: false,
  isDisabled: false,
  groupedOptions: false,
};

export default Select;
