import {
  jobTypeMetadata,
  ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE,
} from "@lango/common/constants";
import ClientVRISettingsForm from "../forms/clientVRISettings/clientVRIForm";

export const buildInitialValues = (
  allJobTypes,
  serviceLinesLoading,
  savedValues,
) =>
  !serviceLinesLoading && savedValues
    ? {
        selectedJobTypes: allJobTypes?.reduce((acc, jobType) => {
          acc[jobType.value] =
            Array.isArray(savedValues) &&
            savedValues.some((job) => job.value === jobType.value);
          return acc;
        }, {}),
      }
    : {};

// Assemble set of available service lines, merging the DB results with the metadata.
export const buildServiceLines = (allJobTypes, organizationID) =>
  allJobTypes?.map((jobType) => {
    const additionalProps =
      jobType.label === ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE &&
      organizationID > 0
        ? {
            _component: ClientVRISettingsForm,
            component_heading: "Client VRI Settings",
          }
        : {};

    return {
      ...jobType,
      ...jobTypeMetadata[jobType.label],
      ...additionalProps,
    };
  });
