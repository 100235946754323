import { useState } from "react";

import SettingsTabContainer from "@lango/common/features/containers/SettingsTabContainer";
import useModal from "@lango/common/features/modal/useModal";

import { UserTable } from "./UserTable";
import { UpdateUserRoles } from "./userRoles/UpdateUserRoles";
import { AddUser } from "../clientAndVendorAdminList/ClientAndVendorAdminList";
import {RemoveUser} from "./removeUser/RemoveUser";
import { useGetUserList } from "./useGetUserList";

const UserList = ({
  canUpdateOrg,
  enableRowClick = true,
  organizationID,
  activeUserID,
  inviteType,
  profileID = null,
}) => {
  const { Modal, hideModal, showModal, isOpen } = useModal();
  const { Modal: RemoveUserModal, hideModal: hideRemoveUserModal, showModal: showRemoveUserModal, isOpen: isRemoveUserModalOpen } = useModal();
  const [initialValues, setInitialValues] = useState({});
  const UserTableProps = useGetUserList(organizationID);
  return (
    <SettingsTabContainer>
      {canUpdateOrg && (
        <>
          <AddUser inviteType={inviteType} numUsers={1} profileID={profileID} />
          <Modal show={isOpen} onClose={hideModal} destroyOnClose>
            <Modal.Header>Edit User Roles</Modal.Header>
            <Modal.Body>
              <UpdateUserRoles
                initialValues={initialValues}
                hideModal={hideModal}
              />
            </Modal.Body>
          </Modal>

          <RemoveUserModal show={isRemoveUserModalOpen} onClose={hideRemoveUserModal} destroyOnClose>
            <Modal.Header>Remove User</Modal.Header>
            <Modal.Body>
              <RemoveUser initialValues={initialValues} hideModal={hideRemoveUserModal} />
            </Modal.Body>
          </RemoveUserModal>
        </>
      )}
      <UserTable
        showModal={showModal}
        showRemoveUserModal={showRemoveUserModal}
        setInitialValues={setInitialValues}
        enableRowClick={enableRowClick}
        canUpdateOrg={canUpdateOrg}
        userTableProps={UserTableProps}
        activeUserID={activeUserID}
      />
    </SettingsTabContainer>
  );
};

export default UserList;
