import { renderDateAndTime } from "@lango/common/helpers";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const columns = [
  columnHelper.accessor("title", {
    id: "title",
    header: "Title",
  }),
  columnHelper.accessor("description", {
    id: "description",
    header: "Description",
  }),
  columnHelper.accessor("createdAt", {
    id: "createdAt",
    cell: (ctx) => renderDateAndTime(ctx.getValue()),
    header: "Created",
  }),
];
