import { IoIosArrowForward } from "react-icons/io";

import cn from "@lango/common/classnames";
import { Avatar, TimeAgo } from "@lango/common/components";
import { getFirstLastCharacters } from "@lango/common/helpers";

import { ActionButton } from "./ActionButton";

/**
 * Notification Component
 *
 * Displays a single notification item within the table notifications list. This component
 * shows the notification's avatar, title, and description. The title and description
 * change style based on whether the notification has been read or not. It also includes
 * a text button to handle any action related to the notification.
 *
 * @param {Object} props - The props for the OrganizationNotification component.
 * @param {Object} props.notification - The notification object containing details to display.
 * @param {string} props.notification.title - The title of the notification.
 * @param {string} props.notification.description - The description of the notification.
 * @param {string} props.notification.name - The name associated with the notification for the avatar.
 * @param {string} props.notification.createdAt - It is the time when the notification is created.
 * @param {any} props.notification.sentBy - It indicates who sent notification
 * @param {boolean} props.notification.isRead - Indicates if the notification has been read.
 * @param {boolean} props.notification.isUserNotification - Indicates if the notification is related to user notifications.
 * @param {Object} props.notification.notificationType - The type of the notification.
 * @param {string} props.notification.notificationType.name - The name of the notification type.
 * @param {any} [props.onClick] - The function to be called when the notification is clicked.
 * @returns {React.ReactNode} The rendered OrganizationNotification component.
 */
export const Notification = ({ notification, onClick }) => {
  const {
    title,
    description,
    sentBy,
    isRead,
    createdAt,
    isUserNotification,
    notificationType,
  } = notification;
  const readColor = isRead ? "text-gray-400" : "text-black";
  console.log(notification);

  return (
    <div className="transition duration-150 ease-in-out bg-white hover:bg-gray-100">
      <div
        onClick={!(isRead || isUserNotification) ? onClick : null}
        className={cn(
          `flex py-5 px-6 w-full ${!(isRead || isUserNotification) ? `cursor-pointer` : ""}`,
        )}
      >
        <div className="flex-shrink-0 flex items-center justify-center w-16">
          <Avatar
            placeholderInitials={getFirstLastCharacters(sentBy?.name)}
            extraClasses="w-14 h-14 text-xl font-bold border-gray-200 border"
          />
        </div>
        <div className="flex-grow mx-4 flex flex-col justify-center">
          <div>
            <p className={`inline text-base font-bold ${readColor}`}>{title}</p>
            {!isRead && (
              <span className="bg-orange-badge inline-block rounded px-3 py-1 ml-2 text-xs font-bold leading-none text-white">
                NEW
              </span>
            )}
          </div>
          <p className={`text-sm font-normal mt-1 ${readColor}`}>
            {description}
          </p>
        </div>
        <div className="flex-shrink-0 w-28 flex flex-col items-end justify-between gap-4">
          <TimeAgo date={createdAt} extraClasses={readColor} />
          {isUserNotification ? (
            !isRead && (
              <ActionButton
                onClick={onClick}
                notificationType={notificationType?.name}
              />
            )
          ) : (
            <IoIosArrowForward size="28px" className={readColor} />
          )}
        </div>
      </div>
      <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
    </div>
  );
};
