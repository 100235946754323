import { useQuery, useReactiveVar } from "@apollo/client";

import { activeOrgVar } from "@lango/common/apollo/localState";
import { ON_DEMAND_JOB_ENABLED } from "@lango/common/queries";

const useOnDemandJobEnabled = () => {
  const activeOrg = useReactiveVar(activeOrgVar);
  const { loading, data, error } = useQuery(ON_DEMAND_JOB_ENABLED, {
    skip: !activeOrg,
    variables: { vendorID: activeOrg?.id },
  });

  return { loading, error, enabled: data?.isOnDemandJobEnabled };
};

export default useOnDemandJobEnabled;
