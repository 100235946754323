import { useNavigate } from "react-router-dom";

import { NOT_AUTHORIZED } from "@lango/common/constants/errors";
import { toastError } from "@lango/common/features/alerts/functions/toast";

export const useJobDetailsError = () => {
  const navigate = useNavigate();

  const handleJobDetailsError = (error) => {
    if (error?.message === NOT_AUTHORIZED) {
      toastError("you are not authorized to view this request", {
        toastId: NOT_AUTHORIZED,
      });
    } else {
      toastError("Error getting request details");
    }
    navigate("/requests");
  };

  return { handleJobDetailsError };
};
