import classNames from "@lango/common/classnames";

const CheckboxLabel = ({ labelFor, text, extraClasses, size }) => {
  let labelClass = classNames("block font-medium", extraClasses, {
    "text-sm": size === "small",
    "text-base": size !== "small",
  });

  return (
    <label htmlFor={labelFor} className={labelClass}>
      {text}
    </label>
  );
};

export default CheckboxLabel;
