import classNames from "@lango/common/classnames";
import Avatar from "@lango/common/components/Avatar";
import { getFirstLastCharacters } from "@lango/common/helpers";

export const getActiveLSPClasseName = (isActiveLSP) =>
  classNames(
    "px-4 py-3 flex items-center text-sm font-medium transition ease-in-out duration-150",
    {
      "bg-black text-white": isActiveLSP,
      "hover:bg-lango-light-gray": !isActiveLSP,
    }
  );

export const renderLSPInitials = (name = "") => {
  const iconClasses = classNames("font-small text-xs text-white");
  return (
    <Avatar
      placeholderInitials={getFirstLastCharacters(name)}
      extraClasses="w-6 h-6 bg-lango-primary"
      iconClasses={iconClasses}
    />
  );
};
