import { useQuery, useReactiveVar } from "@apollo/client";

import { activeOrgVar } from "@lango/common/apollo/localState";

import { CAN_UPDATE_ORG } from "./queries";

const useCanUpdateOrg = () => {
  const activeOrg = useReactiveVar(activeOrgVar);
  const { data, loading, error } = useQuery(CAN_UPDATE_ORG, {
    variables: { organizationID: activeOrg?.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  return { canUpdateOrg: data?.canUpdateOrganization ?? false, loading, error };
};

export default useCanUpdateOrg;
