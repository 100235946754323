import { useHasOrganizations } from "../hooks/useHasOrganizations";

// This is used to hide a component if the user is not a member of any organizations
export default function withOrganizations(WrappedComponent) {
  const WithOrganizations = (props) => {
    const { hasOrganizations } = useHasOrganizations();
    return hasOrganizations && <WrappedComponent {...props} />;
  }
  return WithOrganizations;
}
