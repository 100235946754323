import { toastSuccess } from "@lango/common/features";
import InputLabel from "@lango/common/features/forms/components/InputLabel";
import { CopyInput } from "./CopyInput";

export function handleCopy(value, message) {
  const number = value.replace(/[^\d]/g, "");
  navigator.clipboard.writeText(number);
  toastSuccess(message);
}

export const ContactCodeDisplay = ({ label, value, message }) => {
  return (
    <div>
      <InputLabel text={label} />
      <CopyInput value={value} valueToCopy={value} />
    </div>
  );
};
