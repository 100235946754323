import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  getLoginPath,
  isAppWithCustomLogout,
  logout,
  logoutSlugVar,
} from "@lango/common/apollo/localState";
import { signOutFromAuth0 } from "@lango/common/auth0";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import useQueryParams from "@lango/common/hooks/useQueryParams";

export const Logout = () => {
  const navigate = useNavigate();

  const org = isAppWithCustomLogout() && logoutSlugVar();
  const { getQueryParams } = useQueryParams();
  const from = getQueryParams("from");
  const loginPath = getLoginPath(from, org);

  const handleLogout = async () => {
    try {
      const useAuth0 = await logout();
      if (useAuth0) {
        signOutFromAuth0();
      } else {
        navigate(loginPath);
      }
    } catch (err) {
      console.error(err);
      toastError("We're having trouble logging you out");
      navigate(loginPath);
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  // Do we have a loading / waiting spinner?
  return null;
};
