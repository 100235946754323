import classNames from "@lango/common/classnames";

/**
 * Renders a dropdown triangle component.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.position] - The position of the dropdown triangle. Possible values are "left", "center", or "right".
 * @param {string} [props.positionClasses] -
 * @returns {JSX.Element} The rendered dropdown triangle component.
 */
const DropdownTriangle = ({ position, positionClasses }) => {
  return (
    <div
      className={classNames(
        "flex overflow-hidden",
        {
          "justify-start pl-8": position === "left",
          "justify-center": position === "center",
          "justify-end pr-8": position === "right",
        },
        positionClasses,
      )}
    >
      <div className="h-4 w-4 origin-bottom-left rotate-45 transform bg-white shadow-lg ring-1 ring-black/5" />
    </div>
  );
};

export default DropdownTriangle;
