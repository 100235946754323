import { FolderIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import withOrganizations from "@lango/common/features/auth/components/withOrganizations";

const style =
  "hover:border-b-2 flex flex-wrap gap-2 items-center border-b-2 border-transparent pr-3 hover:border-black";

const HeaderLink = ({ to, name }) => {
  return (
    <Link to={to} className={style}>
      <FolderIcon className="h-6 w-6 hidden sm:block" />
      <span className="text-sm md:text-base">{name}</span>
    </Link>
  );
}

export default withOrganizations(HeaderLink);
