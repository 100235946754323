import { PhoneIcon } from "@heroicons/react/solid";

import envs from "@lango/common/env";
import FormControl from "@lango/common/features/forms/components/FormControl";

import useOnDemandJobEnabled from "./isOnDemandJobEnabled";
import useUpdateCCPermissions from "./updateCCPermissions";
import withOrganizations from "@lango/common/features/auth/components/withOrganizations";

const { twilioSSOUrl } = envs;

function handleRedirectToFlex() {
  if (!twilioSSOUrl) {
    console.error("Could not find twilioSSOUrl");
    return;
  }
  window.open(twilioSSOUrl, "_blank");
}

const OPIVRI = () => {
  const { enabled } = useOnDemandJobEnabled();
  const { handleUpdateCCPermissions, loading } = useUpdateCCPermissions();

  const launchContactCenter = async () => {
    envs.featureFlags.updatePermissionsOnLaunchFlex && (await handleUpdateCCPermissions());
    handleRedirectToFlex();
  };

  return (
    enabled && (
      <FormControl
        control="button"
        type="button"
        text="OPI/VRI"
        extraClasses="px-3"
        icon={<PhoneIcon className="w-4" />}
        onClick={launchContactCenter}
        showLoader={loading}
        disabled={loading}
      />
    )
  );
};

export default withOrganizations(OPIVRI);
