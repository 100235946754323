import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";

import cx from "@lango/common/classnames";
import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";

import Button from "./Button";

/**
 * Button component that represents a back button with an arrow icon and optional custom styling.
 *
 * @param {Object} props - The props for the BackButton component.
 * @param {function} [props.onClick] - The function to be called when the button is clicked.
 * @param {string} [props.className] - Additional CSS classes to apply to the button.
 * @returns {React.ReactNode} The rendered BackButton component.
 */
const BackButton = ({ onClick, className }) => (
  <Button
    type="button"
    onClick={onClick}
    icon={<ArrowNarrowLeftIcon className="inline-block w-5" />}
    className={cx("flex underline", className)}
  >
    <BasePageHeading heading="Back" />
  </Button>
);

BackButton.defaultProps = {
  onClick: () => {},
  className: "",
};

export default BackButton;
