import { gql } from "@apollo/client";

export const JOB_TYPE = gql`
  fragment JobType on JobType {
    id
    name
  }
`;

export const JOB_STATUS = gql`
  fragment JobStatus on JobStatus {
    id
    name
  }
`;

export const JOB_DETAILS = gql`
  ${JOB_TYPE}
  ${JOB_STATUS}
  fragment JobTypeAndStatus on Job {
    jobType {
      ...JobType
    }
    jobStatus {
      ...JobStatus
    }
  }
`;

export const JOB_LABELS = gql`
  fragment JobLabelFields on Job {
    labels {
      id
      label {
        id
        name
      }
    }
  }
`;

export const LANGUAGE = gql`
  fragment BasicLanguageFields on Language {
    id
    name: displayName
    isoCodeLong
  }
`;

export const LANGUAGE_FIELDS = gql`
  ${LANGUAGE}
  fragment LanguagesFields on Job {
    languageFrom {
      ...BasicLanguageFields
    }
    languageTo {
      ...BasicLanguageFields
    }
  }
`;

export const JOB_TYPE_AND_LANGUAGES = gql`
  ${JOB_TYPE}
  ${LANGUAGE_FIELDS}
  fragment jobDetailsAndLanguages on Job {
    jobType {
      ...JobType
    }
    ...LanguagesFields
  }
`;

export const JOB_FORM_DATA = gql`
  ${JOB_LABELS}
  ${JOB_DETAILS}
  fragment jobFormData on Job {
    ...JobTypeAndStatus
    ...JobLabelFields
  }
`;

export const PAGINATED_JOB_PROJECTS = gql`
  ${JOB_DETAILS}
  ${JOB_LABELS}
  ${LANGUAGE}
  ${LANGUAGE_FIELDS}
  fragment paginatedJobProjects on Job {
    ...JobTypeAndStatus
    ...JobLabelFields
    ...LanguagesFields
    sourceLanguages {
      ...BasicLanguageFields
    }
  }
`;

export const JOB_USER = gql`
  fragment jobUser on JobUser {
    id
    firstName
    lastName
  }
`;
