import React from "react";

import Tooltip from "@lango/common/features/forms/components/Tooltip";

const InputLabel = ({ labelFor, text, infoText = null }) => {
  return (
    <label
      htmlFor={labelFor}
      className="mb-4 flex grow flex-col text-base font-medium"
    >
      <span className="flex shrink flex-row items-center">
        {text}
        {infoText !== null && (
          <span className="ml-1">
            <Tooltip text={infoText} />
          </span>
        )}
      </span>
    </label>
  );
};

export default InputLabel;

/**
 * @param {import("formik").FieldMetaProps} meta
 */
export function renderError(meta) {
  return (
    <p className="ml-3 mt-2 h-1 text-sm text-red-600">
      {meta.touched && meta.error ? meta.error : null}
    </p>
  );
}
