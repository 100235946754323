import { Notification } from "@lango/common/features/notifications/list/Notification";
import Empty from "@lango/common/features/tables/components/Empty";
import Pagination from "@lango/common/features/tables/components/Pagination";

import { useMarkNotificationAsRead } from "@lango/common/hooks";
import { useNavigate } from "react-router-dom";
import WithNotificationsLoader from "./WithNotificationsLoader";

const UserNotifications = ({
  notifications,
  loading,
  error,
  paginationProps,
  totalRecords,
}) => {
  const { handleMarkNotificationAsRead } = useMarkNotificationAsRead();
  const navigate = useNavigate();
  const onNotificationRead = (notification) => {
    if (notification?.link) {
      navigate(notification.link);
    }
  };
  const handleNotificationClick = (notification) => {
    handleMarkNotificationAsRead(notification?.id, () =>
      onNotificationRead(notification),
    );
  };
  return (
    <>
      <WithNotificationsLoader
        loading={loading}
        error={error}
        pageLimit={paginationProps?.pageLimit}
      >
        <div className="overflow-hidden rounded-lg bg-gray-200  shadow sm:grid sm:gap-px">
          {notifications.map((notification, key) => (
            <Notification
              key={key}
              notification={notification}
              onClick={() => handleNotificationClick(notification)}
            />
          ))}
        </div>
      </WithNotificationsLoader>
      {!loading && !error && !notifications.length && (
        <Empty emptyMessage={"No notifications to display"} />
      )}
      {totalRecords !== 0 && (
        <Pagination {...paginationProps} totalRecords={totalRecords} />
      )}
    </>
  );
};

export default UserNotifications;
