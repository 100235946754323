import React from "react";
import { useNavigate } from "react-router-dom";

import cx from "@lango/common/classnames";
import BackButton from "@lango/common/features/forms/components/BackButton";
import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";

const JobDetailsHeader = ({ jobID, extraClasses, heading = "Request" }) => {
  const navigate = useNavigate();

  return (
    <div className={cx("flex items-center md:text-2xl", extraClasses)}>
      <BackButton onClick={() => navigate(-1)} />
      <BasePageHeading extraClasses="ml-2" heading={`/ ${heading} #${jobID}`} />
    </div>
  );
};

export default JobDetailsHeader;
