import React from "react";
import { UserGroupIcon } from "@heroicons/react/solid";
import Flex from "@lango/common/features/tables/components/Flex";

const LangoCrowdSubtitle = () => {
  return (
    <Flex align="center" extraClasses="text-lango-green">
      <UserGroupIcon className="size-6" />
      <p className="font-bold">&nbsp;Lango Crowd member</p>
    </Flex>
  );
};

export default LangoCrowdSubtitle;
