import { useState } from "react";

/**
 * A component that lazily loads an image.
 * This prevents the alt text  from showing before the image is actually loaded.
 * @param {Object} props - The component props.
 * @param {string} props.src - The image source URL.
 * @param {string} props.alt - The image alt text.
 * @returns {JSX.Element} The rendered LazyImage component.
 */
export const LazyImage = ({ src, alt, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <img
      src={src}
      alt={isLoaded ? alt : ""}
      onLoad={() => setIsLoaded(true)}
      onError={() => setIsLoaded(true)}
      {...props}
    />
  );
};
