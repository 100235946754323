/**
 * VendorHTJobDetails is a React component that displays the details of a specific vendor job of type HT (Human Translation).
 *
 * It fetches the job details for a given job ID, which it gets from the route parameters.
 * Once the data has been successfully fetched, it displays the job details in a table and the related tasks.
 *
 * @module VendorHTJobDetails
 */

import React from "react";
import { useParams } from "react-router-dom";

import jobTasksContext from "./jobTasksContext";

import { useFetchVendorHTJob, useFetchVendorHTJobTasks} from "@lango/common/hooks";
import PlainTable from "@lango/common/features/tables/components/PlainTable";

import { htJobColumns } from "./columns";
import TaskDetails from "./taskDetails";

const VendorHTJobDetails = () => {
  const { jobID } = useParams();
  const { job } = useFetchVendorHTJob(jobID);
  const {data, fetchData} = useFetchVendorHTJobTasks(jobID);

    return (
      <jobTasksContext.Provider value={{data, fetchData}}>
        <PlainTable extraClasses="my-8" columns={htJobColumns} data={[job]} />
        <TaskDetails jobID={jobID} />
      </jobTasksContext.Provider>
    )
}

export default VendorHTJobDetails;