import { Field, useField } from "formik";

import classNames from "@lango/common/classnames";

import CheckboxLabel from "./CheckboxLabel";

const Checkbox = ({
  label,
  description,
  extraClasses,
  containerExtraClasses,
  size,
  ...props
}) => {
  const [meta] = useField(props);
  let btnClass = classNames(
    "text-black focus:ring-gray-700 border-gray-300 rounded cursor-pointer",
    extraClasses,
    {
      "text-sm": size === "small",
      "text-base": size !== "small",
    },
    {
      "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
        meta.touched && meta.error,
    }
  );
  const containerClass = classNames("flex items-start", containerExtraClasses);

  return (
    <div className={containerClass}>
      <div className="flex h-5 items-center">
        <Field
          type="checkbox"
          className={btnClass}
          aria-describedby={props.errorMsgID}
          {...props}
        />
      </div>
      <div className="ml-3 text-xs">
        <CheckboxLabel
          labelFor={props.id || props.name}
          text={label}
          size={"small"}
        />
        {description ? <p className="text-gray-500">{description}</p> : null}
        {meta.touched && meta.error ? (
          <span className="h-1 text-sm text-red-600" id={props.emailErrorID}>
            {meta.error}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default Checkbox;
