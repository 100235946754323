import { WithLoader } from "@lango/common/components";
import { ORG_FORM_HEADING } from "@lango/common/constants";
import LoginForm from "@lango/common/features/auth/containers/Login/LoginForm";
import LoginPageTitle from "@lango/common/features/auth/containers/Login/LoginPageTitle";
import { useValidateOrgCustomLoginSupport } from "@lango/common/features/auth/hooks";
import { useVendorLogin } from "@lango/common/hooks";

export const VendorLogin = () => {
  const { loading, error, orgInfo } = useValidateOrgCustomLoginSupport();
  const { handleVendorLogin } = useVendorLogin();
  return (
    <WithLoader loading={loading} error={error}>
      <LoginPageTitle orgInfo={orgInfo} />
      <LoginForm
        heading={orgInfo ? ORG_FORM_HEADING : "Sign In"}
        onSubmit={handleVendorLogin}
      />
    </WithLoader>
  );
};
