import { useField } from "formik";
import { useMutation } from "@apollo/client";

import { UPLOAD_VENDOR_FILE } from "@lango/common/queries";
import { toastError } from "@lango/common/features";

import { useAcceptVendorInviteCtx } from "@lango/common/context";

export const useUploadVendorFile = () => {
  const { onboardingData } = useAcceptVendorInviteCtx();
  const [, , { setValue }] = useField("taxFormID");

  const [uploadVendorFile, { loading, error }] =
    useMutation(UPLOAD_VENDOR_FILE);

  function handleUploadVendorFile(files) {
    uploadVendorFile({
      variables: {
        lspID: onboardingData?.lspID,
        organizationID: onboardingData?.organizationID,
        files: files,
      },
    })
      .then(({ data }) => {
        setValue(data?.vendorFileUpload?.[0]?.id);
      })
      .catch((error) => {
        toastError("There was an error uploading the file.");
        console.error(error);
      });
  }

  return { handleUploadVendorFile, loading, error };
};
