import { Tooltip } from "flowbite-react";
import React from "react";
import Moment from "react-moment";

import ReactTimeAgo from "react-time-ago";

import { preferredTimezoneVar } from "../apollo";
import environmentVars from "../env";

export const TimeAgo = ({ date, extraClasses }) => {
  return (
    <ReactTimeAgo
      date={Date.parse(date)}
      locale="en-US"
      timeStyle="round-minute"
      className={extraClasses}
      wrapperComponent={({ children }) => {
        return (
          <Tooltip
            style="light"
            content={
              date && (
                <div className="w-40 whitespace-normal">
                  <Moment
                    format={environmentVars.dateTimeFormat}
                    tz={preferredTimezoneVar()}
                  >
                    {date}
                  </Moment>
                </div>
              )
            }
          >
            {children}
          </Tooltip>
        );
      }}
      tooltip={false}
    />
  );
};

export default TimeAgo;
