import { useState } from "react";

const PAGE_LIMITS = [5, 10, 20, 30];

export const usePagination = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    currentPage: 1,
    pageLimit: 5,
  });

  const setOffset = (value) => setPagination({ ...pagination, offset: value });

  const setPageLimit = (value) =>
    setPagination({ ...pagination, pageLimit: value });

  const setCurrentPage = (value) =>
    setPagination({ ...pagination, currentPage: value });

  const onPageChanged = ({ page, limit }) => {
    setPagination({
      ...pagination,
      offset: (page - 1) * limit,
      pageLimit: limit,
      currentPage: page,
    });
  };

  const paginationProps = {
    pageNeighbors: 1,
    pageLimits: PAGE_LIMITS,
    pageLimit: pagination.pageLimit,
    currentPage: pagination.currentPage,
    onPageChanged,
  };

  return {
    pagination,
    setPagination,
    setOffset,
    setPageLimit,
    setCurrentPage,
    onPageChanged,
    paginationProps,
  };
};
