/**
 * This file contains the implementation of the onboarding step 3 component.
 * It includes two sub-components: AgencyStep3 and IndividualStep3.
 * The Step3 component renders either the AgencyStep3 or IndividualStep3 based on the vendor type.
 * The AgencyStep3 component is used for agency onboarding, while the IndividualStep3 component is used for linguist onboarding.
 * Both components use Formik for form handling and include a form with a heading, description, and action buttons.
 * The AgencyStep3 component also includes a UserList component for displaying a list of users.
 * The IndividualStep3 component includes a SettingsTabContainer component and a VendorProfileLanguage component for language settings.
 * The Step3 component is exported as the default component.
 */

import { Form, Formik } from "formik";
import { Box } from "@lango/common/components";
import UserList from "@lango/common/components/userList/UserList";
import { useAcceptVendorInviteCtx } from "@lango/common/context";
import { VendorProfileLanguage } from "@lango/common/features";
import SettingsTabContainer from "@lango/common/features/containers/SettingsTabContainer";
import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";
import { Action } from "@lango/common/features/onboarding";
import {
  useFetchVendorOnboardingStatus,
  useVendorOnboardSteps,
} from "@lango/common/hooks";
import InfoBars from "../progressBar";
import { INVITATION_TYPES } from "@lango/common/constants/invitationTypes";

/**
 * Renders the AgencyStep3 component for agency onboarding.
 * Includes a form with a heading, description, UserList component, and action buttons.
 */
function AgencyStep3() {
  const { handleOnboardingStep3 } = useVendorOnboardSteps();
  const { onboardingData } = useAcceptVendorInviteCtx();
  const { status } = useFetchVendorOnboardingStatus();
  const { colorOne, colorTwo } = thirdStepColours(status?.currentStep);

  return (
    <Formik initialValues={{}} onSubmit={handleOnboardingStep3}>
      <Form>
        <BasePageHeading
          heading="Agency onboarding"
          description="Step 3 of 3"
        />
        <InfoBars
          status={status?.currentStep}
          step2={"Billing Contact"}
          step3={"Users"}
          colorOne={colorOne}
          colorTwo={colorTwo}
        />
        <Box extraClasses="mt-8">
          <UserList
            canUpdateOrg
            enableRowClick={false}
            organizationID={onboardingData?.organizationID}
            activeUserID={0}
            inviteType={INVITATION_TYPES.ORGANIZATION}
          />
          <Action />
        </Box>
      </Form>
    </Formik>
  );
}

/**
 * Renders the IndividualStep3 component for linguist onboarding.
 * Includes a form with a heading, description, SettingsTabContainer, VendorProfileLanguage component, and action buttons.
 */
function IndividualStep3() {
  const { onboardingData } = useAcceptVendorInviteCtx();
  const { handleOnboardingStep3 } = useVendorOnboardSteps();

  const { status } = useFetchVendorOnboardingStatus();
  const { colorOne, colorTwo } = thirdStepColours(status?.currentStep);
  return (
    <Formik initialValues={{}} onSubmit={handleOnboardingStep3}>
      <Form>
        <BasePageHeading
          heading="Linguist onboarding"
          description="Step 3 of 3"
        />
        <InfoBars
          status={status?.currentStep}
          step2={"Service Lines"}
          step3={"Language Pairs"}
          colorOne={colorOne}
          colorTwo={colorTwo}
        />
        <SettingsTabContainer className="mt-8">
          <VendorProfileLanguage
            organizationID={onboardingData?.organizationID}
            lspID={onboardingData?.lspID}
            canUpdateOrg
          />
        </SettingsTabContainer>
        <Action />
      </Form>
    </Formik>
  );
}

/**
 * Renders the Step3 component.
 * Determines whether to render the AgencyStep3 or IndividualStep3 component based on the vendor type.
 * Uses the useAcceptVendorInviteCtx and useVendorOnboardSteps hooks for context and functionality.
 */
export default function Step3() {
  const { isIndividual } = useAcceptVendorInviteCtx();
  return isIndividual() ? <IndividualStep3 /> : <AgencyStep3 />;
}

function thirdStepColours(currentStep) {
  if (currentStep === "step1" || currentStep === "started") {
    return { colorOne: "white", colorTwo: "white" };
  } else if (currentStep === "step2") {
    return { colorOne: "black", colorTwo: "black" };
  }
  return { colorOne: "white", colorTwo: "white" }; // default case for other steps
}
