import { Formik } from "formik";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { signIn, signOut } from "aws-amplify/auth";

import { isVendorApp } from "@lango/common/env";
import { loginValidationSchema } from "@lango/common/validations";
import { toastError } from "@lango/common/features";
import { signInWithAuth0 } from "@lango/common/auth0";
import { vendorInvitationInitialValues } from "@lango/common/initialValues";
import { useAcceptVendorInviteCtx, useModal } from "@lango/common/context";
import { InviteLoginForm } from "./fields";
import { ResendCode, resendCodeModalProps } from "../../auth/components/ResendCode";

const LoginInvitationForm = () => {
  const { showModal } = useModal();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { verifyResponse, handleChangeInviteStep } = useAcceptVendorInviteCtx();

  async function handleLogin(
    /** @type {any} */ values,
    /** @type {import("formik").FormikHelpers} */ helpers,
  ) {
    const { username, password } = values;

    function onError(error) {
      toastError(error?.message || error);
      helpers?.setFieldValue("disableFields", false);
      console.error(error);
    }

    try {
      helpers?.setFieldValue("disableFields", true);
      authStatus === "authenticated" && (await signOut());
      // todo: use common hook for this
      const { nextStep } = await signIn({ username, password });
      if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        showModal(ResendCode, { ...resendCodeModalProps, username });
        helpers?.setFieldValue("disableFields", false);
        return;
      }
      isVendorApp
        ? signInWithAuth0(username, password, JSON.stringify(values), onError)
        : handleChangeInviteStep("link");
    } catch (error) {
      onError(error);
    }
  }

  return (
    <Formik
      validationSchema={loginValidationSchema}
      initialValues={vendorInvitationInitialValues(verifyResponse)}
      onSubmit={handleLogin}
    >
      <InviteLoginForm />
    </Formik>
  );
};
export default LoginInvitationForm;
