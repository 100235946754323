import React from "react";

const LoginCard = ({ children }) => {
  return (
    <div className="container mx-auto max-w-md">
      <div className="container rounded-xl bg-white p-8 shadow-md">
        {children}
      </div>
    </div>
  );
};

export default LoginCard;
