import { fetchAuthSession } from "aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { redirect, useLocation, useNavigate } from "react-router-dom";

import {
  getLogoutPath,
  logout,
  triggerLogout,
} from "@lango/common/apollo/localState";
import envs from "@lango/common/env";
import { toastWarning } from "@lango/common/features/alerts/functions/toast";
import { AUTH_ROUTES } from "@lango/common/routes/consts";

import withAuthenticated from "./withAuthenticated";

const autoLogoutToast = () =>
  toastWarning("You have been signed out due to inactivity.", {
    toastId: "signoutInactive",
    triggerAtURL: AUTH_ROUTES.LOGIN,
    autoClose: false,
  });

const msPerMinute = 1000 * 60;

const AutoLogout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [keepAliveActive, setKeepAliveActive] = useState(true);
  const idleTimeoutMinutes = envs.idleTimeoutMinutes;
  const sessionKeepalive = envs.sessionKeepaliveMinutes;

  useEffect(() => {
    const interval = setInterval(() => {
      if (keepAliveActive) {
        fetchAuthSession().catch((err) => {
          console.error(err);
          handleOnIdle();
        });
      }
    }, msPerMinute * sessionKeepalive);
    return () => clearInterval(interval);
  }, [keepAliveActive, sessionKeepalive]);

  const handleOnIdle = () => {
    setKeepAliveActive(false);
    autoLogoutToast();
    navigate(getLogoutPath(location.pathname));
  };

  const handleOnActive = () => {
    setKeepAliveActive(true);
  };

  useIdleTimer({
    timeout: msPerMinute * idleTimeoutMinutes,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  return <></>;
};

export default withAuthenticated(AutoLogout);
