import { Formik } from "formik";

import WithLoader from "@lango/common/components/WithLoader";
import SettingsTabContainer from "@lango/common/features/containers/SettingsTabContainer";

import LinguistProfileFields from "./LinguistProfileFields";
import { useFetchLinguistProfile } from "@lango/common/hooks";
import useUpdateLinguistProfile from "./updateLinguistProfile";
import { useReactiveVar } from "@apollo/client";
import { activeOrgVar } from "@lango/common/apollo";

const LinguistProfile = () => {
  const { id } = useReactiveVar(activeOrgVar);
  const { values, ...loadingProps } = useFetchLinguistProfile(id);
  const { handleUpdateLinguistProfile } = useUpdateLinguistProfile();

  return (
    <SettingsTabContainer>
      <WithLoader {...loadingProps}>
        <Formik initialValues={values} onSubmit={handleUpdateLinguistProfile}>
          <LinguistProfileFields />
        </Formik>
      </WithLoader>
    </SettingsTabContainer>
  );
};

export default LinguistProfile;
