import { gql } from "@apollo/client";

export const FIND_OR_REGISTER_USER = gql`
  mutation findOrRegisterUser($input: FindOrRegisterUserRequest!) {
    findOrRegisterUser(input: $input) {
      id
      email
      firstName
      lastName
      organizations {
        id
        name
      }
      notifications
      preferredTimezone
      gender {
        value: id
        label: name
      }
      createdAt
      pendingInvitations {
        profileName
        invitationCode
        invitationType
      }
      roles {
        id
        name
      }
    }
  }
`;
