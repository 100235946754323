import { Amplify } from "aws-amplify";

import fullUrl from "@lango/common/components/FullUrl";

// todo: use common environment vars after LGO-848
const updateAWSConfig = (config) => ({
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: !!process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_URL
      ? process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_URL
      : fullUrl("/auth/sso"),
    redirectSignOut: !!process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT_URL
      ? process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT_URL
      : fullUrl("/auth/sso/logout"),
  },
});

export const configureAmplify = (config) => {
  const updatedAWSConfig = updateAWSConfig(config);
  Amplify.configure(updatedAWSConfig);
  return updatedAWSConfig;
};
