import { createColumnHelper } from "@tanstack/react-table";

import VendorProfileLanguageRowActions from "./languagePairRowActions";
import Status from "@lango/common/components/Status";
import { TrimmedText } from "@lango/common/components";

const columnHelper = createColumnHelper();

export const columns = ({ canUpdateOrg, organizationID }) => [
  columnHelper.accessor("languageFrom.label", {
    header: "Source Language",
    enableSorting: false,
  }),
  columnHelper.accessor("languageTo.label", {
    header: "Target Language",
    enableSorting: false,
  }),

  columnHelper.accessor("jobType.label", {
    header: "Job Type",
    enableSorting: false,
  }),

  columnHelper.accessor("industry.label", {
    header: "Industry",
    enableSorting: false,
  }),

  columnHelper.accessor("qualificationStatus", {
    header: "Approval",
    cell: (info) => renderQualificationStatus(info.getValue()),
    enableSorting: false,
  }),

  ...(canUpdateOrg
    ? [
        columnHelper.accessor("", {
          cell: ({ row }) =>
            row?.original && (
              <VendorProfileLanguageRowActions
                row={row.original}
                organizationID={organizationID}
              />
            ),
          header: "Action",
          enableSorting: false,
        }),
      ]
    : []),
];


const renderQualificationStatus = (status) => {
  return status && <Status type={status} />;
};
