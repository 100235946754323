import useBoolean from "@lango/common/hooks/useBoolean";

import Modal from "./modal";

const useModal = () => {
  const [isOpen, showModal, hideModal] = useBoolean(false);

  return { Modal, isOpen, showModal, hideModal };
};

export default useModal;
