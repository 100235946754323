import { useField, useFormikContext } from "formik";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import cn from "@lango/common/classnames";

import InputLabel, { renderError } from "./InputLabel";

/**
 * @param {import("formik").FieldMetaProps} meta
 */
function prepareClasses(meta) {
  return cn(
    "labeled-phone-input rounded-full border border-gray-200 bg-gray-100 py-1 px-5",
    {
      "border-red-300": meta.touched && meta.error,
    },
  );
}

export default function PhoneNumberInput({
  name,
  disabled,
  placeholder,
  label,
  infoText,
}) {
  const [field, meta, helpers] = useField(name);
  const isSubmitting = useFormikContext().isSubmitting;

  return (
    <div className="text-left">
      {label && <InputLabel labelFor={name} text={label} infoText={infoText} />}
      <PhoneInput
        {...field}
        value={field.value}
        onChange={(value) => helpers.setValue(value)}
        className={prepareClasses(meta)}
        defaultCountry="US"
        disabled={isSubmitting || disabled}
        placeholder={placeholder}
        international
      />
      {renderError(meta)}
    </div>
  );
}
