import { useLazyQuery } from "@apollo/client";

import envs from "@lango/common/env";
import { extractEmailDomain } from "@lango/common/helpers";
import { SSO_USER_CHECK } from "@lango/common/queries";

const config = {
  context: { clientName: "public" },
  notifyOnNetworkStatusChange: true,
};

export const useCheckSSO = () => {
  const [checkSSO] = useLazyQuery(SSO_USER_CHECK, config);

  const handleCheckSSO = async (username, organizationID = null) => {
    try {
      const res = await checkSSO({
        variables: {
          emailDomain: extractEmailDomain(username),
          applicationName: envs?.appName,
          organizationID,
        },
        fetchPolicy: "no-cache",
      });

      const ssoCheck = res?.data?.ssoCheck;
      return {
        isEnabled: ssoCheck?.ssoUser,
        ssoProviderName: ssoCheck?.ssoProviderName,
        ssoProviderOrganizationID: ssoCheck?.ssoProviderOrganizationID,
      };
    } catch (error) {
      return {
        isEnabled: false,
        ssoProviderName: null,
        ssoProviderOrganizationID: null,
      };
    }
  };

  return { handleCheckSSO };
};
