import React from "react";

import { Box } from "@lango/common/components";
import { Flex, FormControl } from "@lango/common/features";
import { Form, useFormikContext } from "formik";
import LoginCard from "../../auth/containers/LoginCard";

const RegisterUserFields = () => {
  const isSubmitting = useFormikContext().isSubmitting;
  return (
    <Box spaceY={6}>
      <Flex extraClasses="gap-5">
        <FormControl
          control="input"
          label="First Name"
          placeholder="Enter first name"
          name="firstName"
          readOnly
        />
        <FormControl
          control="input"
          label="Last Name"
          placeholder="Enter last name"
          name="lastName"
          readOnly
        />
      </Flex>
      <FormControl
        control="input"
        label="Email"
        placeholder="Enter email"
        name="email"
        readOnly
      />
      <FormControl
        control="input"
        name="password"
        label="Password"
        type="password"
        placeholder="Enter password"
      />
      <FormControl
        control="input"
        name="confirmPassword"
        type="password"
        label="Confirm Password"
        placeholder="Enter password"
      />
      <Flex justify="center" extraClasses="pt-5">
        <FormControl
          control="button"
          type="submit"
          text="Sign Up"
          disabled={isSubmitting}
          showLoader={isSubmitting}
        />
      </Flex>
    </Box>
  );
};

export function RegisterForm() {
  return (
    <Form>
      <LoginCard>
        <h2 className="pb-5 text-2xl font-bold text-black">
          Create an account
        </h2>
        <RegisterUserFields />
      </LoginCard>
    </Form>
  );
}
