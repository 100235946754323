import { pendingInvitationsVar } from "@lango/common/apollo";
import { useReactiveVar } from "@apollo/client";
import { Box, Container } from "@lango/common/components";
import PendingInvitationsList from "./PendingInvitationsList";

export const PendingInvitationsScreen = () => {
  const invitations = useReactiveVar(pendingInvitationsVar);
  return (
    <Container extraClasses="p-0">
      <Box extraClasses="p-9">
        <h2 className="pb-1 text-2xl font-bold text-black leading-normal text-center">
          Pending Invitations
        </h2>
      </Box>
      <PendingInvitationsList invitations={invitations} />
    </Container>
  )
}