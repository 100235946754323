import { Formik } from "formik";

import { validateVendorProfileLanguage } from "@lango/common/validations";
import LanguagePairFormFields from "./languagePairFormFields";

const LanguagePairForm = (props) => {
  return (
    <Formik validationSchema={validateVendorProfileLanguage} {...props}>
      <LanguagePairFormFields />
    </Formik>
  );
};

export default LanguagePairForm;
