import { authPolicies } from "./auth";
import { billingPolicies } from "./billing";
import { corePolicies } from "./core";
import { organizationPolicies } from "./organization";
import { projectPolicies } from "./project";

// For each backend schema file, the type policies are defined in a separate 
// file by the same name. This file bundles them all together and exports them
// to keep the ../cache.js organized
// Reference: https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields

export default {
  ...authPolicies,
  ...billingPolicies,
  ...corePolicies,
  ...organizationPolicies,
  ...projectPolicies,
};
