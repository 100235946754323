import { useQuery } from "@apollo/client";

import { ORG_SERVICE_LINES } from "@lango/common/queries";

const prepareServiceLines = (data) =>
  data?.findOrganizationJobTypes?.map((orgJobType) => orgJobType?.jobType);


const useFetchClientServiceLines = (organizationID) => {
  const { data, loading, error } = useQuery(ORG_SERVICE_LINES, {
    variables: { organizationID: organizationID },
    fetchPolicy: "no-cache",
  });

  return { values: prepareServiceLines(data), loading, error };
};

export default useFetchClientServiceLines;
