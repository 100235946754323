import { Navigate, createBrowserRouter } from "react-router-dom";

import { FullPageError } from "@lango/common/components/errorDisplay";
import { NotificationsList } from "@lango/common/features/notifications";
import {
  AUTH_ROUTES,
  VENDOR_ROUTES,
  authPageLoader,
  authRoutes,
  protectedPageLoader,
  vendorInviteRoutes,
} from "@lango/common/routes";

import AppContainer from "./appContainer";
import { VendorLogin, VendorLoginRedirect } from "./login";
import Home from "./pages/home";
import VendorJobDetails from "./pages/home/vendorJobDetails";
import Settings from "./pages/settings";
import LinguistSettings from "./pages/settings/linguistSettings";

const overriddenPaths = [AUTH_ROUTES.LOGIN, AUTH_ROUTES.COGNITO_LOGIN];
const vendorAuthRoutes = [
  ...authRoutes.filter((r) => !overriddenPaths.includes(r.path)),
  {
    path: AUTH_ROUTES.LOGIN,
    element: <VendorLogin />,
    loader: authPageLoader,
  },
  {
    path: AUTH_ROUTES.COGNITO_LOGIN,
    element: <VendorLoginRedirect />,
    loader: authPageLoader,
  },
];

const vendorRoutes = [
  {
    path: "/requests/*",
    loader: protectedPageLoader,
    element: <Home />,
  },
  {
    path: "/settings/*",
    loader: protectedPageLoader,
    element: <Settings />,
  },
  {
    path: "/requests/job/:jobID/*",
    loader: protectedPageLoader,
    element: <VendorJobDetails />,
  },
  {
    path: "/linguists/:linguistID/*",
    loader: protectedPageLoader,
    element: <LinguistSettings />,
  },
  {
    path: "/notifications/*",
    loader: protectedPageLoader,
    element: <NotificationsList />,
  },
];

const routes = [
  {
    path: "/",
    element: <AppContainer />,
    errorElement: <FullPageError />,
    children: [
      ...vendorAuthRoutes,
      ...vendorRoutes,
      ...vendorInviteRoutes,
      {
        path: "*",
        element: <Navigate replace to={VENDOR_ROUTES.REQUESTS} />,
      },
      {
        index: true,
        element: <Navigate replace to={VENDOR_ROUTES.REQUESTS} />,
      },
    ],
  },
];

export default createBrowserRouter(routes);
