import { Form } from "formik";
import { useMutation } from "@apollo/client";

import { FormControl, toastError, toastSuccess } from "@lango/common/features";
import { UPDATE_USER_SETTINGS } from "@lango/common/queries";

const Fields = () => {
  const [updateUserMutation, { loading }] = useMutation(UPDATE_USER_SETTINGS);

  async function handleUpdateNotificationSettings(enabled) {
    try {
      await updateUserMutation({
        variables: {
          input: { notifications: enabled },
        },
      });
      toastSuccess("Notification settings updated successfully");
    } catch (error) {
      toastError("Failed to update notification settings");
      console.error(error);
    }
  }

  return (
    <Form>
      <FormControl
        control="toggle"
        name="notifications"
        label="Enable notifications"
        description="Inactive/Active"
        disabled={loading}
        onChange={handleUpdateNotificationSettings}
      />
    </Form>
  );
};

export default Fields;
