import { Form, Formik } from "formik";

import { useForgotPassword } from "@lango/common/features/auth/hooks";
import { forgotPasswordInitialValues } from "@lango/common/initialValues";
import { forgotPasswordSchema } from "@lango/common/validations";

import LoginCard from "../LoginCard";
import FormFields from "./FormFields";

const ForgotPasswordForm = () => {
  const { handleForgotPassword } = useForgotPassword();

  return (
    <Formik
      initialValues={forgotPasswordInitialValues}
      validationSchema={forgotPasswordSchema}
      onSubmit={handleForgotPassword}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form>
        <LoginCard>
          <FormFields />
        </LoginCard>
      </Form>
    </Formik>
  );
};
export default ForgotPasswordForm;
