export const filterOptions = [
  { value: "LastSevenDays", label: "Last 7 Days" },
  { value: "ThisMonth", label: "This Month" },
  { value: "LastMonth", label: "Last Month" },
  { value: "ThisYear", label: "This Year" },
  { value: "LastYear", label: "Last Year" },
];

const viewModeStyles = {
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "black",
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    paddingRight: "0.5rem",
    margin: 0,
    fontWeight: "bold",
    fontSize: 13,
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
  }),
};

export const timeFilterSelectFieldProps = {
  options: filterOptions,
  additionalStyles: viewModeStyles,
  isSearchable: false,
  border: false,
  whiteBackground: true,
  useRing: false,
};

export const pmDashboardTimeFilterProps = {
  ...timeFilterSelectFieldProps,
  whiteBackground: false,
  options: [{ value: "Today", label: "Today" }, ...filterOptions],
};

export const mergeFilters = (setA, setB) => {
  const commonSet = {};
  const customSet = {};
  const populateCommon = (f) => {
    if (f.values.filter(Boolean).length) {
      commonSet[f.key] = f;
    }
  };
  const populateCustom = (f) => {
    if (f.values.filter(Boolean).length) {
      customSet[f.key] = f;
    }
  };
  setA?.commonFilters?.forEach(populateCommon);
  setB?.commonFilters?.forEach(populateCommon);
  setA?.customFilters?.forEach(populateCustom);
  setB?.customFilters?.forEach(populateCustom);
  return {
    commonFilters: Object.values(commonSet),
    customFilters: Object.values(customSet),
  };
};
