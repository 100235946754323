import { Form, Formik } from "formik";

import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";
import { useClientOnboardSteps } from "@lango/common/hooks";
import { clientOnboardStep1InitialValues } from "@lango/common/initialValues";
import { validateClientOnboardingStep1 } from "@lango/common/validations";
import { Action } from "@lango/common/features/onboarding";

import Fields from "./fields";
import { useAcceptClientInviteCtx } from "@lango/common/context";

export default function Step1() {
  const { handleOnboardingStep1 } = useClientOnboardSteps();
  const { onboardingData } = useAcceptClientInviteCtx();
  return (
    <Formik
      initialValues={clientOnboardStep1InitialValues(
        onboardingData?.profileName,
      )}
      onSubmit={handleOnboardingStep1}
      validationSchema={validateClientOnboardingStep1}
    >
      <Form>
        <BasePageHeading
          heading="Client onboarding"
          description="Step 1 of 4"
        />
        <Fields />
        <Action />
      </Form>
    </Formik>
  );
}
