import { Divider } from "@aws-amplify/ui-react";
import { IoIosArrowForward } from "react-icons/io";

import cn from "@lango/common/classnames";
import { Avatar, TimeAgo } from "@lango/common/components";
import Flex from "@lango/common/features/tables/components/Flex";

/**
 * Notification Component
 *
 * Displays a single notification item within the notifications panel. This component
 * shows the notification's avatar, title, and description. The title and description
 * change style based on whether the notification has been read or not. It also includes
 * a right arrow icon to indicate that the item is clickable.
 *
 * @param {Object} props - The props for the OrganizationNotification component.
 * @param {string} props.title - The title of the notification.
 * @param {string} props.description - The description of the notification.
 * @param {string} props.createdAt - It is the time when the notification is created.
 * @param {string=} props.initials - optional. The initials to show in the avatar. If not provided, the avatar is blank.
 * @param {boolean} props.isRead - Indicates if the notification has been read.
 * @param {any} [props.onClick] - The function to be called when the notification is clicked.
 * @returns {React.ReactNode} The rendered OrganizationNotification component.
 */
export const Notification = ({
  title,
  description,
  initials,
  isRead,
  createdAt,
  onClick,
}) => {
  const readColor = isRead ? "text-gray-400" : "text-black";

  return (
    <div className="transition duration-150 ease-in-out hover:bg-gray-100">
      <Divider />
      <div
        onClick={!isRead ? onClick : null}
        className={cn(
          `flex py-5 px-6 w-full items-start ${!isRead ? `cursor-pointer` : ""}`,
        )}
      >
        <div className="flex-shrink-0 flex items-start justify-center w-16">
          <Avatar
            placeholderInitials={initials}
            extraClasses="w-14 h-14 text-xl font-bold border-gray-200 border"
          />
        </div>
        <div className="flex-grow mx-4 flex flex-col justify-center">
          <p className={`text-base font-bold ${readColor}`}>{title}</p>
          <p className={`text-sm font-normal mt-1 ${readColor}`}>
            {description}
          </p>
        </div>
        <Flex
          justify="between"
          align="end"
          extraClasses="flex-shrink-0 w-28 flex-col gap-4"
        >
          <TimeAgo date={createdAt} extraClasses={readColor} />
          {!isRead && <IoIosArrowForward size="28px" className={readColor} />}
        </Flex>
      </div>
    </div>
  );
};
