import { useMutation } from "@apollo/client";
import { toastError, toastSuccess } from "@lango/common/features";
import { UPDATE_USER_SETTINGS } from "@lango/common/queries";
import { createContext, useContext, useState } from "react";

// Define the SettingsContextProvider component.
export const SettingsContextProvider = ({ user, children }) => {
  // Create a state variable for the active field and a function to update it.
  const [activeField, setActiveField] = useState("");
  const [userSettings, setUserSettings] = useState(user);

  // Create a mutation for updating user settings and get the loading state.
  const [updateUserMutation, { loading }] = useMutation(UPDATE_USER_SETTINGS);

  function handleUpdateUserSettings(values) {
    setUserSettings(values);
  }

  // Define a function to update the user.
  const handleUpdateUser = (
    values,
    onUpdateCallback = (_values) => {},
    formValues,
  ) => {
    // Call the mutation with the values as variables.
    updateUserMutation({
      variables: {
        input: values,
      },
    })
      // On success, show a success toast, update the user settings, and call the callback.
      .then(() => {
        toastSuccess("Updated user settings");
        handleUpdateUserSettings(formValues);
        onUpdateCallback(values);
      })
      // On error, show an error toast and log the error.
      .catch((error) => {
        toastError("There was an error updating user settings");
        console.error(error);
      })
      // Finally, clear the active field.
      .finally(() => {
        handleChangeActiveField("");
      });
  };

  // Define a function to change the active field.
  function handleChangeActiveField(field = "") {
    setActiveField(field);
  }

  // Return a SettingsContext.Provider with the value and children.
  return (
    <SettingsContext.Provider
      value={{
        handleUpdateUser,
        handleChangeActiveField,
        handleUpdateUserSettings,
        activeField,
        loading,
        userSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

// Create a context for settings with default values.
export const SettingsContext = createContext({
  handleUpdateUser: (
    _values,
    _onUpdateCallback = (_values) => {},
    _formValues,
  ) => {},
  handleChangeActiveField: (_field) => {},
  handleUpdateUserSettings: (_values) => {},
  activeField: "",
  loading: false,
  userSettings: null,
});

// Define a hook to use the settings context.
export function useSettingsContext() {
  // Get the context.
  const context = useContext(SettingsContext);
  // If the context is not available, throw an error.
  if (!context) {
    throw new Error(
      "useSettingsContext must be used within a SettingsContextProvider",
    );
  }
  // Return the context.
  return context;
}
