import { Divider } from "@aws-amplify/ui-react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";

import { Box, Grid } from "@lango/common/components";
import { FormControl } from "@lango/common/features";

export default function Fields({ remove, push, ...props }) {
  const serviceLocations = props?.form?.values?.serviceLocations;

  return (
    <Box>
      <Box extraClasses="bg-white rounded-xl shadow-md my-8">
        <Box extraClasses="p-8">
          <span className="text-left text-base md:text-2xl font-bold leading-tight text-black">
            Service Locations
          </span>
          <span className="font-normal">{"   (optional)"}</span>
        </Box>
        {serviceLocations?.map((_, index) => (
          <div key={index}>
            <Box spaceY={6} extraClasses="p-8">
              <Grid extraClasses="sm:grid-cols-5">
                <Grid.Col span={2}>
                  <FormControl
                    control="input"
                    name={`serviceLocations.${index}.locationName`}
                    label="Location Name"
                    placeHolder="Enter location name"
                  />
                </Grid.Col>
              </Grid>
              <Grid extraClasses="sm:grid-cols-5">
                <Grid.Col span={2}>
                  <FormControl
                    control="input"
                    name={`serviceLocations.${index}.address1`}
                    label="Address 1"
                    placeHolder="Enter Address 1"
                  />
                </Grid.Col>
                <Grid.Col span={1} />
                <Grid.Col span={2}>
                  <FormControl
                    control="input"
                    name={`serviceLocations.${index}.address2`}
                    label="Address 2"
                    placeHolder="Enter Address 2"
                  />
                </Grid.Col>
              </Grid>
              <Grid extraClasses="sm:grid-cols-5">
                <Grid.Col span={2}>
                  <FormControl
                    control="input"
                    name={`serviceLocations.${index}.city`}
                    label="City"
                    placeHolder="Enter City"
                  />
                </Grid.Col>
                <Grid.Col span={1} />
                <Grid.Col span={2}>
                  <FormControl
                    control="input"
                    name={`serviceLocations.${index}.zipCode`}
                    label="Zip Code"
                    placeHolder="Enter Zip Code"
                  />
                </Grid.Col>
              </Grid>
              {serviceLocations?.length > 1 && (
                <FormControl
                  textButton={true}
                  text="Remove"
                  type="button"
                  control="button"
                  onClick={() => remove(index)}
                  extraClasses="mt-0 flex items-center float-right"
                  icon={<MinusCircleIcon className="w-5" />}
                />
              )}
              <FormControl
                textButton={true}
                text="Add new"
                type="button"
                control="button"
                extraClasses="flex items-center"
                onClick={() =>
                  push({
                    locationName: "",
                    address1: "",
                    address2: "",
                    city: "",
                    zipCode: "",
                  })
                }
                icon={<PlusCircleIcon className="w-5" />}
              />
            </Box>
            <Divider />
          </div>
        ))}
        <Divider />
      </Box>
    </Box>
  );
}
