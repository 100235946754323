import { Form, useField } from "formik";

import FormControl from "@lango/common/features/forms/components/FormControl";

import useUpdateVendorProfileJobTypes from "./updateVendorProfileJobType";

const ServiceLineFields = ({organizationID, lspID}) => {
  const [{ value }] = useField("jobTypes");
  const { updateVendorProfileJobType, loading } = useUpdateVendorProfileJobTypes();

  return (
    <Form className="space-y-8">
      {value?.map((value, index) => (
        <FormControl
          key={index}
          control="toggle"
          name={`jobTypes.${index}.enabled`}
          label={value?.name}
          description="Inactive/Active"
          disabled={loading}
          onChange={(enabled) => updateVendorProfileJobType({ jobTypeID: value?.id, enabled, organizationID, lspID })}
        />
      ))}
    </Form>
  );
};

export default ServiceLineFields;
