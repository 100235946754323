import { EyeOffIcon } from "@heroicons/react/outline";
import Dropdown from "@lango/common/components/dropdown";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableColumns from "./DraggableColumns";
import { iconButtonStyles, iconButtonStylesInner } from "./IconButton";

export const CustomizeColumns = ({ columns, handleOnDragEnd }) => (
  <Dropdown
    buttonClassName={iconButtonStyles}
    title="Customize Column View"
    className="w-60"
  >
    <Dropdown.Button className={iconButtonStyles}>
      <EyeOffIcon className={iconButtonStylesInner} />
    </Dropdown.Button>
    <Dropdown.Menu className="px-4 py-6">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <DraggableColumns columns={columns} />
      </DragDropContext>
    </Dropdown.Menu>
  </Dropdown>
);
