import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";

import { orgLogoInfoVar } from "@lango/common/apollo/localState";
import { HAS_CUSTOM_LOGO } from "@lango/common/queries";

const useHasCustomLogo = () => {
  const orgLogoInfo = useReactiveVar(orgLogoInfoVar);
  const [hasCustomLogoQuery, { loading, error, data }] = useLazyQuery(
    HAS_CUSTOM_LOGO,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      context: { clientName: "public" },
    }
  );

  useEffect(() => {
    if (orgLogoInfo && orgLogoInfo?.id) {
      hasCustomLogoQuery({ variables: { organizationID: orgLogoInfo?.id } });
    }
  }, [hasCustomLogoQuery, orgLogoInfo]);

  const hasCustomLogo = data?.hasCustomLogo;

  return { hasCustomLogo, loading, error };
};

export default useHasCustomLogo;
