import WithLoader from "@lango/common/components/WithLoader";
import useCanUpdateOrg from "@lango/common/hooks/useCanUpdateOrg";

import { LinguistTabPanel, NavigateToLinguists } from "./utils";

const LinguistSettings = () => {
  const { canUpdateOrg, ...loadingProps } = useCanUpdateOrg();
  return (
    <WithLoader {...loadingProps}>
      <NavigateToLinguists />
      {canUpdateOrg && <LinguistTabPanel />}
    </WithLoader>
  );
};

export default LinguistSettings;
