import React, { createContext, useContext, useEffect, useState } from "react";

import { Modal } from "flowbite-react";
import { useIsAuthenticated } from "../features/auth/hooks";

export const ModalContext = createContext({
  modals: [],
  showModal: (/** @type {any} */ _component, /** @type {any} */ _props) => {},
  hideModal: () => {},
});

/**
 * Provider component for managing modals.
 * Renders the modal provider component.
 * The ModalProvider component is responsible for managing the modals in the application.
 * It provides a context for accessing the modals, showing modals, and hiding modals.
 * The modals are stored in the state using the useState hook.
 * The showModal function adds a new modal to the modals array in the state.
 * The hideModal function removes the last modal from the modals array in the state.
 * The ModalProvider component renders the ModalRoot component, which is responsible for rendering the modals.
 * It also renders the child components passed to it.
 * @param {Object} props - The props for the ModalProvider component.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactNode} The rendered ModalProvider component.
 */
export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);
  const { isAuthenticated } = useIsAuthenticated();

  /**
   * Function to show a modal.
   * @param {any} component - The component to be rendered as a modal.
   * @param {Object} props - The props to be passed to the modal component.
   */
  const showModal = (component, props = {}) => {
    setModals((prevModals) => [...prevModals, { component, props }]);
  };

  /**
   * Function to hide last modal.
   */
  const hideModal = () => {
    setModals((prevModals) => prevModals.slice(0, -1));
  };

  /**
   * useEffect hook to hide all modals if the user is not authenticated.
   */
  useEffect(() => {
    if (!isAuthenticated) {
      modals.forEach(() => hideModal());
    }
  }, [isAuthenticated]);

  return (
    <ModalContext.Provider value={{ modals, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};

/**
 * Renders the modal root component.
 * The ModalRoot component is responsible for rendering the modals in the application.
 * It uses the useModal hook from the ModalContext to access the modals and hideModal function.
 * It maps over the modals array and renders each modal using the Modal component from "flowbite-react".
 * The modals are rendered in the order they were added, with the latest modal being on top.
 * Each modal can have a heading and a size specified in the props.
 * The hideModal function is called when the modal is closed.
 * @returns {React.ReactNode} The rendered modal root component.
 */
export const ModalRoot = () => {
  const { modals, hideModal } = useModal();

  return modals?.map(({ component: Component, props = {} }, index) => {
    const {
      heading = "",
      headerClasses = "",
      bodyClasses = "",
      size = "xl",
      dismissible = false,
    } = props;

    return (
      <Modal
        key={index}
        show
        dismissible={dismissible}
        size={size}
        onClose={hideModal}
      >
        {heading && (
          <Modal.Header className={headerClasses}>{heading}</Modal.Header>
        )}
        <Modal.Body className={bodyClasses}>
          <Component {...props} />
        </Modal.Body>
      </Modal>
    );
  });
};

/**
 * Custom hook for accessing the ModalContext.
 */
export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
}
