import { useAuthenticator } from "@aws-amplify/ui-react";
import { signIn, signOut } from "aws-amplify/auth";

import { signInWithAuth0 } from "@lango/common/auth0";
import { prepareCustomState } from "@lango/common/helpers";
import { orgLogoInfoVar } from "../apollo";
import { clearAllToasts } from "../features";
import { ResendCode, resendCodeModalProps } from "../features/auth/components/ResendCode";
import { useModal } from "../context";

export const useVendorLogin = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { showModal } = useModal();

  const handleVendorLogin = async (
    { username, password, localLogin }, // form values
    { setStatus, setFieldValue }, // form action helpers
  ) => {
    clearAllToasts();
    if (!localLogin) {
      // needs this because we are reusing common login form fields
      setFieldValue("localLogin", true);
      return;
    }

    try {
      setFieldValue("disableFields", true);
      if (authStatus === "authenticated") {
        await signOut();
      }
      const { nextStep } = await signIn({ username, password });
      if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        showModal(ResendCode, { ...resendCodeModalProps, username });
        setFieldValue("disableFields", false);
        return;
      }
      const organization = orgLogoInfoVar();
      const encodedRelayState = prepareCustomState("vendorLogin", "orgID", organization?.id);
      signInWithAuth0(username, password, encodedRelayState, (err) => {
        setStatus(err);
        setFieldValue("disableFields", false);
      });
    } catch (error) {
      const err = error?.message || error;
      setStatus(err);
      setFieldValue("disableFields", false);
    }
  };

  return { handleVendorLogin };
};
