import AddButton from "@lango/common/features/forms/components/AddButton";
import Flex from "@lango/common/features/tables/components/Flex";

import { useModal } from "@lango/common/context";
import { useCreateVendorProfileLanguage } from "@lango/common/hooks";
import { vendorLanguageInitialValues } from "@lango/common/initialValues";
import VendorLanguagePairTable from "./VendorLanguagePairTable";
import LanguagePairForm from "./languagePairForm";

const VendorProfileLanguage = ({ organizationID, lspID, canUpdateOrg }) => {
  const { showModal } = useModal();
  const { handleCreateVendorLanguagePair } = useCreateVendorProfileLanguage({
    organizationID,
    lspID,
  });

  const handleVendorLanguagePairForm = () => {
    showModal(LanguagePairForm, {
      heading: "Add Language Pair",
      initialValues: vendorLanguageInitialValues,
      onSubmit: handleCreateVendorLanguagePair,
    });
  };

  return (
    <>
      <Flex justify="end">
        {canUpdateOrg && (
          <AddButton
            text="Add Language Pair"
            onClick={handleVendorLanguagePairForm}
            extraClasses="mb-4"
            type="button"
          />
        )}
      </Flex>
      <VendorLanguagePairTable
        organizationID={organizationID}
        lspID={lspID}
        canUpdateOrg={canUpdateOrg}
      />
    </>
  );
};

export default VendorProfileLanguage;
