import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import env from "@lango/common/env";
import { ORGANIZATION_LSPS } from "@lango/common/queries";

import { FIND_OR_REGISTER_USER } from "./queries";

function prepareInput(values) {
  return {
    input: {
      firstName: values?.firstName || "",
      lastName: values?.lastName || "",
      organizationID: values?.organizationID,
      applicationName: env.appName,
      registerUser: values?.registerUser,
    },
  };
}

export const useProvisionAndFetchUser = () => {
  const [findUser, { loading }] = useMutation(FIND_OR_REGISTER_USER);

  const findOrRegisterUser = useCallback(
    async (values) => {
      const { data } = await findUser({ variables: prepareInput(values) });
      return data?.findOrRegisterUser;
    },
    [findUser],
  );

  return { findOrRegisterUser, loading };
};

export function useProvisionUserAndRefetchLSPs() {
  const [findUser, { loading }] = useMutation(FIND_OR_REGISTER_USER, {
    refetchQueries: [ORGANIZATION_LSPS],
  });

  const findOrRegisterUser = useCallback(
    async (values) => {
      const { data } = await findUser({ variables: prepareInput(values) });
      return data?.findOrRegisterUser;
    },
    [findUser],
  );

  return { findOrRegisterUser, loading };
}
