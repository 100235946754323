import { gql } from "@apollo/client";

export const UPLOAD_LOGO = gql`
  mutation uploadLogo($organizationID: ID!, $file: Upload!) {
    logoFileUpload(organizationID: $organizationID, file: $file)
  }
`;

export const DELETE_LOGO = gql`
  mutation deleteLogo($organizationID: ID!) {
    deleteOrganizationLogo(organizationID: $organizationID)
  }
`;

export const UPDATE_CLIENT_VRI_SETTINGS = gql`
  mutation updateClientVRISettings(
    $clientProfileInput: UpdateClientProfileSettingsRequest!
  ) {
    updateClientProfileSettings(input: $clientProfileInput)
  }
`;
