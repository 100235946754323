import { useQuery } from "@apollo/client";
import { GET_CLIENT_PROFILE } from "../queries";

/**
 *
 * @param {*} id client profile id
 * @returns client profile data
 */
export const useGetClientProfile = (id) => {
  const { loading, error, data } = useQuery(GET_CLIENT_PROFILE, {
    variables: { id },
  });

  return {
    loading,
    error,
    clientProfile: data?.findClientProfile || {},
  };
};
