import { gql } from "@apollo/client";

import { RECORD_VIEW_MODE_FIELDS } from "../organizations";

export const USER_INFO = gql`
  fragment userInfo on User {
    id
    firstName
    lastName
  }
`;

export const ASSIGNED_USER_INFO = gql`
  fragment assignedUserInfo on AssignedUser {
    id
    firstName
    lastName
  }
`;

export const USER_DETAILS = gql`
  ${USER_INFO}
  fragment userDetails on User {
    ...userInfo
    email
  }
`;

export const CLOUD_STORAGE_PROVIDER = gql`
  fragment cloudStorageProviderField on CloudStorageProvider {
    id
    name
  }
`;

export const USER_ORGANIZATION = gql`
  fragment userOrganization on Organization {
    id
    name
    pageTitleEnabled
    nameSlug
    customLoginPage
  }
`;

export const USER_ORGANIZATION_AND_RECORD_VIEW = gql`
  ${USER_ORGANIZATION}
  ${RECORD_VIEW_MODE_FIELDS}
  fragment userOrganizationAndRecordView on Organization {
    ...userOrganization
    recordViewMode {
      ...RecordViewModeFields
    }
  }
`;

export const USER_DETAILS_AND_ORGANIZATIONS = gql`
  ${USER_DETAILS}
  ${USER_ORGANIZATION_AND_RECORD_VIEW}
  fragment userDetailsAndOrganizations on User {
    ...userDetails
    organizations {
      ...userOrganizationAndRecordView
    }
  }
`;
