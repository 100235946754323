export const styles = {
  enter: "transition ease-out duration-75",
  enterFrom: "opacity-0 -translate-y-2",
  enterTo: "opacity-100 translate-y-0",
  leave: "transition ease-in duration-50",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-0 -translate-y-2",
};

export const dropdownButtonClasses =
  "group flex items-center bg-white text-base text-black hover:text-gray-900 focus:outline-none";

export const dropdownMenuClasses =
  "absolute right-0 z-40 mt-2 w-48 max-w-xs -translate-x-1/2 -transform px-2 sm:-translate-x-2 sm:px-0";
