import { useNavigate } from "react-router-dom";

import { getFirstLastCharacters } from "@lango/common/helpers";
import { useMarkNotificationAsRead } from "@lango/common/hooks";
import { Notification } from "./Notification";

/**
 * UserNotificationsTab Component
 *
 * Displays the list of user notifications in the notifications panel.
 *
 * @param {Object} props - The props for the OrganizationNotification component.
 * @param {Object} props.notifications - The array of organization notification objects to display
 * @param {function} props.closePanel - A function to close the panel
 * @returns {React.ReactNode} The rendered OrganizationNotification component.
 */
export const UserNotificationsTab = ({ notifications, closePanel }) => {
  const { handleMarkNotificationAsRead } = useMarkNotificationAsRead();
  const navigate = useNavigate();
  const onNotificationRead = (notification) => {
    const link = notification?.link || "/notifications";
    navigate(link);
    closePanel();
  };
  const handleNotificationClick = (notification) => {
    handleMarkNotificationAsRead(notification?.id, () =>
      onNotificationRead(notification),
    );
  };
  return (
    <>
      {notifications.map((notification, key) => (
        <Notification
          key={key}
          title={notification.title}
          description={notification.description}
          initials={getFirstLastCharacters(notification.sentBy?.name)}
          onClick={() => handleNotificationClick(notification)}
          isRead={notification.isRead}
          createdAt={notification.createdAt}
        />
      ))}
    </>
  );
};
