import { Formik } from "formik";
import PropTypes from "prop-types";

import { validateInviteClientVendor } from "@lango/common/validations";

import InviteClientAndVendorAdminFields from "./ClientAndVendorAdminFields";

export const InviteClientAndVendorAdmin = ({
  initialValues,
  users,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validateInviteClientVendor}
    >
      <InviteClientAndVendorAdminFields users={users} />
    </Formik>
  );
};

InviteClientAndVendorAdmin.propTypes = {
  heading: PropTypes.string,
  initialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.number,
  }),
};

InviteClientAndVendorAdmin.defaultProps = {
  heading: "Add new User",
  initialValues: {
    firstName: "",
    lastName: "",
    email: "",
    role: 1,
  },
  handleSubmit: () => {},
};
