import { Form, Formik } from "formik";

import ErrorBanner from "@lango/common/features/alerts/components/ErrorBanner";
import LoginFormFields from "@lango/common/features/auth/containers/Login/LoginForm/FormFields";
import { useIsAuthenticated } from "@lango/common/features/auth/hooks";
import { loginFormInitialValues } from "@lango/common/initialValues";
import { loginValidationSchema } from "@lango/common/validations";

import LoginCard from "../../LoginCard";

const LoginForm = ({ onSubmit, heading }) => {
  const { isAuthenticated } = useIsAuthenticated();
  return (
    <Formik
      initialValues={loginFormInitialValues}
      validationSchema={loginValidationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ status }) => (
        <Form>
          <LoginCard>
            <h2 className="pb-5 text-center text-2xl font-bold text-black">
              {heading}
            </h2>
            {status && <ErrorBanner message={status} />}
            {!isAuthenticated && <LoginFormFields />}
          </LoginCard>
        </Form>
      )}
    </Formik>
  );
};
export default LoginForm;
