import { Form, Formik } from "formik";

import Button from "@lango/common/features/forms/components/Button";
import { useUpdateUserRole } from "@lango/common/components/userList/userRoles/useUpdateUserRoles";
import { Flex } from "@lango/common/features";

export const RemoveUser = ({ initialValues, hideModal }) => {
  const handleUpdateUserRole = useUpdateUserRole(hideModal);
  const user = initialValues?.user;
  const userName = user?.firstName + " " + user?.lastName + " (" + user?.email + ")";
  return (
    <Formik initialValues={initialValues} onSubmit={handleUpdateUserRole}>
      <Form className="space-y-1">
        <div className="text-center">
          <p className={"text-lg font-bold"}>Are you sure you want to remove this user?</p>
          <p className={"mt-2"}>{userName}</p>
        </div>
          <Flex justify="center" extraClasses={"gap-4 py-2"}>
            <Button text="Cancel" type="button" importance="secondary" onClick={() => {
              hideModal();
            }} />
            <Button text="Remove" />
          </Flex>
      </Form>
    </Formik>
  )
};
