import className from "@lango/common/classnames";
import { useIsAuthenticated } from "@lango/common/features/auth/hooks";
import BaseHeader from "@lango/common/features/header/components/BaseHeader";

import { useHasOrganizations } from "../../auth/hooks/useHasOrganizations";
import UserMenu from "./UserMenu";

const Header = ({ children, extraClasses }) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { hasOrganizations } = useHasOrganizations();
  return (
    <BaseHeader isAuthenticated={isAuthenticated}>
      <div
        className={className(
          "ml-2 flex flex-1  border-l-2 border-gray-200 px-2 pl-2 text-sm sm:inset-0 md:ml-4 md:pl-4 md:text-base",
          extraClasses,
        )}
      >
        {hasOrganizations && children}
      </div>
      <div className="md:ml-4 md:block">
        <div className={"relative flex items-center md:ml-4"}>
          <UserMenu />
        </div>
      </div>
    </BaseHeader>
  );
};

export default Header;
