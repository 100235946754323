import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect, useState } from "react";

const FileDownload = () => {
  const [authHeader, setAuthHeader] = useState(null);

  useEffect(() => {
    fetchAuthSession()
      .then(({ tokens }) => {
        const token = tokens.idToken;
        setAuthHeader(token);
      })
      .catch((err) => console.error(err));
  }, [setAuthHeader]);

  const setupDownloadRequest = (postBody) => {
    if (!authHeader) {
      console.error("no auth header");
      return;
    }
    return {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader ? `Bearer ${authHeader}` : "",
      },

      body: JSON.stringify(postBody),
    };
  };
  return { setupDownloadRequest };
};

export default FileDownload;
