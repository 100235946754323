export const gridColsClassesMap = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
  4: "grid-cols-4",
  5: "grid-cols-5",
  6: "grid-cols-6",
  7: "grid-cols-7",
  8: "grid-cols-8",
  9: "grid-cols-9",
  10: "grid-cols-10",
  11: "grid-cols-11",
  12: "grid-cols-12",
  none: "grid-cols-none",
};

export const colSpanClassesMap = {
  auto: "col-auto",
  1: "col-span-1",
  2: "col-span-2",
  3: "col-span-3",
  4: "col-span-4",
  5: "col-span-5",
  6: "col-span-6",
  7: "col-span-7",
  8: "col-span-8",
  9: "col-span-9",
  10: "col-span-10",
  11: "col-span-11",
  12: "col-span-12",
  full: "col-span-full",
};

export const spaceXClassesMap = {
  0: "space-x-0",
  1: "space-x-1",
  2: "space-x-2",
  3: "space-x-3",
  4: "space-x-4",
  5: "space-x-5",
  6: "space-x-6",
  7: "space-x-7",
  8: "space-x-8",
  9: "space-x-9",
  10: "space-x-10",
  11: "space-x-11",
  12: "space-x-12",
  14: "space-x-14",
  16: "space-x-16",
  20: "space-x-20",
  24: "space-x-24",
  28: "space-x-28",
  32: "space-x-32",
  36: "space-x-36",
  40: "space-x-40",
  44: "space-x-44",
  48: "space-x-48",
  52: "space-x-52",
  56: "space-x-56",
  60: "space-x-60",
  64: "space-x-64",
  72: "space-x-72",
  80: "space-x-80",
  96: "space-x-96",
  0.5: "space-x-0.5",
  1.5: "space-x-1.5",
  2.5: "space-x-2.5",
  3.5: "space-x-3.5",
  px: "space-x-px",
  reverse: "space-x-reverse",
};

export const spaceYClassesMap = {
  0: "space-y-0",
  1: "space-y-1",
  2: "space-y-2",
  3: "space-y-3",
  4: "space-y-4",
  5: "space-y-5",
  6: "space-y-6",
  7: "space-y-7",
  8: "space-y-8",
  9: "space-y-9",
  10: "space-y-10",
  11: "space-y-11",
  12: "space-y-12",
  14: "space-y-14",
  16: "space-y-16",
  20: "space-y-20",
  24: "space-y-24",
  28: "space-y-28",
  32: "space-y-32",
  36: "space-y-36",
  40: "space-y-40",
  44: "space-y-44",
  48: "space-y-48",
  52: "space-y-52",
  56: "space-y-56",
  60: "space-y-60",
  64: "space-y-64",
  72: "space-y-72",
  80: "space-y-80",
  96: "space-y-96",
  0.5: "space-y-0.5",
  1.5: "space-y-1.5",
  2.5: "space-y-2.5",
  3.5: "space-y-3.5",
  px: "space-y-px",
  reverse: "space-y-reverse",
};

export const spacer = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  14,
  16,
  20,
  24,
  28,
  32,
  36,
  40,
  44,
  48,
  52,
  56,
  60,
  64,
  72,
  80,
  96,
  0.5,
  1.5,
  2.5,
  3.5,
  "px",
  "reverse",
];
