import { useMutation } from "@apollo/client";

import {
  toastError,
  toastSuccess,
} from "@lango/common/features/alerts/functions/toast";

import {
  ALL_VENDOR_PROFILE_LANGUAGE,
  DELETE_VENDOR_PROFILE_LANGUAGE,
} from "@lango/common/queries";

export const useDeleteVendorProfileLanguage = (organizationID) => {
  const [deleteVendorProfileLanguage, { loading }] = useMutation(
    DELETE_VENDOR_PROFILE_LANGUAGE,
    {
      refetchQueries: [ALL_VENDOR_PROFILE_LANGUAGE],
    },
  );

  const handleDeleteVendorProfileLanguage = async (vendorProfileLangaugeID) => {
    try {
      await deleteVendorProfileLanguage({
        variables: { organizationID, vendorProfileLangaugeID },
      });
      toastSuccess("Vendor profile language pair deleted");
    } catch (error) {
      toastError("There was an error deleting the vendor profile language ID");
      console.error(error);
    }
  };

  return { handleDeleteVendorProfileLanguage, loading };
};
