import { useCallback, useState } from "react";

/**
 * useBoolean hook for managing boolean state.
 * 
 * @param {boolean} initial - The initial boolean value.
 * @returns {[boolean, () => void, () => void, () => void]} - An array containing the boolean value and utility functions.
 * 
 * @hook useBoolean  
 */
const useBoolean = (initial) => {
  const [bool, setBool] = useState(initial);

  const setTrue = useCallback(() => {
    setBool(true);
  }, []);

  const setFalse = useCallback(() => {
    setBool(false);
  }, []);

  const toggle = useCallback(() => {
    setBool((value) => !value);
  }, []);

  return [bool, setTrue, setFalse, toggle];
};

export default useBoolean;