import { InformationCircleIcon } from "@heroicons/react/solid";
import { Tooltip as FlowTooltip } from "flowbite-react";

import classNames from "@lango/common/classnames";
import Loader from "@lango/common/components/Loader";

function Tooltip({ text, extraClasses, iconClasses }) {
  return (
    <FlowTooltip
      content={
        <div className={classNames("flex w-40 justify-center", extraClasses)}>
          {text ?? <Loader />}
        </div>
      }
    >
      <InformationCircleIcon
        className={classNames(
          "h-4 w-4 cursor-help  hover:text-black",
          { "text-gray-400": !iconClasses },
          iconClasses
        )}
      />
    </FlowTooltip>
  );
}

Tooltip.defaultProps = {
  extraClasses: "",
  iconClasses: "",
};

export default Tooltip;
