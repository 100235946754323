import { useAuthenticator } from "@aws-amplify/ui-react";
import { signIn, signOut } from "aws-amplify/auth";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { clearOrgLogoInfo, getInitialPath, resetStore } from "@lango/common/apollo/localState";
import {
  NO_ORGANIZATION,
  ORG_FORM_HEADING,
  TOKEN_REVOKED,
} from "@lango/common/constants";
import withRedirectIfAuthenticated from "@lango/common/features/auth/components/withRedirectIfAuthenticated";
import {
  useCheckSSO,
  useIsAuthenticated,
  useLogin,
  useValidateOrgCustomLoginSupport,
} from "@lango/common/features/auth/hooks";
import { customState } from "@lango/common/features/auth/hooks/utils";
import useQueryParams from "@lango/common/hooks/useQueryParams";
import { AUTH_ROUTES } from "@lango/common/routes";
import { clearAllToasts } from "@lango/common/features/alerts/functions/toast";
import { AcceptClientInviteProvider, useModal } from "@lango/common/context";
import { isPMApp } from "@lango/common/env";

import LoginContainer from "./LoginContainer";
import LoginForm from "./LoginForm";
import LoginPageTitle from "./LoginPageTitle";
import { ResendCode, resendCodeModalProps } from "../../components/ResendCode";

const renderLoginPage = (pathname) => {
  switch (pathname) {
    case AUTH_ROUTES.COGNITO_LOGIN:
      return (
        <AcceptClientInviteProvider callbackMode>
          <LoginContainer />
        </AcceptClientInviteProvider>
      );
    case AUTH_ROUTES.LOGIN:
      return <LoginProtected />;
    default:
      return <Navigate replace to={AUTH_ROUTES.LOGIN} />;
  }
};

const Login = () => {
  const { pathname } = useLocation();
  return renderLoginPage(pathname);
};

export default Login;

const LoginFormWrapper = () => {
  const { handleFederatedLogin, handleFetchAndInitializeUser } = useLogin();
  const { getQueryParams } = useQueryParams();
  const { orgInfo } = useValidateOrgCustomLoginSupport();
  const { isAuthenticated } = useIsAuthenticated();
  const { handleCheckSSO } = useCheckSSO();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();
  const { showModal } = useModal();

  const orgSlug = getQueryParams("org");
  const from = getQueryParams("from");
  const formHeading = orgSlug ? ORG_FORM_HEADING : "Sign In";
  const { id: orgID } = orgInfo ?? { id: undefined };

  useEffect(() => {
    !isAuthenticated && !orgSlug && clearOrgLogoInfo();
  }, [isAuthenticated, orgSlug]);

  useEffect(() => {
    orgSlug && isPMApp && navigate(AUTH_ROUTES.LOGIN);
  }, [orgSlug]);

  const handleSubmitLoginForm = async (
    { username, password, localLogin },
    { setStatus, setFieldValue },
  ) => {
    clearAllToasts();
    try {
      if (authStatus === "authenticated") {
        await signOut();
      }
      if (localLogin) {
        const { nextStep } = await signIn({ username, password });
        if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
          showModal(ResendCode, { ...resendCodeModalProps, username });
          return;
        }
        await handleFetchAndInitializeUser(orgID);
        navigate(getInitialPath(from));
      } else {
        const { isEnabled, ssoProviderName, ssoProviderOrganizationID } =
          await handleCheckSSO(username, orgID);
        if (!isEnabled) {
          setFieldValue("localLogin", true);
        } else {
          await handleFederatedLogin(
            ssoProviderName,
            customState(ssoProviderOrganizationID),
          );
          navigate(getInitialPath(from));
        }
      }
    } catch (error) {
      if (!localLogin && error?.message === TOKEN_REVOKED) {
        await signOut();
        return;
      }
      if (localLogin && error?.message === NO_ORGANIZATION) {
        await signOut();
        
        console.log("error", error);
      }
      const err =
        error?.response?.data?.error_description || error?.message || error;
      setStatus(err);
      resetStore();
    }
  };
  return (
    <>
      <LoginPageTitle orgInfo={orgInfo} />
      <LoginForm onSubmit={handleSubmitLoginForm} heading={formHeading} />
    </>
  );
};

// todo: this is a HOC, we should refactor it to a hook
const LoginProtected = withRedirectIfAuthenticated(LoginFormWrapper);
