import { useReactiveVar } from "@apollo/client";
import { Divider } from "@aws-amplify/ui-react";
import { Tooltip } from "flowbite-react";
import { useState } from "react";

import {
  activeOrgVar,
  userFirstNameVar,
  userLastNameVar,
} from "@lango/common/apollo";
import { Avatar } from "@lango/common/components";
import { handleCopy } from "@lango/common/components/ContactCodeDisplay";
import LangoCrowdSubtitle from "@lango/common/components/LangoCrowd/LangoCrowdSubtitle";
import { Flex } from "@lango/common/features";
import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";
import { getFirstLastCharacters } from "@lango/common/helpers";
import {
  useDeleteUserPicture,
  useFetchLinguistID,
  useUploadUserPicture,
} from "@lango/common/hooks";
import { useFetchCurrentUser } from "@lango/common/hooks/findCurrentUser";
import { formatCode } from "@lango/common/hooks/utils";

/**
 * This function takes a linguistUniqueID as an argument and returns a span element
 * If linguistUniqueID is truthy, the span element is rendered with a click event that copies the linguistUniqueID to the clipboard.
 */
function renderLinguistID(linguistUniqueID) {
  return (
    linguistUniqueID && (
      <Tooltip content="Copy to Clipboard">
        <span
          className="text-sm text-gray-500 cursor-pointer"
          onClick={() =>
            handleCopy(
              formatCode(linguistUniqueID),
              "Linguist ID copied to clipboard.",
            )
          }
        >
          Linguist ID: {formatCode(linguistUniqueID)}
        </span>
      </Tooltip>
    )
  );
}

/**
 * This component renders a section of a linguist's profile.
 * It uses reactive variables to get the linguist's first name, last name, and active organization.
 * It also fetches the linguist's unique ID.
 * The component renders an avatar, a page heading, and the linguist's unique ID.
 */
export function LinguistProfileSection() {
  const firstName = useReactiveVar(userFirstNameVar);
  const lastName = useReactiveVar(userLastNameVar);
  const activeOrg = useReactiveVar(activeOrgVar);
  const { linguistUniqueID, isLangoCrowdPartner } = useFetchLinguistID();

  const fullName = `${firstName ?? ""} ${lastName ?? ""}`;
  const [isHovered, setIsHovered] = useState(false);
  const { currentUser } = useFetchCurrentUser();
  const { handleUploadPicture, loading } = useUploadUserPicture();
  const { handleDeletePicture } = useDeleteUserPicture();
  const description = fullName === activeOrg?.name ? "" : activeOrg?.name;

  return (
    <>
      <Flex align="center" extraClasses="flex-col my-4 space-y-3">
        <div
          className="relative rounded-full"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Avatar
            placeholderInitials={getFirstLastCharacters(fullName)}
            extraClasses="w-36 h-36 text-6xl text-black font-bold"
            imageUrl={currentUser?.picture?.url}
            editMode={isHovered}
            loading={loading}
            onUpload={() => document.getElementById("fileInput").click()}
            onDelete={handleDeletePicture}
          />
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            onChange={handleUploadPicture}
            style={{ display: "none" }}
          />
        </div>
        <BasePageHeading heading={fullName} description={description} />
        {isLangoCrowdPartner && <LangoCrowdSubtitle />}
        {renderLinguistID(linguistUniqueID)}
      </Flex>
      <Divider className="w-full my-6" />
    </>
  );
}
