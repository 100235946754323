import classNames from "@lango/common/classnames";
import LangoLogo from "@lango/common/components/langoLogo";

import useFetchOrgCustomLogoURL from "./useFetchCustomLogoURL";
import { LazyImage } from "@lango/common/components/LazyImage";

const LoadHeaderCustomLogo = ({ orgId, extraClasses }) => {
  const { url, error } = useFetchOrgCustomLogoURL(orgId);
  return error ? (
    <LangoLogo />
  ) : url ? (
    <LazyImage
      src={url}
      className={classNames("block h-8", extraClasses)}
      alt={"Logo"}
    />
  ) : null;
};

export default LoadHeaderCustomLogo;
