import { gql } from "@apollo/client";

export const FILE_DATA_FIELDS = gql`
  fragment FileDataFields on File {
    id
    isEstimationCompleted
    estimatedWordCount
  }
`;

export const FILE_INFO_FIELDS = gql`
  fragment FileInfoFields on File {
    id
    name
    fileType {
      id
      name
    }
  }
`;

export const CURRENCY = gql`
  fragment currency on Currency {
    id
    name
  }
`;

export const VENDOR_INFO = gql`
  fragment vendorInfo on VendorProfile {
    id
    name
  }
`;

export const RATE_UNIT = gql`
  fragment rateUnit on RateUnit {
    id
    name
  }
`;
