import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { GET_LSP_ORG_JOB_TYPES } from "@lango/common/queries";

// prepareServiceLines function is used to prepare the data that is returned from the query
const prepareServiceLines = (data) => {
  const jobTypes = data?.allJobTypes?.map((jobType) => {
    const enabled = data?.findVendorProfileJobTypesByLspID?.some(
      (vpJobType) => vpJobType?.jobType?.value === jobType.id
    );
    return { ...jobType, enabled };
  });

  return { jobTypes }
};

const useFetchServiceLines = (organizationID, lspID, types) => {
  const { data, loading, error } = useQuery(GET_LSP_ORG_JOB_TYPES, {
    variables: { organizationID, lspID, types },
    fetchPolicy: "no-cache",
  });

  const memoizedData = useMemo(() => prepareServiceLines(data), [data]);

  return { values: memoizedData, loading, error };
};

export default useFetchServiceLines;
