import { SearchIcon } from "@heroicons/react/outline";
import _debounce from "lodash/debounce";
import { useCallback } from "react";

/**
 * SearchBar component that provides a search input field.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.filters - The current filters applied.
 * @param {Function} props.setFilters - Function to update the filters.
 *
 * @returns {JSX.Element} The rendered search bar component.
 */
export const SearchBar = ({ filters, setFilters }) => {
  const debouncedSearch = useCallback(
    _debounce((searchText) => {
      setFilters({
        ...filters,
        customFilters: [{ key: "search", values: [searchText], like: true }],
      });
    }, 500),
    [filters, setFilters],
  );

  return (
    <div className="sm:max-w-x flex items-center">
      <div className="w-full">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon className="text-gray inline text-gray-400" width="20px" />
        </div>
        <input
          id="search"
          name="search"
          className="focus:outline-none block w-full rounded-full border-transparent bg-white-100 py-2.5 pl-10 pr-3 placeholder-gray-400 focus:border-gray-700 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-700 sm:text-sm"
          placeholder="Search"
          type="search"
          onChange={(e) => debouncedSearch(e.target.value)}
        />
      </div>
    </div>
  );
};
