import React from "react";

const StartBar = ({ color, text, zIndex = 1, textColor, width = 385 }) => {
  return (
    <div style={{ zIndex }}>
      <svg
        width={width}
        height="48"
        viewBox={`0 0 ${width} 48`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5H${width - 17.114}L${width - 0.611} 24L${width - 17.114} 47.5H20C9.23043 47.5 0.5 38.7696 0.5 28V20Z`}
          fill={color}
          stroke="#E9E9E9"
        />
        <text
          x={width / 2}
          y="50%"
          fill={textColor}
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="16"
        >
          {text}
        </text>
      </svg>
    </div>
  );
};

export default StartBar;
