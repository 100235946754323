import { CloudUploadIcon } from "@heroicons/react/solid";
import classNames from "@lango/common/classnames";
import { useField } from "formik";
import { useDropzone } from "react-dropzone";

export const FileUploadStatuses = {
  NO_FILE: "NO_FILE",
  KEEP_FILE: "KEEP_FILE",
  REPLACE_FILE: "REPLACE_FILE",
  DELETE_FILE: "DELETE FILE",
};

export const FileUploadInput = ({
  label,
  subLabel,
  showLabel,
  extraClasses,
  size,
  infoText,
  currencyOpts,
  showImagePreview,
  icon,
  type,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const onDrop = (uploadedFiles) => {
    setValue({
      status: FileUploadStatuses.REPLACE_FILE,
      file: uploadedFiles[0],
    });
  };
  const onRemove = () => {
    setValue({
      status: FileUploadStatuses.DELETE_FILE,
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: props.maxSize,
    minSize: props.minSize,
    accept: props.accept,
    autoFocus: props.autoFocus,
  });

  const inputClassNames = classNames(
    "grid cursor-pointer place-items-center rounded-lg border border-dashed border-gray-400 p-8",
    extraClasses,
    { "border-solid border-black": isDragActive },
  );

  const imagePreviewUrl =
    showImagePreview &&
    field.value &&
    (field.value.status === FileUploadStatuses.KEEP_FILE ||
      field.value.status === FileUploadStatuses.REPLACE_FILE) &&
    field.value.file
      ? URL.createObjectURL(field.value.file)
      : field.value.objectUrl;

  return (
    <div className="sm:col-span-6">
      {imagePreviewUrl && (
        <div className="mt-1 mb-3 flex items-center">
          <img
            src={imagePreviewUrl}
            className={classNames("block h-14")}
            alt={"Uploaded Image Preview"}
          />
          <div className="ml-4 flex">
            <button
              type="button"
              className="ml-3 inline-block cursor-pointer rounded-md border py-2 px-3 text-sm font-medium hover:bg-gray-100"
              onClick={onRemove}
            >
              Remove
            </button>
          </div>
        </div>
      )}
      {!imagePreviewUrl && (
        <>
          <label htmlFor={props.id || props.name}>
            <div className={inputClassNames} {...getRootProps()}>
              <CloudUploadIcon className="w-12" />
              <p className="text-lg font-semibold text-gray-700">
                {label || "Upload File"}
              </p>
              {subLabel && <p className="text-sm text-gray-500">{subLabel}</p>}
              <input {...field} {...getInputProps()} value={undefined} />
            </div>
          </label>
          {meta.touched && meta.error && (
            <p
              className="ml-3 mt-2 h-1 text-sm text-red-600"
              id={props.errorMsgID}
            >
              {meta.error}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default FileUploadInput;
