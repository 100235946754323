import { redirect } from "react-router-dom";

import {
  authStateVar,
  getLoginPath,
  getLogoutPath,
  pendingInvitationsVar,
  userOrgsVar,
} from "../apollo/localState";

export const logoutPageLoader = async ({ request }) => {
  // If user is not signed in, then send them from the logout page to the login page directly.
  const authState = authStateVar();
  if (authState !== "signedIn") {
    return redirect(
      getLoginPath(new URL(request.url).searchParams.get("from"))
    );
  }
  return null;
};

export const protectedPageLoader = ({ request }) => {
  // If the user is not logged in, does NOT have any organizations, and tries to
  // access a protected page, we redirect them to `/login` with a `from` parameter
  // that allows login to redirect back to this page upon successful authentication.
  const authState = authStateVar();
  const userOrgs = userOrgsVar();
  const pendingInvitations = pendingInvitationsVar();
  if (authState !== "signedIn") {
    return redirect(getLogoutPath(new URL(request.url).pathname));
  }
  if ((!Array.isArray(userOrgs) || userOrgs.length === 0) && pendingInvitations?.length > 0) {
    return redirect("/invitations");
  }
  return null;
};

export const authPageLoader = ({ request }) => {
  // If the user tries to access a login page when already logged in, we redirect them to the root page.
  const authState = authStateVar();
  if (authState === "signedIn") {
    return redirect("/");
  }
  return null;
};


export const invitationPageLoader = ({ request }) => {
  // if the user is logged in, they should be able to access the invtation page
  const authState = authStateVar();
  if (authState !== "signedIn") {
    return redirect(getLogoutPath(new URL(request.url).pathname));
  }
  return null;
};
