import { WebAuth } from "auth0-js";

import env from "@lango/common/env";

export const webAuth = new WebAuth({
  domain: env.auth0Domain,
  clientID: env.auth0ClientID,
  redirectUri: env.signInRedirectURL,
  scope: env.auth0Scope,
  responseType: env.auth0ResponseType,
});

export const signInWithAuth0 = (
  username,
  password,
  state,
  onError = () => {}
) => {
  webAuth.login(
    {
      username,
      password,
      state,
      realm: env.auth0Realm,
    },
    (err) => onError(err.description || err.error)
  );
};

export const signOutFromAuth0 = () =>
  webAuth.logout({
    clientID: env.auth0ClientID,
    returnTo: env.signoutRedirectURL,
  });
