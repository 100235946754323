import PropTypes from "prop-types";
import { memo } from "react";

import cx from "@lango/common/classnames";

const justifyClassesMap = {
  between: "justify-between",
  start: "justify-start",
  center: "justify-center",
  end: "justify-end",
  normal: "justify-normal",
  around: "justify-around",
};

const alignClassesMap = {
  start: "items-start",
  center: "items-center",
  end: "items-end",
};

const Flex = ({ extraClasses = "", justify, align, children, onClick }) => {
  const className = cx(
    "flex",
    justifyClassesMap[justify],
    alignClassesMap[align],
    extraClasses,
  );
  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

Flex.propTypes = {
  align: PropTypes.oneOf(["start", "center", "end"]),
  justify: PropTypes.oneOf([
    "between",
    "start",
    "center",
    "end",
    "normal",
    "around",
  ]),
  onClick: PropTypes.func,
};

export default memo(Flex);
