import { WithLoader } from "@lango/common/components";
import Table from "@lango/common/features/tables/components/Table";
import { useGetPaginatedOrgNotifications } from "@lango/common/hooks";

import { columns } from "./columns";

export const PaginatedOrgNotifications = () => {
  const { loading, error, ...props } = useGetPaginatedOrgNotifications();
  return (
    <>
      <Table
        columns={columns}
        enablePagination
        defaultPageSize={10}
        {...props}
      />
      <WithLoader error={error} loading={loading} />
    </>
  );
};
