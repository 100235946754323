import { Hub } from "aws-amplify/utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { clearHubspotConversation } from "@lango/common//components/HubspotChatWidget/utils";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import { useLogin } from "@lango/common/features/auth/hooks";
import { AUTH_ROUTES } from "@lango/common/routes";
import { useAcceptClientInviteCtx } from "@lango/common/context";

const LoginWrapper = () => {
  const navigate = useNavigate();
  const { handleInitializeFederatedUser } = useLogin();
  const { handleChangeVerifyResponse } = useAcceptClientInviteCtx();

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", async (data) => {
      switch (data.payload.event) {
        case "customOAuthState":
          try {
            const customState = JSON.parse(data.payload.data);
            customState?.code // invite code
              ? handleChangeVerifyResponse(customState, "link")
              : await handleInitializeFederatedUser(customState);
            clearHubspotConversation();
          } catch (error) {
            console.error(error);
            toastError(error?.message);
            navigate(AUTH_ROUTES.LOGOUT);
          }
          break;
      }
    });
    return () => unsubscribe;
  }, []);

  return null;
};

export default LoginWrapper;
