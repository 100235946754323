import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { vendorOnboardingStep1Request } from "@lango/common/request";
import {
  VENDOR_ONBOARD,
  VENDOR_ONBOARDING_STATUS,
} from "@lango/common/queries";
import { toastSuccess, toastWarning } from "@lango/common/features";
import { useAcceptVendorInviteCtx } from "@lango/common/context";

import { VENDOR_ROUTES } from "../routes";
import { userFirstNameVar, userGenderVar, userLastNameVar } from "../apollo";

const refetchQueries = {
  refetchQueries: [VENDOR_ONBOARDING_STATUS],
};

function updateActiveUserName(values) {
  values?.firstName && userFirstNameVar(values.firstName);
  values?.lastName && userLastNameVar(values.lastName);
  values?.gender && userGenderVar(values.gender)
}

export const useVendorOnboardSteps = () => {
  const navigate = useNavigate();

  const [step1Mutation] = useMutation(VENDOR_ONBOARD.STEP1, refetchQueries);
  const [step2Mutation] = useMutation(VENDOR_ONBOARD.STEP2, refetchQueries);
  const [step3Mutation] = useMutation(VENDOR_ONBOARD.STEP3, refetchQueries);
  const [individualStep1Mutation] = useMutation(
    VENDOR_ONBOARD.INDIVIDUAL_STEP1,
    refetchQueries,
  );
  const [individualStep2Mutation] = useMutation(
    VENDOR_ONBOARD.INDIVIDUAL_STEP2,
    refetchQueries,
  );

  const { onboardingData, handleChangeInviteStep } = useAcceptVendorInviteCtx();
  const vendorProfileID = onboardingData?.vendorProfileID;

  async function handleOnboardingStep1(/** @type {any} */ values) {
    try {
      await step1Mutation({
        variables: {
          input: vendorOnboardingStep1Request({ ...values, vendorProfileID }),
        },
      });
      handleChangeInviteStep("step2");
    } catch (error) {
      toastWarning("Failed to perform vendor onboarding step 1");
      console.error(error);
    }
  }

  async function handleOnboardingStep2(/** @type {any} */ values) {
    try {
      await step2Mutation({
        variables: { input: { ...values, vendorProfileID } },
      });
      handleChangeInviteStep("step3");
    } catch (error) {
      toastWarning("Failed to perform vendor onboarding step 2");
      console.error(error);
    }
  }

  async function handleOnboardingStep3() {
    try {
      await step3Mutation({
        variables: { input: { vendorProfileID } },
      });
      toastSuccess("Vendor onboarding completed successfully");
      navigate(VENDOR_ROUTES.REQUESTS);
    } catch (error) {
      toastWarning("Failed to perform vendor onboarding step 3");
      console.error(error);
    }
  }

  // individuals
  async function handleIndividualOnboardingStep1(/** @type {any} */ values) {
    try {
      const { email, gender, ...rest } = values;
      await individualStep1Mutation({
        variables: {
          input: { ...rest, genderID: gender.value, vendorProfileID },
        },
      });
      handleChangeInviteStep("step2");
      updateActiveUserName(values);
    } catch (error) {
      toastWarning("Failed to perform vendor onboarding step 1");
      console.error(error);
    }
  }

  async function handleIndividualOnboardingStep2(/** @type {any} */ values) {
    const jobTypeIDs = Object.keys(values?.selectedJobTypes).filter(
      (key) => values?.selectedJobTypes[key],
    );
    if (!jobTypeIDs.length) {
      toastWarning("You must select at least one service line.");
      return;
    }
    try {
      await individualStep2Mutation({
        variables: { input: { vendorProfileID, jobTypeIDs } },
      });
      handleChangeInviteStep("step3");
    } catch (e) {
      console.error(e);
      toastWarning("Failed to perform vendor onboarding step 3");
    }
  }

  return {
    handleOnboardingStep1,
    handleOnboardingStep2,
    handleOnboardingStep3,
    handleIndividualOnboardingStep1,
    handleIndividualOnboardingStep2,
  };
};
