import React from "react";

import clx from "@lango/common/classnames";

const SettingsTabContainer = (props) => {
  return (
    <div
      className={clx("mb-4 rounded-xl bg-white p-8 shadow-md", props.className)}
    >
      {props.children}
    </div>
  );
};

export default SettingsTabContainer;
