import { useFormikContext } from "formik";
import { Flex, FormControl } from "@lango/common/features";

export function Action() {
  const isSubmitting = useFormikContext().isSubmitting;
  return (
    <Flex align="center" justify="end">
      <FormControl
        control="button"
        type="submit"
        text="Save & continue"
        disabled={isSubmitting}
        showLoader={isSubmitting}
      />
    </Flex>
  );
}
