import { ToastContainer as ToastifyContainer } from "react-toastify";
import { useLocation } from 'react-router-dom';
import { checkTriggerURL } from "../functions/toast";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

const toastContextClass = {
  success: "toast-success bg-lango-success-green text-black",
  error: "toast-error bg-lango-error-red text-black",
  warning: "toast-warning bg-lango-light-orange text-black",
  info: "toast-info bg-gray-600",
  default: "toast-default bg-gray-600",
  dark: "toast-dark bg-white-600 font-gray-300",
};

const toastClassName = ({ type }) =>
  toastContextClass[type || "default"] +
  " relative flex p-2 min-h-10 justify-between overflow-hidden cursor-pointer mt-2";

const ToastContainer = (props) => {
  const location = useLocation();
 
  useEffect(() => {
    checkTriggerURL(location);
  }, [location]);
  
  return (
    <ToastifyContainer
      icon={false}
      bodyClassName="text-sm font-white font-med block p-3"
      toastClassName={toastClassName}
      {...props}
    />);
};

export default ToastContainer;
