import Proptypes from "prop-types";
import React from "react";
import { MdModeEdit, MdDelete } from "react-icons/md";

import classNames from "@lango/common/classnames";
import { Loader } from "@lango/common/components";
import { LazyImage } from "./LazyImage";
import { Flex } from "../features";

/**
 * Avatar component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.placeholderInitials - The initials to display when no image is available.
 * @param {string} props.extraClasses - Additional CSS classes for the avatar container.
 * @param {string} [props.iconClasses] - Additional CSS classes for the placeholder initials.
 * @param {string} [props.imageUrl] - The URL of the image to display as the avatar.
 * @param {boolean} [props.editMode] - Indicates whether the avatar is in edit mode.
 * @param {boolean} [props.loading] - Indicates whether the avatar is in loading state.
 * @param {import("react").MouseEventHandler<SVGElement>} [props.onUpload] - Uploads the user profile picture
 * @param {import("react").MouseEventHandler<SVGElement>} [props.onDelete] - Deletes the user profile picture
 * @returns {JSX.Element} The Avatar component.
 */
const Avatar = ({
  placeholderInitials,
  extraClasses,
  iconClasses = "",
  imageUrl,
  editMode = false,
  loading = false,
  onDelete,
  onUpload,
}) => {
  const avatarClasses = classNames(
    "relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600",
    extraClasses,
  );

  const hoverOpacity = `transition-opacity duration-300 ${editMode || loading ? "opacity-30" : "opacity-100"}`;
  const iconClassNames = classNames(
    "font-medium text-gray-600 dark:text-gray-300",
    iconClasses,
  );

  return (
    <>
      {imageUrl ? (
        <>
          <LazyImage
            className={`${avatarClasses} ${hoverOpacity}`}
            src={imageUrl}
            alt="avatar"
          />
        </>
      ) : (
        <div className={avatarClasses}>
          <span className={`${iconClassNames} ${hoverOpacity}`}>
            {placeholderInitials}
          </span>
        </div>
      )}
      <RenderOverlays
        editMode={editMode}
        loading={loading}
        imageUrl={imageUrl}
        onDelete={onDelete}
        onUpload={onUpload}
      />
    </>
  );
};

/**
 * RenderOverlays component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.editMode - The initials to display when no image is available.
 * @param {boolean} props.loading - Additional CSS classes for the avatar container.
 * @param {string} [props.imageUrl] - Additional CSS classes for the placeholder initials.
 * @param {import("react").MouseEventHandler<SVGElement>} [props.onUpload] - Uploads the user profile picture
 * @param {import("react").MouseEventHandler<SVGElement>} [props.onDelete] - Deletes the user profile picture
 * @returns {JSX.Element} The RenderOverlays component.
 */
const RenderOverlays = ({
  editMode,
  loading,
  imageUrl = "",
  onUpload,
  onDelete,
}) => {
  if (loading)
    return (
      <Flex
        justify="center"
        align="center"
        extraClasses="absolute inset-0 rounded-full text-black text-xlI"
      >
        <Loader
          message="Uploading..."
          extraClasses={"text-black"}
          extraSpinnerClasses={"text-black"}
        />
      </Flex>
    );
  if (editMode)
    return (
      <Flex
        align="center"
        justify="center"
        extraClasses="absolute inset-0 rounded-full text-black font-medium text-3xl"
      >
        <MdModeEdit className="cursor-pointer" onClick={onUpload} />
        {imageUrl && (
          <MdDelete
            className="cursor-pointer text-red-500 ml-2"
            onClick={onDelete}
          />
        )}
      </Flex>
    );
};

Avatar.propsTypes = {
  placeholderInitials: Proptypes.string,
  extraClasses: Proptypes.string,
  iconClasses: Proptypes.string,
  imageUrl: Proptypes.string,
  isHovered: Proptypes.bool,
  onUpload: Proptypes.func,
  onDelete: Proptypes.func,
};

export default Avatar;
