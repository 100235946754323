import { useMutation } from "@apollo/client";
import React, { useCallback } from "react";

import { APPROVED, DECLINED, VENDOR_APP } from "@lango/common/constants";
import { toastError, toastSuccess } from "@lango/common/features";
import {
  ALL_VENDOR_PROFILE_LANGUAGE,
  ALL_VENDOR_UNAPPROVED_QUALIFICATIONS,
  FETCH_VENDOR_PROFILE_QUALIFICATION,
  UPDATE_QUALIFICATION_STATUS,
} from "@lango/common/queries";
import environmentVars from "@lango/common/env";

const appName = environmentVars.appName;

export const useUpdateQualificationStatus = () => {
  const [updateQualification] = useMutation(UPDATE_QUALIFICATION_STATUS, {
    refetchQueries: [
      ALL_VENDOR_UNAPPROVED_QUALIFICATIONS,
      FETCH_VENDOR_PROFILE_QUALIFICATION,
      ...(appName === VENDOR_APP ? [ALL_VENDOR_PROFILE_LANGUAGE] : []),
    ],
  });

  const handleUpdateQualification = useCallback(
    async (profileIDs, status) => {
      try {
        await updateQualification({ variables: { profileIDs, status, appName } });
        if (status === APPROVED) {
          toastSuccess("Vendor qualifications approved successfully");
        } else if (status === DECLINED) {
          toastSuccess("Vendor qualifications declined successfully");
        } else {
          toastSuccess("Re-approval request sent successfully");
        }
      } catch (error) {
        toastError("There was an error approving qualification");
        console.error(error);
      }
    },
    [updateQualification],
  );

  return { handleUpdateQualification };
};
