import { useField } from "formik";
import { useMutation } from "@apollo/client";

import { UPLOAD_CLIENT_FILE } from "@lango/common/queries";
import { toastError } from "@lango/common/features";

import { useAcceptClientInviteCtx } from "@lango/common/context";

export const useUploadClientFile = () => {
  const { onboardingData } = useAcceptClientInviteCtx();
  const [, , { setValue }] = useField("taxFormID");

  const [uploadClientFile, { loading, error }] =
    useMutation(UPLOAD_CLIENT_FILE);

  function handleUploadClientFile(files) {
    uploadClientFile({
      variables: {
        lspID: onboardingData?.lspID,
        organizationID: onboardingData?.organizationID,
        files: files,
      },
    })
      .then(({ data }) => {
        setValue(data?.clientFileUpload?.[0]?.id);
      })
      .catch((error) => {
        toastError("There was an error uploading the file.");
        console.error(error);
      });
  }

  return { handleUploadClientFile, loading, error };
};
