import { useQuery, useReactiveVar } from "@apollo/client";

import { FIND_LINGUIST_ID } from "../queries";
import { activeOrgVar, userIDVar } from "../apollo";

export function useFetchLinguistID() {
  const userID = useReactiveVar(userIDVar);
  const org = useReactiveVar(activeOrgVar);

  const { loading, data, error } = useQuery(FIND_LINGUIST_ID, {
    variables: {
      userID,
      vendorID: org?.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  return {
    loading,
    error,
    linguistUniqueID: data?.findLinguistProfile?.linguistUniqueID,
    isLangoCrowdPartner: data?.crowdVendorProfileExistsByOrg,
  };
}
