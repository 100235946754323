import { Formik } from "formik";

import { ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY } from "@lango/common/constants";
import WithLoader from "@lango/common/components/WithLoader";

import useFetchServiceLines from "./fetchServiceLines";
import ServiceLineFields from "./serviceLineFields";

const ServiceLines = ({ organizationID, lspID }) => {
  const types = [ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY];

  const { values, loading, error } = useFetchServiceLines(
    organizationID,
    lspID,
    types,
  );

  return (
    <WithLoader loading={loading} error={error}>
      <Formik initialValues={values} onSubmit={() => {}}>
        <ServiceLineFields organizationID={organizationID} lspID={lspID} />
      </Formik>
    </WithLoader>
  );
};

export default ServiceLines;
