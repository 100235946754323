import { useMutation } from "@apollo/client";
import { toastError, toastSuccess } from "../features";
import { USER_NOTIFICATIONS } from "../queries";

export const useMarkNotificationsAsRead = () => {
  const [markNotifications] = useMutation(
    USER_NOTIFICATIONS.MARK_NOTIFICATIONS_READ,
    {
      refetchQueries: [USER_NOTIFICATIONS.GET],
    },
  );

  const handleMarkNotificationsAsRead = async () => {
    try {
      await markNotifications();
      toastSuccess("Notifications marked as read");
    } catch (error) {
      toastError(error?.message);
    }
  };
  return { handleMarkNotificationsAsRead };
};
