import { useState } from "react";

import { BasePageHeading, Button, Flex } from "@lango/common/features";
import {
  useMarkNotificationsAsRead,
  useReadAllOrgNotifications,
} from "@lango/common/hooks";

import { NotificationTabs } from "./Tabs";

const NOTIFICATION_TABS = ["user", "organization"];

/**
 * NotificationPanel Component
 *
 * A panel that displays a list of notifications for the organization. It includes a heading,
 * a "Mark all as read" button, and a "View all notifications" button. The notifications
 * are displayed in a scrollable area, and each notification can be clicked to trigger
 * a callback function. The panel also handles transitions for opening and closing.
 *
 * @param {Object} props - The props for the NotificationPanel component.
 * @param {Array} props.organizationNotifications - The array of organization notification objects to be displayed.
 * @param {Array} props.userNotifications - The array of user notification objects to be displayed.
 * @param {function} props.closePanel - A function to close the panel
 * @returns {React.ReactNode} The rendered NotificationPanel component.
 */
export const NotificationList = ({
  organizationNotifications,
  userNotifications,
  closePanel,
}) => {
  const { handleReadAllNotifications } = useReadAllOrgNotifications();
  const { handleMarkNotificationsAsRead } = useMarkNotificationsAsRead();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleReadAll = () => {
    NOTIFICATION_TABS[activeTabIndex] === "user"
      ? handleMarkNotificationsAsRead()
      : handleReadAllNotifications();
  };

  const hasUnreadNotifications = () => {
    const notfications =
      NOTIFICATION_TABS[activeTabIndex] === "user"
        ? userNotifications
        : organizationNotifications;
    return notfications.some((n) => !n.isRead);
  };

  return (
    <div className="flex-1 overflow-y-auto">
      <Flex extraClasses="p-6" justify="between" align="center">
        {/* Panel header with the title and "Mark all as read" button */}
        <BasePageHeading heading="Notifications" />
        <Button
          text="Mark all as read"
          textButton
          onClick={() => {
            handleReadAll();
            closePanel();
          }}
          disabled={!hasUnreadNotifications()}
        />
      </Flex>
      <NotificationTabs
        organizationNotifications={organizationNotifications}
        userNotifications={userNotifications}
        closePanel={closePanel}
        setActiveTab={setActiveTabIndex}
      />
    </div>
  );
};
