import { useQuery, useReactiveVar } from "@apollo/client";
import { VENDOR_ONBOARDING_STATUS } from "@lango/common/queries";

import { activeLSPVar, activeOrgVar } from "../apollo";

export function useFetchVendorOnboardingStatus(onComplete = (_val) => {}) {
  const org = useReactiveVar(activeOrgVar);
  const lsp = useReactiveVar(activeLSPVar);

  const { loading, data, error } = useQuery(VENDOR_ONBOARDING_STATUS, {
    variables: { vendorID: org?.id, lspID: lsp?.id },
    fetchPolicy: "cache-and-network",
    skip: lsp?.id === undefined,
    onCompleted: ({ vendorOnboardingStatus }) => {
      vendorOnboardingStatus && onComplete?.(vendorOnboardingStatus);
    },
  });

  return { loading, error, status: data?.vendorOnboardingStatus };
}
