/**
 * Job Types
 * todo: add slugs in the DB and use those instead of the name
 */

// job type names
const REAL_TIME_MACHINE_TRANSLATION_JOB_TYPE = "Real-time Machine Translation";
const LARGE_BATCH_MACHINE_TRANSLATION_JOB_TYPE =
  "Large Batch Machine Translation";
const ON_DEMAND_AUDIO_INTERPRETATION_JOB_TYPE =
  "On-Demand Audio Interpretation";
const ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE =
  "On-Demand Video Interpretation";
const SCHEDULED_OPI_JOB_TYPE = "Scheduled Audio Interpretation";
const SCHEDULED_VRI_JOB_TYPE = "Scheduled Video Interpretation";
const ONSITE_INTERPRETATION = "Onsite Interpretation";
const TRANSLATION_ONLY = "Translation Only";
const PROOFREADING = "Proofreading";
const TRANSLATION_EDITING = "Translation and Editing";
const CERTIFIED_TRANSLATION = "Certified Translation";
const POST_EDITING = "Post Editing of MT";

// job type category names
const MACHINE_TRANSLATION_JOB_TYPE_CATEGORY = "Machine Translation";
const ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY = "On-Demand Interpretation";
const HUMAN_TRANSLATION = "Human Translation";
const INTERPRETING = "Interpreting";

const jobTypeMetadata = {
  [ON_DEMAND_AUDIO_INTERPRETATION_JOB_TYPE]: {
    fullName: "On-demand Audio Interpretation",
    shortName: "On-demand OPI",
    description:
      "Provide clients with quick access to over-the-phone interpreters for real-time assistance.",
    group: INTERPRETING,
  },
  [ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE]: {
    fullName: "On-demand Video Interpretation",
    shortName: "On-demand VRI",
    description:
      "Enable clients to access video remote interpreters instantly for immediate language support.",
    group: INTERPRETING,
  },
  [SCHEDULED_OPI_JOB_TYPE]: {
    fullName: "Scheduled Audio Interpretation",
    shortName: "Scheduled OPI",
    description:
      "Facilitate clients in scheduling over-the-phone interpretation sessions ahead of time.",
    group: INTERPRETING,
  },
  [SCHEDULED_VRI_JOB_TYPE]: {
    fullName: "Scheduled Video Interpretation",
    shortName: "Scheduled VRI",
    description:
      "Allow clients to book video remote interpretation sessions in advance.",
    group: INTERPRETING,
  },
  [ONSITE_INTERPRETATION]: {
    fullName: "Onsite Interpretation",
    shortName: "Onsite Interpretation",
    description:
      "Offer clients the option to book interpreters for in-person language support at their requested location.",
    group: INTERPRETING,
  },

  [TRANSLATION_ONLY]: {
    fullName: "Translation Only",
    shortName: "Translation Only",
    description: "Human Translation per word with quality assurance (draft).",
    group: HUMAN_TRANSLATION,
  },
  [PROOFREADING]: {
    fullName: "Proofreading",
    shortName: "Proofreading",
    description:
      "Human Revision per word with quality assurance (proofreading).",
    group: HUMAN_TRANSLATION,
  },
  [TRANSLATION_EDITING]: {
    fullName: "Translation and Editing",
    shortName: "Translation & Editing",
    description:
      "Human Translation + Revision per word with quality assurance (certified).",
    group: HUMAN_TRANSLATION,
  },
  [CERTIFIED_TRANSLATION]: {
    fullName: "Certified Translation",
    shortName: "Certified Translation",
    description:
      "Human Translation + Revision per word with quality assurance (certified).",
    group: HUMAN_TRANSLATION,
  },
  [POST_EDITING]: {
    fullName: "Post-Editing of MT",
    shortName: "Post-Editing",
    description: "Machine Translation Post-Editing with human in the loop.",
    group: HUMAN_TRANSLATION,
  },

  [REAL_TIME_MACHINE_TRANSLATION_JOB_TYPE]: {
    fullName: "Real-time Machine Translation",
    shortName: "Real-time MT",
    description: "Offer clients realtime machine translation services.",
    group: MACHINE_TRANSLATION_JOB_TYPE_CATEGORY,
  },
  [LARGE_BATCH_MACHINE_TRANSLATION_JOB_TYPE]: {
    fullName: "Large-batch Machine Translation",
    shortName: "Large-batch MT",
    description:
      "Offer clients the ability to run machine translation services against large sets of documents.",
    group: MACHINE_TRANSLATION_JOB_TYPE_CATEGORY,
  },
};

function isMachineTranslationJobType(jobType) {
  return [
    REAL_TIME_MACHINE_TRANSLATION_JOB_TYPE,
    LARGE_BATCH_MACHINE_TRANSLATION_JOB_TYPE,
  ].includes(jobType);
}

function isOnDemandInterpretationJobType(jobType) {
  return [
    ON_DEMAND_AUDIO_INTERPRETATION_JOB_TYPE,
    ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE,
  ].includes(jobType);
}

export {
  CERTIFIED_TRANSLATION,
  HUMAN_TRANSLATION,
  isMachineTranslationJobType,
  isOnDemandInterpretationJobType,
  jobTypeMetadata,
  LARGE_BATCH_MACHINE_TRANSLATION_JOB_TYPE,
  MACHINE_TRANSLATION_JOB_TYPE_CATEGORY,
  ON_DEMAND_AUDIO_INTERPRETATION_JOB_TYPE,
  ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY,
  ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE,
  POST_EDITING,
  PROOFREADING,
  REAL_TIME_MACHINE_TRANSLATION_JOB_TYPE,
  TRANSLATION_EDITING,
  TRANSLATION_ONLY,
};
