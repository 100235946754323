import { useQuery } from "@apollo/client";

import { ALL_GENDERS } from "../../../components/data/queries";

export const useAllGenders = () => {
  const { data, loading, error } = useQuery(ALL_GENDERS);

  return {
    allGenders: data?.allGenders || [],
    loading,
    error,
  };
};
