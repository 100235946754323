import { OfficeBuildingIcon } from "@heroicons/react/solid";
import React from "react";

const ActiveOrg = ({ activeOrgName }) => (
  <div className="bg-gray-50 p-4 text-xs text-gray-500 md:hidden">
    {activeOrgName ? (
      <div className="flex">
        <OfficeBuildingIcon width={4} height={4} />
        <div className="ml-2">{activeOrgName}</div>
      </div>
    ) : (
      <span className="italic text-gray-400">No active company</span>
    )}
  </div>
);

export default ActiveOrg;
