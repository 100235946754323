import * as yup from "yup";

const maxAddressLength = 255;

export const validateAddress = yup
  .string()
  .max(
    maxAddressLength,
    `Address should not exceed ${maxAddressLength} characters`,
  )
  .required("Address is required");

export const validateAddressDetails = yup
  .string()
  .nullable()
  .notRequired()
  .max(
    maxAddressLength,
    `Address details should not exceed ${maxAddressLength} characters`,
  );

export const validateState = yup
  .string()
  .required("State is required")
  .max(25, "State can be a maximum of 25 characters");
