import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { toastError } from "@lango/common/features/alerts/functions/toast";
import { FORGOT_PASSWORD } from "@lango/common/queries";
import { AUTH_ROUTES } from "@lango/common/routes";

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    context: { clientName: "public" },
  });

  const handleForgotPassword = async ({ username }) => {
    try {
      await forgotPassword({ variables: { email: username } });
      navigate(AUTH_ROUTES.CONFIRM_FORGOT_PASSWORD, {
        state: { username: username },
      });
    } catch (error) {
      toastError("There was a problem resetting your password.");
      console.error(error);
    }
  };

  return { handleForgotPassword };
};
