import { ChevronDownIcon, UserIcon } from "@heroicons/react/solid";
import { Fragment } from "react";

import { LazyImage } from "@lango/common/components/LazyImage";
import { useFetchCurrentUser } from "@lango/common/hooks/findCurrentUser";
const UserProfile = ({
  userFirstName,
  userLastName,
  activeOrgName,
  unreadNotificationsCount,
}) => {
  const { currentUser, loading } = useFetchCurrentUser();
  const displayOrgName = activeOrgName != userFirstName + " " + userLastName;

  return (
    <Fragment>
      <div className="mx-1.5 h-9 w-9 rounded-full bg-gray-100 md:mx-3 md:h-9 md:w-9">
        {!loading && currentUser?.picture?.url ? (
          <LazyImage
            src={currentUser.picture.url}
            alt="User Profile"
            className="h-full w-full rounded-full object-cover"
          />
        ) : (
          <UserIcon className="mx-auto mt-0.5 h-7 w-7 md:mt-0 md:h-7 md:w-7" />
        )}
        {unreadNotificationsCount > 0 && (
          <span className="absolute top-1 left-9 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white" />
        )}
      </div>
      <div className="text-sm">
        <span className="hidden sm:inline">
          <strong>
            {userFirstName} {userLastName}
          </strong>
        </span>
        <span className="inline-block align-top">
          <ChevronDownIcon
            className="mr-2 h-5 w-5 align-top group-hover:text-gray-700 sm:ml-2 sm:mr-5"
            aria-hidden="true"
          />
        </span>
        {displayOrgName && (
          <div className="hidden text-left sm:block">
            {activeOrgName ?? (
              <span className={"italic text-gray-500"}>No active company</span>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default UserProfile;
