import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

import { toastError } from "@lango/common/features";
import { PAGINATED_ORG_USERS } from "@lango/common/queries";

export const useGetUserList = (organizationID, inviteType) => {
  const [getUserList, { data: userList }] = useLazyQuery(PAGINATED_ORG_USERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      getUserList({
        variables: {
          organizationID,
          inviteType,
          offset: pageIndex * pageSize,
          pageLimit: pageSize,
          ...prepareSortBy(sortBy),
        },
      }).catch((err) => {
        toastError("There was an error getting the user list");
        console.error(err);
      });
    },
    [getUserList, organizationID],
  );
  return {
    fetchData,
    data: userList?.paginatedUsersForOrganization?.users || [],
    totalRecords: userList?.paginatedUsersForOrganization?.totalRecords,
  };
};

const prepareSortBy = (sortBy) =>
  sortBy?.length > 0
    ? { sortBy: sortBy[0]?.id, sortDir: sortBy[0]?.desc ? "DESC" : "ASC" }
    : { sortBy: null, sortDir: null };
