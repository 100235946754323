import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

import classNames from "@lango/common/classnames";

const PlainTable = ({ columns, data, extraClasses }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <div className={classNames("flex flex-col", extraClasses)}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="border-b border-gray-200 shadow sm:rounded-lg">
            <table className="simple-table min-w-full divide-y">
              <thead
                className={
                  "bg-gray-300/20 text-left text-xs font-bold uppercase"
                }
              >
                {table.getHeaderGroups().map((headerGroup, i) => (
                  <tr key={i}>
                    {headerGroup.headers.map((header, headerIndex) => (
                      <th
                        scope="col"
                        className={
                          "border-r-2 border-white px-2 py-3 tracking-wider md:px-4  md:py-5 " +
                          header.classNames
                        }
                        key={headerIndex}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y divide-gray-100 bg-white">
                {table.getRowModel().rows.map((row, i) => (
                  <tr key={i}>
                    {row.getVisibleCells().map((cell, index) => (
                      <td key={index} className="whitespace-nowrap px-4 py-4">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlainTable;
