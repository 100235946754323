// Update a single vendor profile job type

import { useMutation } from "@apollo/client";

import {
  toastError,
  toastSuccess,
} from "@lango/common/features/alerts/functions/toast";
import { UPDATE_VENDOR_PROFILE_JOB_TYPE } from "@lango/common/queries";

const useUpdateVendorProfileJobTypes = () => {

  const [updateVendorProfileServiceLines, { loading }] = useMutation(UPDATE_VENDOR_PROFILE_JOB_TYPE);

  const updateVendorProfileJobType = async (props) => {
    try {
      const request = updateVendorProfileJobTypeRequest({...props});

      await updateVendorProfileServiceLines(request);
      toastSuccess("Updated vendor profile service lines");
    } catch (error) {
      toastError("There was an error updating the vendor profile service lines");
    }
  };

  return { updateVendorProfileJobType, loading };
};

function updateVendorProfileJobTypeRequest({organizationID, lspID, jobTypeID, enabled}) {
  return {
    variables: {
      input: {
        organizationID,
        lspID,
        jobTypeID,
        enabled,
      },
    },
  };
}

export default useUpdateVendorProfileJobTypes;
