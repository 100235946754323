import { gql } from "@apollo/client";

export const BILLABLE_LINE_ITEM = gql`
  fragment BillableLineItemFields on LineItem {
    id
    serviceType
    description
    adjustmentDescription
    adjustmentQuantity
    quantity
    rate
    serviceDate
    rateUnits
    billable {
      id
      number
      filename
      recipientOrgName
      recipientContactName
      recipientAddress
      recipientEmail
      recipientPhone
      instructions
      currency
    }
  }
`;
