import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { ACCEPT_CLIENT_INVITATION } from "@lango/common/queries";
import { toastSuccess } from "@lango/common/features";
import { useAcceptClientInviteCtx } from "@lango/common/context";

import { initializeUserData, invitedLSPVar } from "../apollo";
import { useProvisionUserAndRefetchLSPs } from "../features/auth/hooks";
import { useHandleAcceptInviteError } from "./acceptVendorInvitation";

export const useAcceptClientInvitation = () => {
  const [acceptInvitation, { loading: loadingAcceptInvite }] = useMutation(
    ACCEPT_CLIENT_INVITATION,
  );

  const navigate = useNavigate();
  const { redirectMode, handleChangeOnboardingData } =
    useAcceptClientInviteCtx();
  const { findOrRegisterUser, loading } = useProvisionUserAndRefetchLSPs();
  const handleError = useHandleAcceptInviteError();

  const handleAcceptInvite = useCallback(
    async (code, orgID) => {
      try {
        const { data } = await acceptInvitation({
          variables: { code, orgID },
        });
        const response = data?.acceptClientInvitation;
        const lspID = response?.lspID;
        lspID && invitedLSPVar(lspID);
        toastSuccess("Invitation Accepted");
        const userData = await findOrRegisterUser({ organizationID: orgID });
        initializeUserData(userData);
        const nextStep = response?.nextStep;
        ["step1", "step2", "step3", "step4"].includes(nextStep)
          ? handleChangeOnboardingData(response, nextStep)
          : navigate("/projects");
      } catch (error) {
        handleError(error, redirectMode);
      }
    },
    [acceptInvitation],
  );

  return {
    handleAcceptInvite,
    loading: loadingAcceptInvite || loading,
  };
};
