import {
  CERTIFIED_TRANSLATION,
  LARGE_BATCH_MACHINE_TRANSLATION_JOB_TYPE,
  ON_DEMAND_AUDIO_INTERPRETATION_JOB_TYPE,
  ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE,
  POST_EDITING,
  PROOFREADING,
  REAL_TIME_MACHINE_TRANSLATION_JOB_TYPE,
  TRANSLATION_EDITING,
  TRANSLATION_ONLY,
} from "@lango/common/constants";

import { useFetchJobFilters } from "@lango/common/hooks/useFetchJobFilters";

export const useJobsQuickFilters = ({includeMT = true}) => {
  const { jobTypes } = useFetchJobFilters();
  const interpretationFilters = [
    {
      key: "requestType",
      values: jobTypes
        ?.filter(({ label }) =>
          [
            ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE,
            ON_DEMAND_AUDIO_INTERPRETATION_JOB_TYPE,
          ].includes(label),
        )
        .map(({ value }) => value.toString()),
    },
  ];
  const translationFilters = [
    {
      key: "requestType",
      values: jobTypes
        ?.filter(({ label }) =>
          [
            TRANSLATION_ONLY,
            TRANSLATION_EDITING,
            CERTIFIED_TRANSLATION,
            POST_EDITING,
            PROOFREADING,
          ].includes(label),
        )
        .map(({ value }) => value.toString()),
    },
  ];
  const mtFilters = [
    {
      key: "requestType",
      values: jobTypes
        ?.filter(({ label }) =>
          [
            REAL_TIME_MACHINE_TRANSLATION_JOB_TYPE,
            LARGE_BATCH_MACHINE_TRANSLATION_JOB_TYPE,
          ].includes(label),
        )
        .map(({ value }) => value.toString()),
    },
  ];
  const quickFilters = [
    { label: "All", filters: [] },
    {
      label: "Interpretation (On-demand)",
      filters: interpretationFilters,
    },
    {
      label: "Human Translation",
      filters: translationFilters,
    },
  ];

  if (includeMT) {
    quickFilters.push({
      label: "Machine Translation",
        filters: mtFilters,
    });
  }

  return quickFilters;
};
