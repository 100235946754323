import React, { useEffect } from "react";
import { Box, Container, Loader } from "@lango/common/components";
import OrganizationList from "./organizationList";
import Button from "../../forms/components/Button";
import Flex from "../../tables/components/Flex";
import {
  useAcceptClientInvitation,
  useAcceptVendorInvitation,
} from "@lango/common/hooks";
import {
  useAcceptClientInviteCtx,
  useAcceptVendorInviteCtx,
} from "@lango/common/context";

const LinkOrganization = () => {
  const { verifyResponse } = useAcceptVendorInviteCtx();

  const { acceptInviteAndInitializeUser, loading } =
    useAcceptVendorInvitation();

  useEffect(() => {
    if (verifyResponse?.acceptInviteStep === "accept-vendor") {
      acceptInviteAndInitializeUser(
        verifyResponse?.code,
        verifyResponse?.defaultOrganization,
      );
    }
  }, [verifyResponse]);

  if (loading) {
    return <Loader message="Please wait..." />;
  }

  return (
    <LinkOrganizationWrapper
      inviteResponse={verifyResponse}
      linkExistingOrganization={(id) =>
        acceptInviteAndInitializeUser(verifyResponse?.code, id)
      }
      linkDefaultOrganization={() =>
        acceptInviteAndInitializeUser(
          verifyResponse?.code,
          verifyResponse?.defaultOrganization,
        )
      }
    />
  );
};

export function LinkOrganizationWrapper({
  inviteResponse,
  linkExistingOrganization,
  linkDefaultOrganization,
}) {
  return inviteResponse?.acceptInviteStep &&
    inviteResponse?.organizations?.length > 0 ? (
    <Container extraClasses="p-0">
      <Box extraClasses="p-9">
        <h2 className="pb-1 text-2xl font-bold text-black leading-normal">
          Select Organization
        </h2>
        <h1>Please select one of your organization.</h1>
      </Box>
      <OrganizationList
        organizations={inviteResponse?.organizations}
        onClick={linkExistingOrganization}
      />
      <Flex justify="center" extraClasses="">
        <Button
          textButton
          text="Add new organization"
          extraClasses="!pb-4 !pt-10"
          type="button"
          onClick={linkDefaultOrganization}
        />
      </Flex>
    </Container>
  ) : (
    <Loader message="Please wait..." />
  );
}

export default LinkOrganization;

export const LinkClient = () => {
  const { verifyResponse } = useAcceptClientInviteCtx();
  const { handleAcceptInvite, loading } = useAcceptClientInvitation();

  useEffect(() => {
    if (verifyResponse?.acceptInviteStep === "accept-client") {
      handleAcceptInvite(
        verifyResponse?.code,
        verifyResponse?.defaultOrganization,
      );
    }
  }, [verifyResponse]);

  if (loading) {
    return <Loader message="Please wait..." />;
  }

  return (
    <LinkOrganizationWrapper
      inviteResponse={verifyResponse}
      linkExistingOrganization={(id) =>
        handleAcceptInvite(verifyResponse?.code, id)
      }
      linkDefaultOrganization={() =>
        handleAcceptInvite(
          verifyResponse?.code,
          verifyResponse?.defaultOrganization,
        )
      }
    />
  );
};
