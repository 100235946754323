import { Form, Formik } from "formik";

import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";
import { useClientOnboardSteps } from "@lango/common/hooks";

import { useAcceptClientInviteCtx } from "@lango/common/context";
import { Box } from "@lango/common/components";
import UserList from "@lango/common/components/userList/UserList";
import { Action } from "../../onboarding";
import { INVITATION_TYPES } from "@lango/common/constants/invitationTypes";

export default function Step4() {
  const { handleOnboardingStep4 } = useClientOnboardSteps();
  const { onboardingData } = useAcceptClientInviteCtx();
  return (
    <Formik initialValues={{}} onSubmit={handleOnboardingStep4}>
      <Form>
        <BasePageHeading
          heading="Client onboarding"
          description="Step 4 of 4"
        />
        <Box>
          <UserList
            canUpdateOrg
            enableRowClick={false}
            organizationID={onboardingData?.organizationID}
            activeUserID={0}
            inviteType={INVITATION_TYPES.CLIENT}
            profileID={onboardingData?.clientProfileID}
          />
          <Action />
        </Box>
      </Form>
    </Formik>
  );
}
