import { Formik } from "formik";

import { useRegisterUser } from "@lango/common/hooks";
import { vendorInvitationInitialValues } from "@lango/common/initialValues";
import { validateRegisterUserForm } from "@lango/common/validations";
import { useAcceptVendorInviteCtx } from "@lango/common/context";
import { VENDOR_APP } from "@lango/common/constants";

import { RegisterForm } from "./fields";

function RegisterUser() {
  const { verifyResponse, handleChangeInviteStep } = useAcceptVendorInviteCtx();
  const { handleRegisterUser } = useRegisterUser(VENDOR_APP, () =>
    handleChangeInviteStep("login"),
  );

  return (
    <Formik
      initialValues={vendorInvitationInitialValues(verifyResponse)}
      validationSchema={validateRegisterUserForm}
      onSubmit={handleRegisterUser}
    >
      <RegisterForm />
    </Formik>
  );
}

export default RegisterUser;
