import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const linkClasses =
  "px-4 py-3 flex items-center text-sm font-medium text-black hover:bg-gray-100 transition ease-in-out duration-150";
const iconClasses = "shrink-0 h-5 w-5 text-black";

const LinkItem = ({ item }) => (
  <Link to={item.href} role="menuitem" className={linkClasses} id={item.name}>
    <item.icon className={iconClasses} aria-hidden="true" />
    <div className="ml-2">
      <p className="text-sm font-medium text-black">{item.name}</p>
      {item.description ? (
        <p className="mt-1 text-xs text-gray-500">{item.description}</p>
      ) : null}
    </div>
  </Link>
);

const ButtonItem = ({ item }) => (
  <button role="menuitem" onClick={item.onClick} className={linkClasses}>
    {item?.icon && <item.icon className={iconClasses} aria-hidden="true" />}
    <div className="ml-2">
      <p className="text-sm font-medium text-black">{item.name}</p>
      {item.description ? (
        <p className="text-gray-black mt-1 text-xs font-normal">
          {item.description}
        </p>
      ) : null}
    </div>
  </button>
);

const MenuItems = ({ menuItems }) => {
  return (
    <div className="relative grid gap-1 bg-white sm:gap-2 sm:p-0">
      {menuItems.map((item) => (
        <Fragment key={item.name}>
          {item.children ? (
            item.children
          ) : item.href ? (
            <LinkItem item={item} />
          ) : (
            <ButtonItem item={item} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default MenuItems;
