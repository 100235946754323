/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_tq0BiSo9z",
    "aws_user_pools_web_client_id": "7ehs17bo1va09bodsmbd5ii04j",
    "oauth": {
        "domain": "lango.auth.us-west-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid"
        ],
        "redirectSignIn": "https://platform.lango.co,https://platform.lango.co/auth/sso,https://platform.lango.co/login,https://service.lango.co,https://service.lango.co/auth/sso,https://service.lango.co/login,https://vendor.lango.co,https://vendor.lango.co/auth/sso,https://vendor.lango.co/login",
        "redirectSignOut": "https://platform.lango.co/auth/sso/logout,https://platform.lango.co/login,https://service.lango.co/auth/sso/logout,https://service.lango.co/login,https://vendor.lango.co/auth/sso/logout,https://vendor.lango.co/login",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
