import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";
import { clientOnboardStep3InitialValues } from "@lango/common/initialValues";
import { useClientOnboardSteps } from "@lango/common/hooks";
import { validateClientOnboardingStep3 } from "@lango/common/validations";

import Fields from "./fields";
import { FieldArray, Form, Formik } from "formik";
import { useAcceptClientInviteCtx } from "@lango/common/context";
import FormControl from "../../forms/components/FormControl";
import Flex from "../../tables/components/Flex";

export default function Step3() {
  const { handleOnboardingStep3, handleUpdateClientOnboardingStatus } =
    useClientOnboardSteps();
  const { handleChangeInviteStep } = useAcceptClientInviteCtx();
  return (
    <Formik
      initialValues={clientOnboardStep3InitialValues}
      onSubmit={handleOnboardingStep3}
      validationSchema={validateClientOnboardingStep3}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ dirty, handleSubmit, validateForm, isSubmitting }) => (
        <Form>
          <BasePageHeading
            heading="Client onboarding"
            description="Step 3 of 4"
          />
          <FieldArray name="serviceLocations" component={Fields} />
          <Flex align="center" justify="end">
            <FormControl
              control="button"
              type="submit"
              text="Save & continue"
              disabled={isSubmitting}
              showLoader={isSubmitting}
              onClick={async (e) => {
                e.preventDefault(); // because it will submit form twice otherwise
                if (dirty) {
                  await validateForm();
                  handleSubmit();
                } else {
                  handleUpdateClientOnboardingStatus();
                  handleChangeInviteStep("step4");
                }
              }}
            />
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
