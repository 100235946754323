/**
 * This file contains the hook `useFetchVendorJobDetails` which is used to fetch the details of a specific vendor job.
 * It uses the Apollo Client's `useQuery` hook to execute the `VENDOR_JOB_DETAILS` GraphQL query with the provided `jobID`.
 * If the query encounters an error, it checks if the error message is `NOT_AUTHORIZED`. If so, it displays an error toast and redirects the user to the requests page.
 * The hook returns an object containing the job details (if available), the raw data from the query, and the loading and error states.
 */
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { NOT_AUTHORIZED } from "@lango/common/constants/errors";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import { VENDOR_JOB_DETAILS } from "@lango/common/queries";
import { VENDOR_ROUTES } from "@lango/common/routes";

export const useFetchVendorJobDetails = (jobID) => {
  const navigate = useNavigate();

  const handleJobAuthError = (error) => {
    if (error?.message === NOT_AUTHORIZED) {
      toastError("You are not authorized to view this request.");
      navigate(VENDOR_ROUTES.REQUESTS);
    }
  };

  const { data, loading, error } = useQuery(VENDOR_JOB_DETAILS, {
    variables: { jobID: parseInt(jobID) },
    notifyOnNetworkStatusChange: true,
    onError: handleJobAuthError,
  });

  const jobDetails = data?.vendorJobDetails;

  return {
    job: jobDetails ? [jobDetails] : [],
    loading,
    error,
  };
};
