import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";
import VendorJobTable from "./vendorJobTable/jobsTable";
import { OnboardingBanner } from "@lango/common/features/onboarding";

const Home = () => {
  return (
    <>
      <OnboardingBanner />
      <BasePageHeading heading="Vendor Requests" extraClasses="mb-4" />
      <VendorJobTable />
    </>
  );
};

export default Home;
