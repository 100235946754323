import { useMutation } from "@apollo/client";
import { toastError, toastSuccess } from "../features";
import { USER_NOTIFICATIONS } from "../queries";

export const useMarkNotificationAsRead = () => {
  const [markNotifications] = useMutation(
    USER_NOTIFICATIONS.MARK_NOTIFICATION_READ,
    {
      refetchQueries: [USER_NOTIFICATIONS.GET],
    },
  );

  const handleMarkNotificationAsRead = async (id, onRead) => {
    try {
      await markNotifications({ variables: { id } });
      toastSuccess("Notification marked as read");
      onRead();
    } catch (error) {
      toastError(error?.message);
    }
  };
  return { handleMarkNotificationAsRead };
};
