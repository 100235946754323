import React from "react";
import { Helmet } from "react-helmet-async";

const LoginPageTitle = ({ orgInfo }) => (
  <>
    {orgInfo?.hasCustomLoginPage && orgInfo?.pageTitleEnabled && (
      <Helmet>
        <title>{orgInfo?.name}</title>
      </Helmet>
    )}
  </>
);

export default LoginPageTitle;
