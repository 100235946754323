import { useQuery } from "@apollo/client";

import {
  ALL_JOB_TYPE_LANGUAGES,
  JOB_TYPE_LANGUAGES_AND_LANGO_CROWD,
} from "@lango/common/queries";
import { activeOrgVar } from "@lango/common/apollo";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";
import {
  ON_DEMAND_AUDIO_INTERPRETATION_JOB_TYPE,
  ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE,
} from "../constants";

/**
 * useJobTypeLanguages Hook
 *
 * This hook fetches job type languages based on the provided job type
 * It utilizes useQuery hook from Apollo Client for data fetching.
 *
 *  @hook useJobTypeLanguages
 */
export const useJobTypeLanguages = (/** @type {any} */ jobType) => {
  const { id: organizationID } = activeOrgVar();
  const params = useParams();
  const { setFieldValue } = useFormikContext();
  const { data, loading, error } = useQuery(
    JOB_TYPE_LANGUAGES_AND_LANGO_CROWD,
    {
      variables: { jobTypeID: jobType?.value, organizationID },
      skip: !jobType?.value,
      onCompleted: ({ organizationSettings }) => {
        const jobTypeName = jobType?.label;
        jobTypeName &&
          !params?.jobRoutingID &&
          setFieldValue(
            "useLangoCrowd",
            jobTypeName === ON_DEMAND_AUDIO_INTERPRETATION_JOB_TYPE
              ? organizationSettings?.useLangoCrowdOPIDefault
              : jobTypeName === ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE
                ? organizationSettings?.useLangoCrowdVRIDefault
                : false,
          );
      },
    },
  );

  return {
    jobTypeLanguages: data?.languagesForJobType || [],
    loading,
    error,
  };
};

export const useFetchAllJobTypeLanguages = () => {
  const { data, loading, error } = useQuery(ALL_JOB_TYPE_LANGUAGES, {
    fetchPolicy: "cache-first",
  });

  return {
    jobTypeLanguages: data?.allJobTypeLanguages || [],
    loading,
    error,
  };
};
