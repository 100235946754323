import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import { useGetClientProfile } from "@lango/common/hooks";
import { WithLoader } from "@lango/common/components";

export const ClientProfileContext = createContext({
  loading: false,
  error: null,
  clientProfile: null,
});

/* *
 * Provider component for managing client profiles.
 * It provides a context for accessing the client profiles.
 * It also renders the child components passed to it.
 */

export const ClientProfileProvider = ({ children }) => {
  const { clientProfileID } = useParams();
  const { loading, error, clientProfile } =
    useGetClientProfile(clientProfileID);

  return (
    <ClientProfileContext.Provider value={{ loading, error, clientProfile }}>
      <WithLoader loading={loading} error={error}>
        {children}
      </WithLoader>
    </ClientProfileContext.Provider>
  );
};

/**
 * Custom hook for accessing the ClientProfileContext.
 */

export function useClientProfile() {
  const context = useContext(ClientProfileContext);
  if (!context) {
    throw new Error(
      "ClientProfileProvider must be used within a ClientProfileProvider",
    );
  }
  return context;
}
