import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { AppContextProvider } from "@lango/common/app";
import { configureAmplify } from "@lango/common/config/aws";
import { reportWebVitals } from "@lango/common/helpers";

import awsconfig from "./aws-exports";
import router from "./routes";

configureAmplify(awsconfig);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // Removing StrictMode for now until react-draggable is updated. See: https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-strict-mode
  // <React.StrictMode>
  <AppContextProvider>
    <RouterProvider router={router} />
  </AppContextProvider>
  // </React.StrictMode>
);

reportWebVitals();
