// login
export const CUSTOM_LOGIN_SUPPORT_DISABLED =
  "organization custom login support is disabled";
export const ORG_LOGIN_FORM_ERROR = "Error signing in under organization:";
export const SIGNING_IN = "Signing you in. Please wait...";

// confirm forgot password
export const PASSWORD_CHANGED = "Password has been changed";
export const passwordRequirements = [
  "At least 8 characters",
  "Cannot begin or end with whitespace",
  "Requires Lowercase",
  "Requires Uppercase",
  "Requires Numbers",
  "Requires Symbols",
];

export const renderPasswordRequirements = (
  <div className="my-2 rounded-md border bg-gray-50 p-4">
    Password requirements:
    <ul className="list-disc pl-4">
      {passwordRequirements.map((item, ind) => (
        <li key={ind}>{item}</li>
      ))}
    </ul>
  </div>
);
