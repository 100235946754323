import { gql } from "@apollo/client";

export const NOTICE_DETAILS = gql`
  fragment noticeDetails on Notice {
    id
    message
    noticeType {
      id
      slug
    }
  }
`;

export const GET_ORG_NOTICES = gql`
  ${NOTICE_DETAILS}
  fragment getOrgNotices on Notice {
    ...noticeDetails
    startTime
    endTime
    createdAt
  }
`;

export const CLIENT_ORG_WORD_COUNT_AND_LIMIT_FRAGMENT = gql`
  fragment clientOrgWordCountAndLimit on WordCountAndLimit {
    wordCount
    wordCountLimit
  }
`;

export const ORG_GLOSSARY_DATA = gql`
  fragment orgGlossaryData on OrganizationGlossary {
    id
    glossary {
      id
      name
      languageFrom {
        id
        name: displayName
        isoCodeLong
      }
      languageTo {
        id
        name: displayName
        isoCodeLong
      }
    }
    isDefault
  }
`;

export const RECORD_VIEW_MODE_FIELDS = gql`
  fragment RecordViewModeFields on RecordViewMode {
    id
    name
  }
`;

export const ORG_CLOUD_STORAGE_PROVIDER = gql`
  fragment orgCloudStorageProvider on OrganizationCloudStorageProvider {
    cloudStorageProvider {
      id
      name
    }
  }
`;

export const ORG_SETTINGS = gql`
  fragment orgSettings on Organization {
    glossarySupport
    customLogoSupport
    jobWordCountLimitEnabled
  }
`;

export const ORG_FIELDS = gql`
  ${ORG_SETTINGS}
  fragment orgFields on Organization {
    ...orgSettings
    id
    name
    contactEmail
    website
    projectKey
    tagline
    description
    automaticallyDeleteDocuments
    automaticallyDeleteDocumentsMinutes
    requireBillingReference
    wipeDeletedFileData
    fileExpirationMinutes
    pageTitleEnabled
    nameSlug
  }
`;

export const VENDOR_PROFILE = gql`
  fragment vendorProfileBasicFields on VendorProfile {
    id
    name
    firstName
    lastName
    billingTerms
    status
  }
`;

export const VENDOR_PROFILE_DETAILS = gql`
  ${VENDOR_PROFILE}
  fragment vendorProfileDetails on VendorProfile {
    ...vendorProfileBasicFields
    billingNotes
    generalNotes
    organizationID
    lspID
    hasAcceptedAdminInvitation
    vendorProfileType {
      id
      name
    }
  }
`;
