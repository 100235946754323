import { useQuery, useReactiveVar } from "@apollo/client";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { activeOrgVar } from "@lango/common/apollo/localState";
import { FIND_LINGUIST_SERVICE_LINES } from "@lango/common/queries";

import { categories, prepareServiceLines } from "../../utils";

const useFetchLinguistServiceLines = () => {
  const { id } = useReactiveVar(activeOrgVar);
  const { linguistID } = useParams();

  const { loading, data, error } = useQuery(FIND_LINGUIST_SERVICE_LINES, {
    fetchPolicy: "no-cache",
    variables: { userID: linguistID, vendorID: id, types: categories },
  });

  const values = useMemo(() => prepareServiceLines(data), [data]);

  return { values, loading, error };
};

export default useFetchLinguistServiceLines;
