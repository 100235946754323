import { Popover, PopoverPanel, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import cn from "@lango/common/classnames";
import { DropdownTriangle } from "@lango/common/features";
import { useGetPaginatedOrgNotifications } from "@lango/common/hooks";
import { useGetUserNotifications } from "@lango/common/hooks/getUserNotifications";
import { PanelFooter } from "./footer";
import { NotificationHeader } from "./header";
import { NotificationList } from "./list";

/**
 * NotificationPanel Component
 *
 * Renders a notification bell icon that indicates the presence of unread notifications.
 * When clicked, it displays the NotificationPanel component that lists all notifications.
 * The component also shows a red dot on the bell icon if there are any notifications.
 *
 * @param {Object} props - The props for the OrganizationNotifications component.
 * @param {string} [props.extraClasses] - Additional classes to apply to the component.
 * @returns {React.ReactNode} The rendered OrganizationNotifications component.
 */
const NotificationPanel = ({ extraClasses }) => {
  const { data: organizationNotifications, unreadCounts: unreadOrgCount } =
    useGetPaginatedOrgNotifications();
  const { paginatedNotifications, unreadCount: unreadUserCount } =
    useGetUserNotifications(0, 5);
  const navigate = useNavigate();

  const hasUnreadNotifications = unreadOrgCount > 0 || unreadUserCount > 0;

  return (
    <Popover className={cn(`relative ${extraClasses}`)}>
      <NotificationHeader hasUnreadNotifications={hasUnreadNotifications} />
      <PanelTransition>
        <PopoverPanel className="absolute left-6 w-screen max-w-xs -translate-x-72 transform sm:px-0 lg:max-w-xl">
          {({ close }) => (
            <>
              <DropdownTriangle positionClasses="justify-center pr-4" />
              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5">
                <div className="relative flex flex-col bg-white h-[536px]">
                  <NotificationList
                    organizationNotifications={organizationNotifications}
                    userNotifications={paginatedNotifications}
                    closePanel={close}
                  />
                  <PanelFooter
                    onClick={() => {
                      navigate("/notifications");
                      close();
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </PopoverPanel>
      </PanelTransition>
    </Popover>
  );
};

export { NotificationPanel };

/**
 * PanelTransition Component
 *
 * Provides transition effects for the NotificationPanel component. This component
 * wraps its children with transitions that animate the appearance and disappearance
 * of the notification panel. It enhances the user experience by adding smooth
 * transitions when the panel is shown or hidden.
 *
 * @param {Object} props - The props for the PanelTransition component.
 * @param {React.ReactNode} props.children - The child components to which the transition effects will be applied.
 * @returns {React.ReactNode} The rendered transition component with its children.
 */
const PanelTransition = ({ children }) => {
  return (
    <Transition
      enter="transition ease-out duration-75"
      enterFrom="opacity-0 -translate-y-2"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-50"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-2"
    >
      {children}
    </Transition>
  );
};
