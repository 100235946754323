import { Formik } from "formik";

import { useAcceptClientInviteCtx } from "@lango/common/context";
import { useRegisterUser } from "@lango/common/hooks";
import { vendorInvitationInitialValues } from "@lango/common/initialValues";
import { validateRegisterUserForm } from "@lango/common/validations";
import { BUYER_APP } from "@lango/common/constants";

import { RegisterForm } from "../registerUser/fields";

const RegisterClient = () => {
  const { verifyResponse, handleChangeInviteStep } = useAcceptClientInviteCtx();
  const { handleRegisterUser } = useRegisterUser(BUYER_APP, () =>
    handleChangeInviteStep("login"),
  );

  return (
    <Formik
      initialValues={vendorInvitationInitialValues(verifyResponse)}
      validationSchema={validateRegisterUserForm}
      onSubmit={handleRegisterUser}
    >
      <RegisterForm />
    </Formik>
  );
};
export default RegisterClient;
