import { useMutation, useReactiveVar } from "@apollo/client";

import { activeLSPVar, activeOrgVar } from "@lango/common/apollo";
import { useModal, useVendorProfile } from "@lango/common/context";
import environmentVars from "@lango/common/env";
import {
  toastError,
  toastSuccess,
} from "@lango/common/features/alerts/functions/toast";
import {
  ALL_VENDOR_PROFILE_LANGUAGE,
  UPDATE_VENDOR_PROFILE_LANGUAGE_PAIR,
} from "@lango/common/queries";
import { updateVendorProfileLanguagePairRequest } from "@lango/common/request";
import { VENDOR_APP } from "@lango/common/constants";

const appName = environmentVars.appName;

export const useUpdateVendorProfileLanguagePair = () => {
  const { hideModal } = useModal();
  const { vendorProfile } = useVendorProfile();

  // handle for both PM and Vendor apps
  let organizationID = useReactiveVar(activeOrgVar)?.id;
  let lspID = useReactiveVar(activeLSPVar);

  if (appName === VENDOR_APP) {
    lspID = lspID?.id;
  } else { // for PM app
    lspID = organizationID;
    organizationID = vendorProfile?.organizationID;
  }

  const [updateVendorProfileLanguagePair] = useMutation(
    UPDATE_VENDOR_PROFILE_LANGUAGE_PAIR,
    {
      refetchQueries: [ALL_VENDOR_PROFILE_LANGUAGE],
    },
  );

  const handleUpdateLanguagePair = async (props) => {
    try {
      await updateVendorProfileLanguagePair(
        updateVendorProfileLanguagePairRequest({
          appName,
          organizationID,
          lspID,
          ...props,
        }),
      );
      toastSuccess("Updated vendor language pair request");
      hideModal();
    } catch (error) {
      toastError(
        error?.message || "Error updating vendor profile language pair",
      );
    }
  };

  return { handleUpdateLanguagePair };
};
