import { FaAngleRight } from "react-icons/fa";
import Flex from "../../tables/components/Flex";
import { Box } from "@lango/common/components";

// todo: this component will be removed in client invitation ticket LGO-1351
const OrganizationList = ({ organizations, onClick }) => {
  return (
    <Box extraClasses="border-t">
      {organizations?.map((org, key) => (
        <Flex
          justify="between"
          key={key}
          extraClasses="p-5 border-b font-medium text-base leading-normal cursor-pointer hover:bg-gray-100"
          onClick={() => onClick(org?.id)}
        >
          {org?.name}
          <FaAngleRight />
        </Flex>
      ))}
    </Box>
  );
};

export default OrganizationList;
