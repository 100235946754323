import { Form, Formik } from "formik";
import { useLocation } from "react-router-dom";

import { useConfirmForgotPassword } from "@lango/common/features/auth/hooks";
import { confirmPasswordInitialValues } from "@lango/common/initialValues";
import { confirmPasswordSchema } from "@lango/common/validations";

import LoginCard from "../../containers/LoginCard";
import FormFields from "./FormFields";

const ConfirmForgotPasswordForm = () => {
  const { handleConfirmForgotPassword } = useConfirmForgotPassword();
  const { state } = useLocation();

  return (
    <Formik
      initialValues={confirmPasswordInitialValues(state?.username)}
      validationSchema={confirmPasswordSchema}
      onSubmit={handleConfirmForgotPassword}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form>
        <LoginCard>
          <FormFields isValidUsername={state?.username} />
        </LoginCard>
      </Form>
    </Formik>
  );
};
export default ConfirmForgotPasswordForm;
