import { Formik } from "formik";
import React from "react";

import { UpdateUserRolesForm } from "./UpdateUserRolesForm";
import { useUpdateUserRole } from "./useUpdateUserRoles";

export const UpdateUserRoles = ({ initialValues, hideModal }) => {
  const handleUpdateUserRole = useUpdateUserRole(hideModal);

  return (
    <Formik initialValues={initialValues} onSubmit={handleUpdateUserRole}>
      <UpdateUserRolesForm initialValues={initialValues} />
    </Formik>
  );
};
