export const VENDOR_STATUS = {
  active: {
    label: "Active",
    value: "active",
  },
  draft: {
    label: "Draft",
    value: "draft",
  },
  not_invited: {
    label: "Not Invited",
    value: "not invited",
  },
};
