import React from "react";
import HeaderContainer from "./HeaderContainer";
import HeaderLogo from "./HeaderLogo";

const BaseHeader = ({ isAuthenticated, children }) => {
  return (
    <HeaderContainer>
      <HeaderLogo />
      {isAuthenticated && children}
    </HeaderContainer>
  );
};

export default BaseHeader;
