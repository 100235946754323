import { Modal } from "flowbite-react";

const SearchModalWrapper = ({
  showSearchModal,
  children,
  size,
  headerTitle,
  className,
  bodyClasses,
  setShowModal,
}) => {
  const handleCloseModal = () => {
    setShowModal?.(false);
  };

  return (
    <Modal
      size={size}
      className={className}
      show={showSearchModal}
      onClose={handleCloseModal}
    >
      <Modal.Header>
        <span className="font-bold text-3xl">{headerTitle}</span>
      </Modal.Header>

      <Modal.Body className={bodyClasses}>{children}</Modal.Body>
    </Modal>
  );
};

SearchModalWrapper.defaultProps = {
  className: "bg-gray-100",
  size: "2xl",
  headerTitle: "Filter",
  bodyClasses: "border-0",
};

export default SearchModalWrapper;
