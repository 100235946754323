import { Form, useFormikContext } from "formik";

import { useFetchAllRoles } from "@lango/common/hooks";
import { ADMIN, MEMBER } from "@lango/common/constants";
import { FormControl, Flex } from "@lango/common/features";

const InviteClientAndVendorAdminFields = ({ users }) => {
  const { isSubmitting, dirty } = useFormikContext();
  const { allRoles } = useFetchAllRoles();
  let filteredRoles = [];
  if (users < 1) {
    filteredRoles = allRoles
      ? allRoles.filter((role) => role?.label === ADMIN)
      : [];
  } else {
    filteredRoles = allRoles
      ? allRoles.filter(
          (role) => role?.label === ADMIN || role?.label === MEMBER,
        )
      : [];
  }

  return (
    <Form className="space-y-8">
      <Flex extraClasses="gap-2">
        <FormControl
          control="input"
          label="First Name"
          name="firstName"
          type="text"
          placeholder="First Name"
          disabled={isSubmitting}
        />
        <FormControl
          control="input"
          label="Last Name"
          name="lastName"
          type="text"
          placeholder="Last Name"
          disabled={isSubmitting}
        />
      </Flex>

      <FormControl
        control="input"
        label="Email"
        name="email"
        type="email"
        placeholder="Email"
        disabled={isSubmitting}
      />
      <FormControl
        control="radio"
        label="Role"
        name="role"
        options={filteredRoles}
      />
      <Flex align="center" justify="center">
        <FormControl
          control="button"
          type="submit"
          text="Submit"
          disabled={isSubmitting || !dirty}
          showLoader={isSubmitting}
        />
      </Flex>
    </Form>
  );
};
export default InviteClientAndVendorAdminFields;
