import { useMutation, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

import { activeOrgVar } from "@lango/common/apollo";
import { toastError, toastSuccess } from "@lango/common/features";
import { PAGINATED_ORG_USERS, UPDATE_USER_ROLES } from "@lango/common/queries";

export const useUpdateUserRole = (hideModal) => {
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const [updateUserRoles] = useMutation(UPDATE_USER_ROLES, {
    refetchQueries: [PAGINATED_ORG_USERS],
  });

  const handleUpdateUserRole = useCallback(
    async (values) => {
      const roleIDs = values?.userRoles?.map((item) => item.value);
      try {
        await updateUserRoles({
          variables: {
            userID: values?.user?.id,
            organizationID,
            roleIDs,
          },
        });
        toastSuccess(!roleIDs ? "User removed" : "Roles updated");
        hideModal();
      } catch (error) {
        console.error("error", error);
        toastError(error?.message);
      }
    },
    [organizationID, updateUserRoles]
  );
  return handleUpdateUserRole;
};
