import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { USER_PROFILE_PICTURE, FIND_CURRENT_USER } from "@lango/common/queries";
import { toastError, toastSuccess } from "../features";
import { activeOrgVar } from "../apollo";

export const useDeleteUserPicture = () => {
  const [deleteUserProfile, { loading }] = useMutation(
    USER_PROFILE_PICTURE.DELETE,
    {
      refetchQueries: [FIND_CURRENT_USER],
    },
  );

  const handleDeletePicture = useCallback(async () => {
    try {
      await deleteUserProfile({
        variables: { organizationID: activeOrgVar()?.id },
      });
      toastSuccess("Picture picture deleted");
    } catch (error) {
      console.error(error);
      toastError("There was an error deleting the picture");
    }
  }, [deleteUserProfile]);

  return { handleDeletePicture, loading };
};
