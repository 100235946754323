import React from "react";
import { Tooltip } from "flowbite-react";

export const renderLabel = (id, name) => {
  return (
    <span
      key={id}
      id={"label-" + id}
      className="mx-0.5 mb-1 inline-flex rounded-md bg-black px-2 py-1 text-xs font-bold uppercase leading-5 text-white"
    >
      {name}
    </span>
  );
};

const renderLabelsTooltip = (labels) => {
  const tooltipProps = {
    style: "light",
    content: (
      <div className="w-40 whitespace-normal">
        {labels?.map((l) => renderLabel(l?.label?.id, l?.label?.name))}
      </div>
    ),
  };
  return (
    <Tooltip {...tooltipProps}>
      <span className="cursor-pointer">
        {renderLabel("multiple", "Multiple")}
      </span>
    </Tooltip>
  );
};

const Labels = ({ labels }) => {
  return labels?.length > 1
    ? renderLabelsTooltip(labels)
    : labels[0]?.label
    ? renderLabel(labels[0].label.id, labels[0].label.name)
    : null;
};

export default Labels;
