import { useMutation, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

import { toastError } from "@lango/common/features/alerts/functions/toast";
import { ORGANIZATION_NOTIFICATION } from "@lango/common/queries";
import { activeOrgVar } from "@lango/common/apollo";

export const useReadAllOrgNotifications = () => {
  const org = useReactiveVar(activeOrgVar);
  const orgID = org?.id;

  const [readAll] = useMutation(ORGANIZATION_NOTIFICATION.READ_ALL, {
    refetchQueries: [ORGANIZATION_NOTIFICATION.PAGINATED],
  });

  const handleReadAllNotifications = useCallback(async () => {
    try {
      await readAll({ variables: { orgID } });
    } catch (error) {
      toastError("There was an error reading notifications");
      console.error(error);
    }
  }, [readAll, orgID]);

  return { handleReadAllNotifications };
};
