const BASE_MODAL_STYLE = {
  content: {
    top: "30%",
    left: "0",
    right: "0",
    border: "0",
    boxShadow:
      "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    width: "510px",
    height: "250px",
    minHeight: "150px",
    margin: "0 auto",
    borderRadius: "12px",
    padding: "0",
  },
};

export { BASE_MODAL_STYLE };
