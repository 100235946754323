import { useFormikContext } from "formik";

import { Box } from "@lango/common/components/Box";
import FormControl from "@lango/common/features/forms/components/FormControl";
import Flex from "@lango/common/features/tables/components/Flex";

const FormFields = () => {
  const { isSubmitting, dirty } = useFormikContext();

  return (
    <Box spaceY={8}>
      <FormControl
        control="input"
        label="Email"
        type="email"
        name="username"
        placeholder="Enter email"
        description="Forgot your password? No problem. Enter your email and we'll send you a link to reset it."
        disabled={isSubmitting}
        whiteBackground
      />
      <Flex justify="center">
        <FormControl
          control="button"
          type="submit"
          disabled={isSubmitting || !dirty}
          text="Send"
          showLoader={isSubmitting}
        />
      </Flex>
    </Box>
  );
};

export default FormFields;
