import { gql } from "@apollo/client";

import { CURRENCY, RATE_UNIT, VENDOR_INFO } from "../../common";
import { JOB_TYPE_AND_LANGUAGES } from "../job";
import { TASK_TYPE_AND_FILES } from "../task";

export const PROJECT_LABELS = gql`
  fragment ProjectLabelFields on Project {
    labels {
      id
      label {
        id
        name
      }
    }
  }
`;

export const PROJECT_FIELDS = gql`
  ${PROJECT_LABELS}
  ${JOB_TYPE_AND_LANGUAGES}
  ${TASK_TYPE_AND_FILES}
  ${CURRENCY}
  ${VENDOR_INFO}
  ${RATE_UNIT}
  fragment ProjectFields on Project {
    id
    projectName
    projectTypeID
    projectStatusID
    requestedCompletionDate
    projectCode
    clientProjectCode
    projectKey
    projectNumber
    internalProjectCode
    billingReferenceNumber
    customerOrganizationID
    projectContactID
    matterName
    estimatedPrice
    actualPrice
    currencyID
    ...ProjectLabelFields
    internalNotes
    vendorNotes
    customerNotes
    jobs {
      id
      ...jobDetailsAndLanguages
      requestedCompletionDate
      documentOutputFormat {
        id
        name
      }
      rateUnit {
        ...rateUnit
      }
      quantity
      rate
      tasks {
        id
        ...taskTypeAndFiles
        vendor {
          ...vendorInfo
        }
        targetDate
        vendorProfileID
        vendorID
        rate
        rateUnitID
        sourceFileID
      }
      currency {
        ...currency
      }
      estimatedPrice
      actualPrice
    }
  }
`;

export const PROJECT_FILE_FIELDS = gql`
  fragment ProjectFileFields on File {
    id
    name
    fileType {
      id
      name
    }
  }
`;

export const PROJECT_INFO_FIELDS = gql`
  ${PROJECT_LABELS}
  ${CURRENCY}
  fragment ProjectInfoFields on Project {
    ...ProjectLabelFields
    id
    projectType {
      id
      name
    }
    projectStatus {
      id
      name
    }
    currency {
      ...currency
    }
    projectContact {
      id
      name
    }
    projectCode
    projectKey
    projectNumber
    estimatedPrice
    actualPrice
    requestedCompletionDate
    billingReferenceNumber
    createdAt
  }
`;
