const SLUG_REGEX = new RegExp("^[a-z0-9]+(?:(?:-|_)+[a-z0-9]+)*$");

const GetFileStorageProviderDisplayName = (name) => {
  switch (name) {
    case "onedrive":
      return "OneDrive";
    case "onedrive_business":
      return "OneDrive for Business";
    case "box":
      return "Box";
    default:
      if (typeof name !== "string") return "";
      return name.charAt(0).toUpperCase() + name.slice(1);
  }
};

const CopyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

const isValidNameSlug = (nameSlug) => {
  return nameSlug && SLUG_REGEX.test(nameSlug) ? true : false;
};

export {
  CopyTextToClipboard,
  GetFileStorageProviderDisplayName,
  isValidNameSlug,
  SLUG_REGEX,
};
