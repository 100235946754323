import { gql } from "@apollo/client";

const ALL_PROVIDER_LANGUAGES = gql`
  query allProviderLanguages($provider: String!) {
    allProviderLanguages(provider: $provider) {
      value: id
      label: displayName
      isoCode: isoCodeLong
    }
  }
`;

const ALL_LANGUAGES_AND_GENDERS = gql`
  query languagesAndGenders($provider: String!, $skipSlugs: [String!]) {
    allProviderLanguages(provider: $provider) {
      value: id
      label: displayName
      isoCode: isoCodeLong
    }
    allGenders(skipSlugs: $skipSlugs) {
      value: id
      label: name
    }
  }
`;

const JOB_TYPE_LANGUAGES_AND_GENDERS = gql`
  query languagesAndGenders(
    $jobTypeID: ID!
    $skipSlugs: [String!]
    $orgID: ID!
    $lspID: ID!
  ) {
    languagesForJobType(jobTypeID: $jobTypeID) {
      value: id
      label: displayName
      isoCode: isoCodeLong
      isSuggested
    }
    allGenders(skipSlugs: $skipSlugs) {
      value: id
      label: name
    }
    organizationSettings(organizationID: $orgID) {
      callRecordingMode {
        label: name
        value: id
      }
    }
    clientProfileSettings(organizationID: $orgID, lspID: $lspID) {
      SLASupport
      vriSLASeconds
      opiSLASeconds
    }
  }
`;

const JOB_TYPE_LANGUAGES = gql`
  query languagesForJobType($jobTypeID: ID!) {
    languagesForJobType(jobTypeID: $jobTypeID) {
      value: id
      label: displayName
      isoCode: isoCodeLong
      isSuggested
    }
  }
`;

const ALL_JOB_TYPES = gql`
  query allJobTypes($types: [String!]) {
    allJobTypes(types: $types) {
      value: id
      label: name
      type
    }
  }
`;

const ALL_GENDERS = gql`
  query allGenders {
    allGenders {
      value: id
      label: name
    }
  }
`;

const CAN_UPDATE_ORG = gql`
  query canUpdateOrganization($organizationID: ID!) {
    canUpdateOrganization(organizationID: $organizationID)
  }
`;

const FIND_OR_CREATE_CLIENT_USER_OPI_CODE = gql`
  query findOrCreateClientUserOPICode($orgID: Int!, $lspID: Int!) {
    findOrCreateClientUserOPICode(orgID: $orgID, lspID: $lspID)
  }
`;

const ROTATE_CLIENT_USER_OPI_REQUEST_CODE = gql`
  mutation rotateClientUserOPIRequestCode($orgID: Int!, $lspID: Int!) {
    rotateClientUserOPIRequestCode(orgID: $orgID, lspID: $lspID)
  }
`;

export {
  ALL_PROVIDER_LANGUAGES,
  ALL_LANGUAGES_AND_GENDERS,
  JOB_TYPE_LANGUAGES_AND_GENDERS,
  JOB_TYPE_LANGUAGES,
  ALL_JOB_TYPES,
  ALL_GENDERS,
  CAN_UPDATE_ORG,
  FIND_OR_CREATE_CLIENT_USER_OPI_CODE,
  ROTATE_CLIENT_USER_OPI_REQUEST_CODE,
};
