import React from "react";
import { Navigate, useParams } from "react-router-dom";

import { AUTH_ROUTES } from "@lango/common/routes";

const RedirectOrgLogin = () => {
  const { org } = useParams();
  const redirectURL = org
    ? `${AUTH_ROUTES.LOGIN}?org=${org}`
    : AUTH_ROUTES.LOGIN;

  return <Navigate replace to={redirectURL} />;
};

export default RedirectOrgLogin;
