import { useReactiveVar } from "@apollo/client";
import { ChevronRightIcon } from "@heroicons/react/solid";

import Tab from "@lango/common/features/tab/Tab";
import envs from "@lango/common/env";
import useCanUpdateOrg from "@lango/common/hooks/useCanUpdateOrg";
import { VendorProfileLanguage } from "@lango/common/features";

import { activeLSPVar, activeOrgVar } from "@lango/common/apollo";

import ServiceLines from "./serviceLines";
import UpdateUserSettings from "./linguistSettings/userSettings";
import Notifications from "./linguistSettings/notifications";
import { LinguistProfileSection } from "./profile";

const linguistTabStyles = {
  active:
    "bg-black text-white rounded-xl py-4 px-4 flex items-center justify-between",
  inactive: "py-4 px-4 py-4 flex items-center justify-between",
  header:
    "mb-8 flex flex-col bg-white p-4 shadow-md rounded-xl col-span-1 h-[737px]",
  container: "grid grid-cols-3 gap-4",
  childContainer:
    "col-span-2 h-[737px] overflow-y-scroll rounded-xl bg-white shadow-md p-8",
};

const Settings = () => {
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const { id: lspID } = useReactiveVar(activeLSPVar);
  const { canUpdateOrg } = useCanUpdateOrg();

  return (
    <Tab
      styles={linguistTabStyles}
      header={<LinguistProfileSection />}
      tabIcon={<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />}
    >
      <Tab.Panel name="Basic Info">
        <UpdateUserSettings />
      </Tab.Panel>
      {canUpdateOrg && (
        <Tab.Panel name="Service Lines">
          <ServiceLines organizationID={organizationID} lspID={lspID} />
        </Tab.Panel>
      )}
      {envs.featureFlags.enabledVendorIndustry && (
        <Tab.Panel name="Vendor Language Pairs">
          <VendorProfileLanguage
            organizationID={organizationID}
            lspID={lspID}
            canUpdateOrg={canUpdateOrg}
          />
        </Tab.Panel>
      )}
      <Tab.Panel name="Notifications">
        <Notifications />
      </Tab.Panel>
    </Tab>
  );
};
export default Settings;
