import { XIcon } from "@heroicons/react/solid";
import React from "react";
import ReactModal from "react-modal";
import { BASE_MODAL_STYLE } from "./modalUtils";

const BaseModal = ({ title, ...props }) => {
  return (
    <ReactModal style={BASE_MODAL_STYLE} ariaHideApp={false} {...props}>
      <div className="flex items-start items-center justify-between p-4">
        <h3 className="text-xl font-semibold">{title}</h3>
        <XIcon
          className="h-4 w-4 cursor-pointer text-black"
          onClick={props.onRequestClose}
        />
      </div>
      {props.children}
    </ReactModal>
  );
};

export default BaseModal;
