import { Formik } from "formik";

import WithLoader from "@lango/common/components/WithLoader";
import SettingsTabContainer from "@lango/common/features/containers/SettingsTabContainer";

import ServiceLineFields from "./ServiceLineFields";
import useFetchLinguistServiceLines from "./fetchLinguistServiceLines";

const ServiceLines = () => {
  const { values, ...loadingProps } = useFetchLinguistServiceLines();
  return (
    <SettingsTabContainer>
      <WithLoader {...loadingProps}>
        <Formik initialValues={values}>
          <ServiceLineFields />
        </Formik>
      </WithLoader>
    </SettingsTabContainer>
  );
};

export default ServiceLines;
