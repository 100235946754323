import { createContext, useContext, useState } from "react";
import { COMPOSITION_VIDEO_STATUSES } from "@lango/common/constants";

const { CREATED } = COMPOSITION_VIDEO_STATUSES;

export const OnDemandJobDetailsContext = createContext({
  compositionStatus: null,
  setCompositionStatus: () => {},
});

/* *
 * Provider component for managing on demand job details.
 * It provides a context for accessing the on-demand job details.
 * It also renders the child components passed to it.
 */

export const OnDemandJobDetailsProvider = ({ children }) => {
  const [compositionStatus, setCompositionStatus] = useState(null);

  return (
    <OnDemandJobDetailsContext.Provider
      value={{ compositionStatus, setCompositionStatus }}
    >
      {children}
    </OnDemandJobDetailsContext.Provider>
  );
};

/**
 * Custom hook for accessing the OnDemandJobDetailsContext.
 */

export function useOnDemandJobDetails() {
  const context = useContext(OnDemandJobDetailsContext);
  if (!context) {
    throw new Error(
      "useOnDemandJobDetails must be used within a OnDemandJobDetailsProvider",
    );
  }
  return context;
}
