// Contains type policies corresponding to the protected/core.graphql schema
// Reference: https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields

import { idField } from "./consts";

export const corePolicies = {
  Currency: {
    keyFields: idField,
  },
  FileFormat: {
    keyFields: idField,
  },
  Language: {
    keyFields: idField,
  },
  Role: {
    keyFields: idField,
  },
  Permission: {
    keyFields: ["roleID"],
  },
  User: {
    keyFields: false,
  },
  UpdateUserResp: {
    keyFields: false,
  },
  CreateProjectResponse: {
    keyFields: false,
  },
  NotificationData: {
    keyFields: false,
  },
  PaginatedNotification: {
    keyFields: false,
  },
  CreateLabelResponse: {
    keyFields: false,
  },
  UserByEmailAndOrg: {
    keyFields: false,
  },
  UserOrganization: {
    keyFields: false,
  },
};
