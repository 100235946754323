import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { toastError, toastSuccess } from "@lango/common/features";
import { REGISTER_USER } from "@lango/common/queries";
import { registerUserRequest } from "@lango/common/request";

export const useRegisterUser = (appType = "", onRegister = () => {}) => {
  const [registerUser] = useMutation(REGISTER_USER, {
    context: { clientName: "public" },
  });

  const handleRegisterUser = useCallback(
    async (/** @type {any} */ values) => {
      try {
        await registerUser(registerUserRequest(values, appType));
        toastSuccess("Your account has been created successfully! Please check your email to verify your account and continue.");
        onRegister();
      } catch (error) {
        toastError(error?.message || "Error registering user");
        console.error(error);
      }
    },
    [registerUser, onRegister, appType],
  );

  return { handleRegisterUser };
};
