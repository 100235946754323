/**
 * This file contains the definition of the Fields component, which renders the form fields for the billing contact section in the onboarding step 2.
 */

import { Divider } from "@aws-amplify/ui-react";
import { Box } from "@lango/common/components";
import TaxForm from "./taxForm";
import { renderFieldGroup } from "../step1/fields";

/**
 * An array of objects representing the form fields for the billing contact section.
 * Each object contains the control type, name, label, and placeholder for the field.
 * @type {Array}
 */
export const billing_contact_fields = [
  {
    control: "input",
    name: "firstName",
    label: "First name",
    placeholder: "Enter first name",
  },
  {
    control: "input",
    name: "lastName",
    label: "Last name",
    placeholder: "Enter last name",
  },
  {
    control: "input",
    type: "email",
    name: "email",
    label: "Email",
    placeholder: "Enter email",
  },
  {
    control: "phone",
    name: "phone",
    label: "Phone number",
    placeholder: "Enter phone number",
  },
  {
    control: "input",
    type: "tel",
    name: "faxNumber",
    label: "Fax number",
    placeholder: "Enter fax number",
  },
];

export const address_fields = [
  {
    control: "input",
    name: "country",
    label: "Country",
    placeholder: "Select country",
    containerClasses: "md:col-start-1",
  },
  {
    control: "input",
    name: "address1",
    label: "Address",
    placeholder: "Enter address",
    containerClasses: "md:col-start-1",
  },
  {
    control: "input",
    name: "address2",
    label: "Apartment, suite, building, floor",
    placeholder: "Enter apartment, suite, building, floor, etc",
  },
  {
    control: "input",
    name: "city",
    label: "City",
    placeholder: "Enter city",
  },
  {
    control: "input",
    name: "state",
    label: "State",
    placeholder: "Enter state",
  },
  {
    control: "input",
    name: "postalCode",
    label: "Postal code",
    placeholder: "Enter postal code",
  },
];

/**
 * The Fields component renders the form fields for the billing contact section.
 * It includes the billing contact field group, a divider, and the TaxForm component.
 * @returns {JSX.Element} The rendered Fields component.
 */
export default function Fields({ fileName }) {
  return (
    <Box extraClasses="bg-white rounded-xl shadow-md my-8">
      {renderFieldGroup("Billing Contact", [...billing_contact_fields, ...address_fields])}
      <Divider />
      <TaxForm fileName={fileName} />
    </Box>
  );
}
