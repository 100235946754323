import { useQuery, useReactiveVar } from "@apollo/client";
import React from "react";
import { activeLSPVar, activeOrgVar } from "../apollo";
import { FIND_LINGUIST_ONBOARDING_STEP1 } from "../queries";
import { useFetchVendorOnboardingStatus } from "./vendorOnboardingStatus";

export const useLinguistOnboardingStep1 = () => {
  const { status } = useFetchVendorOnboardingStatus();
  const activeLSP = useReactiveVar(activeLSPVar);
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const lspID = activeLSP?.id;
  const { loading, error, data } = useQuery(FIND_LINGUIST_ONBOARDING_STEP1, {
    variables: { organizationID, lspID },
    fetchPolicy: "cache-and-network",
    skip: status?.currentStep === "started" || status === undefined,
  });

  return {
    loading,
    error,
    vendorProfile: data ? data.findIndividualOnbardingStep1 : null,
  };
};
