import startCase from "lodash.startcase";
import React from "react";

import classNames from "@lango/common/classnames";
import Flex from "@lango/common/features/tables/components/Flex";
import { mapStatusIcon } from "@lango/common/helpers";

const Status = ({ type }) => {
  const iconType = mapStatusIcon(type);

  let iconClass = classNames("h-3 w-3 inline-block rounded-full", {
    "bg-lango-green": iconType === "success",
    "bg-blue-400": iconType === "info",
    "bg-orange-400": iconType === "warning",
    "bg-red-400": iconType === "danger",
    "bg-lango-cyan": iconType === "primary",
  });

  return (
    <Flex align={"center"}>
      <div className={"w-8 shrink-0 items-center text-center"}>
        <span className={iconClass} />
      </div>
      <div className={"flex-1"}>{startCase(type)}</div>
    </Flex>
  );
};

export default Status;
