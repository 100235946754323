import { useQuery, useReactiveVar } from "@apollo/client";

import { activeOrgVar } from "@lango/common/apollo/localState";
import envs from "@lango/common/env";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import { HT_JOB_DATA, HT_VENDOR_JOB_DATA } from "@lango/common/queries";

const pollInterval = envs.taskPollInterval;
export const useFetchHTJob = (jobID) => {
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const { data, stopPolling } = useQuery(HT_JOB_DATA, {
    variables: { jobID, organizationID },
    pollInterval,
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      toastError("Failed to fetch HT job", { toastId: "ht_job_error" });
      console.error(error);
    },
  });

  return { job: data?.job || {}, stopPolling };
};

export const useFetchVendorHTJob = (jobID) => {
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const { data } = useQuery(HT_VENDOR_JOB_DATA, {
    variables: { jobID, organizationID },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      toastError("Failed to fetch human translation job", { toastId: "ht_job_error" });
      console.error(error);
    },
  });

  return { job: data?.job || {} };
}