import { useQuery, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";

import { activeOrgVar } from "@lango/common/apollo/localState";
import environmentVars from "@lango/common/env";
import { FIND_JOB_TYPE } from "@lango/common/queries";

import { useJobDetailsError } from "./useJobDetailsError";

export const useFetchJobType = () => {
  const { jobID } = useParams();
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const { handleJobDetailsError } = useJobDetailsError();

  const { data, loading, error } = useQuery(FIND_JOB_TYPE, {
    variables: {
      jobID: parseInt(jobID),
      organizationID,
      sourceAppName: environmentVars.appName,
    },
    notifyOnNetworkStatusChange: true,
    onError: handleJobDetailsError,
  });

  return { jobType: data?.job?.jobType?.type, loading, error };
};
