import classNames from "@lango/common/classnames";

function ButtonLoader({ message }) {
  let btnClass = classNames({
    "absolute -inset-x-4": message && message.length > 0,
  });

  return (
    <div className="relative flex items-center justify-center rounded-md border border-transparent text-base text-gray-500 transition duration-150 ease-in-out">
      <div className={btnClass}>
        <svg
          className="w-5 animate-spin text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      {message ?? null}
    </div>
  );
}

export default ButtonLoader;
