/**
 * CircularProgress component
 * @param progress number The progress amount, a value from 0 - 100
 * @param extraClasses string extra classes for the text
 * @param size number The size of the component in pixels
 * @returns {JSX.Element}
 */
function CircularProgress({ progress = 0, extraClasses = "", size }) {
  const fillPercent = Math.min(progress / 100, 1);
  const radius = size / 2;
  const stroke = 6;
  const normalizedRadius = radius - stroke;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - fillPercent * circumference;

  return (
    <div className="flex justify-center items-center">
      <div className="inline-flex items-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-gray-500 transition duration-150 ease-in-out">
        <svg className="transform -rotate-90" height={size} width={size}>
          <circle
            className="opacity-25 lango-light-gray"
            cx={radius}
            cy={radius}
            r={normalizedRadius}
            fill="transparent"
            stroke="currentColor"
            strokeWidth={stroke}
          />
          <circle
            className={extraClasses}
            cx={radius}
            cy={radius}
            r={normalizedRadius}
            stroke="currentColor"
            strokeWidth={stroke}
            style={{
              strokeDashoffset,
              transition: "stroke-dashoffset 1s linear",
            }}
            strokeDasharray={circumference + " " + circumference}
            fill="transparent"
          />
        </svg>
      </div>
    </div>
  );
}

export default CircularProgress;
