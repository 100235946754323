import { useMemo } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { Divider } from "@aws-amplify/ui-react";

import { userFirstNameVar, userLastNameVar } from "@lango/common/apollo";
import { Box } from "@lango/common/components";
import { Flex, FormControl } from "@lango/common/features";
import Button from "@lango/common/features/forms/components/Button";
import InputLabel from "@lango/common/features/forms/components/InputLabel";
import {
  getGroupedTimezones,
  prepareTimezoneOption,
} from "@lango/common/features/userSettings/userSettingForm/timezones";
import { useAllGenders } from "@lango/common/features/userSettings/userSettingForm/prepareGender";

import { useSettingsContext } from "./provider";

// Formik has performance issues, we need to create reuable react-hook-form controller in (LGO-1446)
// Define the EditableField component.
const EditableField = ({
  title, // The title of the field.
  editComponent: EditComponent, // The component used for editing the field.
  renderValue = () => <></>, // A function to render the value of the field.
  readOnly = false, // Whether the field is read-only.
}) => {
  // Get the loading state, the active field, and the function to change the active field from the settings context.
  const { loading, activeField, handleChangeActiveField } =
    useSettingsContext();

  // Define a function to handle clicking the "Edit" button.
  function handleEdit() {
    // If not loading, change the active field to the title of this field.
    !loading && handleChangeActiveField(title);
  }

  // Define a function to handle clicking the "Cancel" button.
  function handleCancel() {
    // If not loading, clear the active field.
    !loading && handleChangeActiveField("");
  }

  const active = activeField === title;
  // Return the JSX for the component.
  return (
    <Box extraClasses="my-8">
      <Flex justify="between">
        {/* Render a label for the field. */}
        <InputLabel labelFor={title} text={title} />
        {/* Render a button to toggle between editing and viewing the field. */}
        {!readOnly && (
          <Button
            textButton
            type="button"
            text={active ? "Cancel" : "Edit"} // The button text depends on whether this field is the active field.
            onClick={active ? handleCancel : handleEdit} // The button click handler depends on whether this field is the active field.
            disabled={loading} // The button is disabled if loading.
          />
        )}
      </Flex>
      {/* Render either the edit component or the rendered value of the field,
      depending on whether this field is the active field. */}
      {active ? <EditComponent /> : renderValue()}
    </Box>
  );
};

function renderUserAddress(values) {
  // Create an array of the city, state, zip code, and country, filtering out any falsy values.
  const addressDetails = [
    values?.city,
    values?.state,
    values?.zipCode,
    values?.country,
  ].filter(Boolean);

  return (
    <span>
      {/* If address1 exists, render it on a new line */}
      {values?.address1 && (
        <>
          {values.address1} <br />
        </>
      )}
      {/* If address2 exists, render it on a new line */}
      {values?.address2 && (
        <>
          {values.address2} <br />
        </>
      )}
      {/* Join the address details with a comma and render them */}
      {addressDetails.join(", ")}
    </span>
  );
}

// Define the SettingsForm component.
export function SettingsForm({ user }) {
  const { userSettings } = useSettingsContext();
  return (
    // Return the Formik component with initial values from the user prop and an empty submit function.
    <Formik initialValues={user} onSubmit={() => {}}>
      {/* Wrap the form content with the Form component. */}
      <Form>
        {/* Render a Box component with a heading. */}
        <Box heading="Basic Info">
          <EditableField /* Render an EditableField component for the "Name" field. */
            title="Name"
            editComponent={EditName}
            renderValue={() => (
              <span>
                {userSettings?.firstName ?? ""} {userSettings?.lastName ?? ""}
              </span>
            )}
          />
          {/* Render a Divider component to visually separate the fields. */}
          <Divider />
          <EditableField /* Render an EditableField component for the "Preferred name" field. */
            title="Preferred name"
            editComponent={PreferrednameEdit}
            renderValue={() => userSettings?.prefferedName ?? ""}
          />
          <Divider />
          <EditableField /* Render an EditableField component for the "Gender" field. */
            title="Gender"
            editComponent={GenderEdit}
            renderValue={() => userSettings?.gender?.label ?? ""}
          />
          <Divider />
          <EditableField /* Render an EditableField component for the "Address" field. */
            title="Address"
            editComponent={EditAddress}
            renderValue={() => renderUserAddress(userSettings)}
          />
          <Divider />
          <EditableField /* Render an EditableField component for the "Email" field. */
            title="Email"
            editComponent={<></>}
            renderValue={() => userSettings?.email ?? ""}
            readOnly
          />
          <Divider />
          <EditableField /* Render an EditableField component for the "Phone" field. */
            title="Phone"
            editComponent={EditPhone}
            renderValue={() => userSettings?.phone ?? ""}
          />
          <Divider />
          <EditableField /* Render an EditableField component for the "Timezone" field. */
            title="Timezone"
            editComponent={EditTimezone}
            renderValue={() => userSettings?.preferredTimezone?.label ?? ""}
          />
          <Divider />
        </Box>
      </Form>
    </Formik>
  );
}

// Define the PreferrednameEdit component.
function PreferrednameEdit() {
  // Return a FormControl for the preferred name input and an Action button.
  return (
    <>
      {/* Render a FormControl for the preferred name input. */}
      <FieldContainer>
        <FormControl
          control="input"
          name="prefferedName"
          type="text"
          placeholder="Preferred name"
          whiteBackground
        />
      </FieldContainer>
      {/* Render an Action button that submits the preferred name value. */}
      <Action
        submitValues={(values) => ({
          prefferedName: values.prefferedName || "",
        })}
      />
    </>
  );
}

// Define the GenderEdit component.
function GenderEdit() {
  // Get all genders and the loading state from the useAllGenders hook.
  const { allGenders, loading } = useAllGenders();

  // Return a FormControl for the gender select and an Action button.
  return (
    <>
      {/* Render a FormControl for the gender select. */}
      <FieldContainer>
        <FormControl
          control="select"
          name="gender"
          placeholder="Gender Preference"
          options={allGenders}
          isLoading={loading}
          whiteBackground
          isDisabled={loading}
        />
      </FieldContainer>
      {/* Render an Action button that submits the gender value. */}
      <Action
        submitValues={(values) => ({ genderID: values.gender?.value || 0 })}
        disabled={loading}
      />
    </>
  );
}

// Define the EditName component.
function EditName() {
  // Return a paragraph of text, a Box containing two FormControls for the first and last name inputs, and an Action button.
  return (
    <>
      {/* Render a paragraph of text. */}
      <p className="text-gray-900 mt-0 mb-8">
        Enter your legal name as it appears on your government issued ID.
      </p>
      {/* Render a Box containing two FormControls. */}
      <FieldContainer>
        {/* Render a FormControl for the first name input. */}
        <FormControl
          control="input"
          label="First Name"
          name="firstName"
          type="text"
          placeholder="Enter first name"
          whiteBackground
        />
        {/* Render a FormControl for the last name input. */}
        <FormControl
          control="input"
          label="Last Name"
          name="lastName"
          type="text"
          placeholder="Enter last name"
          whiteBackground
        />
      </FieldContainer>
      {/* Render an Action button that submits the first and last name values. */}
      <Action
        submitValues={(values) => ({
          firstName: values.firstName ?? "",
          lastName: values.lastName ?? "",
        })}
        onUpdateCallback={(values) => {
          // Update the user first and last name in the cache.
          userFirstNameVar(values?.firstName);
          userLastNameVar(values?.lastName);
        }}
      />
    </>
  );
}

function FieldContainer({ children }) {
  return <Box extraClasses="grid grid-cols-2 gap-4">{children}</Box>;
}

// Define the EditAddress component.
function EditAddress() {
  // Return a FormControl for the address input and an Action button.
  return (
    <>
      {/* Render a FormControl for the address input. */}
      <FieldContainer>
        <FormControl
          control="input"
          name="address1"
          type="text"
          label="Address 1"
          whiteBackground
        />
        <FormControl
          control="input"
          name="address2"
          type="text"
          label="Address 2"
          whiteBackground
        />
      </FieldContainer>
      <FieldContainer>
        <FormControl
          control="input"
          name="city"
          type="text"
          label="City"
          whiteBackground
        />
        <FormControl
          control="input"
          name="country"
          type="text"
          label="Country"
          whiteBackground
        />
      </FieldContainer>
      <FieldContainer>
        <FormControl
          control="input"
          name="state"
          type="text"
          label="State"
          whiteBackground
        />
        <FormControl
          control="input"
          name="zipCode"
          type="text"
          label="Zip Code"
          whiteBackground
        />
      </FieldContainer>
      {/* Render an Action button that submits the address value. */}
      <Action
        submitValues={(values) => ({
          address1: values.address1 || "",
          address2: values.address2 || "",
          city: values.city || "",
          state: values.state || "",
          zipCode: values.zipCode || "",
          country: values.country || "",
        })}
      />
    </>
  );
}

// Define the EditPhone component.
function EditPhone() {
  // Return a FormControl for the phone input and an Action button.
  return (
    <>
      {/* Render a FormControl for the phone input. */}
      <FieldContainer>
        <FormControl
          control="input"
          name="phone"
          type="text"
          placeholder="Enter phone number"
          whiteBackground
        />
      </FieldContainer>
      {/* Render an Action button that submits the phone value. */}
      <Action submitValues={(values) => ({ phone: values.phone || "" })} />
    </>
  );
}

// Define the EditTimezone component.
function EditTimezone() {
  // Compute the options for the timezone select once and memoize the result.
  const options = useMemo(() => getGroupedTimezones(true), []);

  // Return a FormControl for the timezone select and an Action button.
  return (
    <>
      {/* Render a FormControl for the timezone select. */}
      <FieldContainer>
        <FormControl
          control="select"
          name="preferredTimezone"
          options={options}
          placeholder="Select Timezone"
          getOptionLabel={prepareTimezoneOption}
          groupedOptions
          whiteBackground
        />
      </FieldContainer>
      {/* Render an Action button that submits the preferred timezone value. */}
      <Action
        submitValues={(values) => ({
          preferredTimezone: values.preferredTimezone?.value || "",
        })}
      />
    </>
  );
}

// Define the Action component.
function Action({
  disabled = false,
  submitValues = (_values) => ({}),
  onUpdateCallback = (_values) => {},
}) {
  // Get the function to update the user and the loading state from the settings context.
  const { handleUpdateUser, loading } = useSettingsContext();

  // Get the form values from the formik context.
  const values = useFormikContext().values;

  // Return a FormControl for the save changes button.
  return (
    <FormControl
      control="button"
      type="button"
      text="Save changes"
      extraClasses="mt-4"
      // When the button is clicked, call handleUpdateUser with the result of calling submitValues with the form values.
      onClick={() =>
        handleUpdateUser(submitValues(values), onUpdateCallback, values)
      }
      // Show a loader if loading.
      showLoader={loading}
      // Disable the button if loading or disabled.
      disabled={loading || disabled}
    />
  );
}
