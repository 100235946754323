import React from "react";

import { useIsAuthenticated } from "@lango/common/features/auth/hooks";

const withAuthenticated = (WrappedComponent) => {
  const Authenticated = (props) => {
    const { isAuthenticated } = useIsAuthenticated();

    return isAuthenticated && <WrappedComponent {...props} />;
  };

  return Authenticated;
};

export default withAuthenticated;
