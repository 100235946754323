import Button from "@lango/common/features/forms/components/Button";
import Flex from "@lango/common/features/tables/components/Flex";

export const PanelFooter = ({ onClick }) => {
  return (
    <Flex
      justify="center"
      extraClasses="py-6 border-t border-gray-200 bg-white text-base font-medium underline rounded-b-2xl"
    >
      <Button text="View all notifications" textButton onClick={onClick} />
    </Flex>
  );
};
